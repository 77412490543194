import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Stack, Typography, InputAdornment, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileSettingsModal } from "../../../components/NewCustomModal";
import { useChangePassword } from "../../../hooks/useChangePassword";
import useGetUserId from "../../../hooks/useGetUserId";
import Button from "../../../components/NewButton";
import Label from "../../../components/Label";
import Input from "../../../components/Input/Input";
import ErrorMessage from "../../../components/ErrorMessage";
import getREMFromPX from "../../../utils/getREMFromPX";
import sendErrorToast from "../../../utils/sendErrorToast";
import isValidPassword from "../../../utils/isValidPassword";
import { theme } from "../../../theme";
import { OnboardingBubble } from "../../../assets/icons";
import {
  iconStyle,
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad,
} from "./Styles";

export type PasswordType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const EditPassword = () => {
  const colors = theme["new"].colors;
  const userId = useGetUserId();

  const [content, setContent] = useState("old");
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PasswordType>({
    mode: "onChange",
    defaultValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
  });

  const newPassword = watch("newPassword").trim();
  const confirmPassword = watch("confirmPassword").trim();

  const { mutateAsync: changePassword, isLoading: isLoadingNewPassword } =
    useChangePassword(userId, {
      onSuccess: () => {
        setContent("success");
      },
      onError: () =>
        sendErrorToast(
          "There was a problem changing the password, please try again"
        ),
    });

  const onSubmitHandler = useCallback(
    async (e: PasswordType) => {
      if (newPassword === confirmPassword) {
        changePassword({ newPassword, confirmPassword });
      } else {
        sendErrorToast("Error saving new password");
      }
    },
    [changePassword, newPassword, confirmPassword]
  );

  const disableButton =
    !isValid ||
    !!Object.values(errors).length ||
    newPassword !== confirmPassword;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordsMatch = !confirmPassword || confirmPassword === newPassword;

  const closeModal = () => {
    setIsEditing(false);
    setContent("old");
  };

  const getContent = () => {
    switch (content) {
      case "success":
        return (
          <Stack sx={{ alignItems: "center" }}>
            <OnboardingBubble />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: "#000000",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              Your password has been updated
            </Typography>
            <Button
              text="OK"
              mode="default"
              disabled={!isValid}
              isLoading={isLoadingNewPassword}
              sx={{ width: "218px" }}
              onClick={() => {
                closeModal();
              }}
            />
          </Stack>
        );
      default:
        return (
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            data-testid="edit-username"
          >
            <Stack spacing={getREMFromPX(theme.spacing * 2)} direction="column">
              <Label htmlFor="newPassword">New Password</Label>
              <Input
                id="newPassword"
                data-testid="newPassword"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                placeholder="Enter your new password"
                error={
                  (!!errors.newPassword && !!newPassword.length) ||
                  !passwordsMatch
                }
                softError={
                  !!errors.newPassword || !newPassword.length || !passwordsMatch
                }
                style={inputAdornmentStyle}
                endAdornment={
                  <InputAdornment position="end" style={iconStyleAdornment}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      style={iconStylePad}
                      data-cy="editpassword-togglepasswordview"
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon="eye-slash" style={iconStyle} />
                      ) : (
                        <FontAwesomeIcon icon="eye" style={iconStyle} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                {...register("newPassword", {
                  required: true,
                  validate: (value) =>
                    isValidPassword(value) || "Invalid password",
                })}
              />
            </Stack>
            {errors.newPassword && newPassword && (
              <ErrorMessage
                sx={{
                  marginTop: getREMFromPX(5),
                  fontFamily: "Inter",
                  fontSize: getREMFromPX(11),
                }}
              >
                {errors.newPassword.message}
              </ErrorMessage>
            )}
            <Stack
              spacing={getREMFromPX(theme.spacing * 2)}
              direction="column"
              mt={getREMFromPX(theme.spacing * 8)}
            >
              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <Input
                id="confirmPassword"
                data-testid="confirmPassword"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                placeholder="Confirm your new password"
                error={
                  (!!errors.confirmPassword && !!confirmPassword.length) ||
                  !passwordsMatch
                }
                softError={
                  !!errors.confirmPassword ||
                  !confirmPassword.length ||
                  !passwordsMatch
                }
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) =>
                    value === newPassword || "These passwords do not match",
                })}
              />
              {!!errors.confirmPassword && !!confirmPassword?.length && (
                <ErrorMessage
                  sx={{
                    marginTop: getREMFromPX(5),
                    fontFamily: "Inter",
                    fontSize: getREMFromPX(11),
                  }}
                >
                  {errors.confirmPassword.message}
                </ErrorMessage>
              )}
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              paddingTop={getREMFromPX(theme.spacing * 8)}
            >
              <Button
                data-testid="saveButton"
                mode="default"
                text="Save"
                type="submit"
                size="large"
                disabled={disableButton}
                sx={{ width: 220 }}
              />
            </Stack>
          </form>
        );
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <ProfileSettingsModal open={isEditing}>
        {content !== "success" && (
          <>
            <Typography
              sx={{
                position: "absolute",
                top: 30,
                left: 40,
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.darkGreenFontColor,
              }}
            >
              Choose a new password
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: 15,
                right: 15,
                color: colors.teal,
              }}
              onClick={() => closeModal()}
              data-cy="editpassword-close"
            >
              <CloseIcon sx={{ fontWeight: "bolder" }} fontSize="large" />
            </IconButton>
          </>
        )}
        {getContent()}
      </ProfileSettingsModal>
      <Typography
        sx={{
          flex: 1,
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(18),
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.darkGreenFontColor,
        }}
      >
        Password
      </Typography>
      <Typography
        sx={{
          flex: 1.5,
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(36),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.darkGreenFontColor,
        }}
      >
        •••••••
      </Typography>
      <Typography
        onClick={() => setIsEditing(true)}
        sx={{
          cursor: "pointer",
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(16),
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          color: colors.teal,
        }}
        data-cy="editpassword-edit"
      >
        Edit
      </Typography>
    </Stack>
  );
};

export default EditPassword;

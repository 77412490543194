import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import {
  getEditStyle,
  getHeaderIconStyle,
  getHeaderStyle,
  getListValueStyle,
} from "./Styles";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sendErrorToast from "../../../../utils/sendErrorToast";
import useGetUserId from "../../../../hooks/useGetUserId";
import { useContext, useEffect, useState } from "react";
import ContactAndLocationEdit from "../../../../components/ContactAndLocationEdit";
import { ContactAndLocationInfo } from "../../../../components/ContactAndLocationEdit/ContactAndLocationEdit";
import Button from "../../../../components/Button/Button";
import { useUpdateContactInformation } from "../../../../hooks/useUpdateContactInformation";
import { useGetEnums } from "../../../../hooks/useGetEnums";
import { SingleUser } from "../../../../api/interfaces/User";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";

const Contact = () => {
  const userId = useGetUserId();
  const { userInfo, setUserInfo, isLoading: isContextLoading } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const {
    mutateAsync: updateContactInformation,
    isLoading: isUpdatingContactInformation,
  } = useUpdateContactInformation(userId, {
    onError: () =>
      sendErrorToast(
        "There was an error saving Contact Information, please try again"
      ),
    onSuccess: () => setIsEditing(false),
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { isLoading } = useGetEnums({ refetchOnWindowFocus: false });
  const [values, setValues] = useState<{ key: string; value?: string }[]>([]);
  const [currentData, setCurrentData] = useState<ContactAndLocationInfo>({
    name: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const themePath = useGetThemePath();

  useEffect(() => {
    const contact = userInfo?.data?.user?.Contact;
    let fullAddress = contact?.Location?.address1 ?? "";
    if (contact?.Location?.address2) {
      fullAddress += ` ${contact?.Location?.address2}`;
    }
    fullAddress += `, ${contact?.Location?.city}, ${contact?.Location?.state}, ${contact?.Location?.zip}`;

    setValues([
      { key: "Name", value: `${contact?.first_name} ${contact?.last_name}` },
      { key: "Email", value: contact?.email },
      { key: "Cell Phone Number", value: contact?.phone },
      // { key: "Provider", value: `TBD - AT&T` },
      // { key: "Secondary Phone Number", value: "TBD - 234567890" },
      { key: "Address", value: fullAddress },
    ]);

    setCurrentData({
      name: contact?.first_name ?? "",
      lastName: contact?.last_name ?? "",
      phoneNumber: contact?.phone ?? "",
      address: contact?.Location?.address1 ?? "",
      city: contact?.Location?.city ?? "",
      state: contact?.Location?.state ?? "",
      zipCode: contact?.Location?.zip ?? "",
    });
  }, [userInfo]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onSubmit = async (data: ContactAndLocationInfo) => {
    updateContactInformation({
      data,
      contactId: userInfo?.data?.user?.Contact?.id ?? "",
      locationId: userInfo?.data?.user?.Contact?.Location?.id ?? "",
    }).then(res => {
      setUserInfo((info: SingleUser) => {
        return {
          ...info,
          data: {
            ...info.data,
            user: {
              ...info.data?.user,
              Contact: {
                ...info.data?.user?.Contact,
                ...res?.data?.contact,
                Location: {
                  ...info.data?.user?.Contact?.Location,
                  ...res.Location?.data?.location,
                },
              }
            },
          },
        };
      });
    });
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={`0 0 ${getREMFromPX(theme.spacing * 3)} 0`}
      >
        <Stack direction="row" alignItems="center">
          <MailOutlineIcon sx={getHeaderIconStyle(themePath)} />
          <Typography sx={getHeaderStyle}>
            {isEditing ? "Edit Contact Information" : "Contact"}
          </Typography>
        </Stack>
        {!isEditing && (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleEdit}
            sx={getEditStyle(themePath)}
            component="button"
          >
            <Typography component="span"> Edit </Typography>
            <FontAwesomeIcon icon="pencil" />
          </Stack>
        )}
      </Stack>
      {isEditing ? (
        <>
          <Typography>Email can be edited in the Security Tab</Typography>
          <ContactAndLocationEdit
            previousValues={currentData}
            onSubmit={onSubmit}
            setIsValid={setIsValid}
          >
            <>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                paddingTop={getREMFromPX(theme.spacing * 8)}
              >
                <Button
                  data-testid="cancelButton"
                  text="Cancel"
                  type="button"
                  size="large"
                  variant="text"
                  onClick={() => setIsEditing(false)}
                />
                <Button
                  data-testid="saveButton"
                  text="Save"
                  type="submit"
                  size="large"
                  disabled={!isValid}
                />
              </Stack>
            </>
          </ContactAndLocationEdit>
        </>
      ) : (
        values.map((value, idx) => (
          <Stack
            direction="row"
            padding={`${getREMFromPX(theme.spacing * 6)} 0 0 0`}
            key={`contact-property-${idx}`}
          >
            <Typography>{`${value.key}:`}</Typography>
            <Typography sx={getListValueStyle(themePath)}>
              {value.value}
            </Typography>
          </Stack>
        ))
      )}
      <Backdrop
        open={isLoading || isUpdatingContactInformation || isContextLoading}
      >
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </Stack>
  );
};

export default Contact;

import React, { useState, useRef, useEffect } from "react";
import CardWrapper from "../../components/NewCardWrapper";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { newRoutes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";
import { buttonWeb, buttonWebText } from "./styles";
import { ServiceList } from "../../api/interfaces/Service";
import {
  InternetIcon,
  LearningIcon,
  NetworkIcon,
  HomeSecurityIcon,
  ChevronArrowIcon,
} from "../../assets/icons";

type AllServicesProps = {
  services: ServiceList | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

const AllServices = ({ services }: AllServicesProps) => {
  const navigate = useNavigate();

  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const checkForOverflow = () => {
    if (gridContainerRef.current) {
      const isOverflowing =
        gridContainerRef.current.scrollWidth >
        gridContainerRef.current.clientWidth;
      setShowScrollIndicator(isOverflowing);
    }
  };

  useEffect(() => {
    checkForOverflow(); // Check on mount
    window.addEventListener("resize", checkForOverflow); // Add resize listener

    return () => {
      window.removeEventListener("resize", checkForOverflow); // Clean up listener
    };
  }, []);

  const arrowAction = (
    <ForwardArrowIcon
      onClick={() => navigate(newRoutes.services.path)}
      style={{ cursor: "pointer" }}
      data-cy="dashboard-all-services"
    />
  );

  const hoverStyle = {
    "&:hover": {
      boxShadow: "0 0 17px 0 rgba(126, 130, 129, 0.39)",
      shadowOpacity: 1,
    },
  };

  return (
    <Grid item xl={12} style={{ height: "fit-content" }}>
      <CardWrapper
        title="All Services"
        actions={arrowAction}
        sx={{ maxHeight: "265px" }}
      >
        <Grid
          container
          ref={gridContainerRef}
          style={{
            marginTop: "0px",
            paddingBottom: "17px",
            overflowX: "auto", // Enable horizontal scrolling
            display: "flex", // Make it a flex container
            flexWrap: "nowrap", // Prevent items from wrapping
            paddingRight: showScrollIndicator ? 30 : 0,
          }}
          spacing={2}
        >
          {services?.data?.servicetype?.map((servicetype) => {
            const cyText = `service-view-${servicetype.name
              .toLocaleLowerCase()
              .replace(" ", "-")}`;
            return (
              servicetype.display && (
                <Grid
                  key={servicetype.id}
                  item
                  style={{
                    textAlign: "center",
                    height: "174px",
                    minWidth: "189px",
                  }}
                >
                  <CardWrapper
                    id={servicetype.id}
                    title=""
                    sx={hoverStyle}
                    style={{ padding: "0px" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "0.5rem",
                      }}
                    >
                      {servicetype.name === "Internet Service" && (
                        <InternetIcon />
                      )}
                      {servicetype.name === "Remote Learning" && (
                        <LearningIcon />
                      )}
                      {servicetype.name === "Network Service" && (
                        <NetworkIcon />
                      )}
                      {servicetype.name === "Home Security" && (
                        <HomeSecurityIcon />
                      )}
                    </div>
                    <div
                      style={{
                        marginBottom: "14px",
                      }}
                    >
                      {servicetype.name}
                    </div>
                    <div>
                      <button
                        style={buttonWeb}
                        onClick={() =>
                          navigate(newRoutes.services.path, {
                            state: servicetype.name,
                          })
                        }
                        data-cy={cyText}
                      >
                        <span style={buttonWebText}>View</span>
                      </button>
                    </div>
                  </CardWrapper>
                </Grid>
              )
            );
          })}
          {showScrollIndicator && (
            <div
              style={{
                display: "flex",
                backgroundColor: "white",
                position: "absolute",
                height: "200px",
                width: "36px",
                right: "25px",
                alignItems: "center",
              }}
            >
              <ChevronArrowIcon />
            </div>
          )}
        </Grid>
      </CardWrapper>
    </Grid>
  );
};
export default AllServices;

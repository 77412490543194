import { useContext } from "react";
import { MenuItemProps, Select, SelectChangeEvent } from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import SelectOption from "../SelectOption";

const SelectDevice = ({ children, ...props }: MenuItemProps) => {
  const { devices, currentDevice, setCurrentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  return (
    <Select
      sx={props.sx}
      value={currentDevice.id}
      onChange={(event: SelectChangeEvent) => {
        const newDevice = devices.find(
          (device) => device.id === event.target.value
        );
        if (newDevice) {
          setCurrentDevice(newDevice);
        }
      }}
      data-cy="myDeviceSelectInput"
    >
      {devices.map((device, idx) => (
        <SelectOption
          key={device.id}
          value={device.id}
          data-cy={`myDevice-${idx}`}
        >
          {`${device.Physicallocation.house_number} ${device.Physicallocation.pre_directional} ${device.Physicallocation.street} ${device.Physicallocation.post_directional} ${device.Physicallocation.apartment_number}`}
        </SelectOption>
      ))}
    </Select>
  );
};
export default SelectDevice;

import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const introAlertStyles = {
  margin: `0 ${getREMFromPX(theme.spacing * 6)}`,
  padding: getREMFromPX(theme.spacing * 6),
};

export const spinnerContainerStyles = {
  margin: "0 !important",
};

export const getIconStyles = (themePath = "default") => ({
  fontSize: getREMFromPX(theme.spacing * 12),
  color: theme[themePath].colors.secondary[500],
});

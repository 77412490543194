import React from "react";
import { Box, Typography, styled } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import NewButton from "../../../components/NewButton";
import { theme } from "../../../theme";
import { Plan } from "../utils/intefaces/Plan";
import { convertToDecimalIfNeeded } from "../utils/convertToDecimal";
import { OverviewDataModel } from "../../../components/AllServices/AllServices";
import { ContactInfo } from "../../../api/interfaces/User/ContactInfo";
import { FieldIcon } from "../utils/intefaces/FieldIcon";
import getREMFromPX from "../../../utils/getREMFromPX";

export const LogoContainer = styled("div")<{
  width: string;
  height: string;
  isCoverImage?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin: auto;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  padding: 12px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: ${(p) =>
      p.isCoverImage
        ? "cover"
        : "contain"}; /* Set to "contain" for fitting height */
  }
`;

const RowContainer = styled("div")`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  height: 35px;
`;

const CardService = ({
  plan,
  setOpenSubscribeModal,
  setModalOverviewData,
  setOpenWorkflowSubscriptionModal,
  support_url,
  subscription_redirect_url,
  Contacts,
  dataCy,
}: {
  setOpenSubscribeModal: () => void;
  setOpenWorkflowSubscriptionModal: () => void;
  plan: Plan;
  setModalOverviewData: (param: OverviewDataModel) => void;
  Contacts?: Partial<Array<ContactInfo>>;
  support_url?: string;
  subscription_redirect_url?: string;
  dataCy: string;
}) => {
  const providerInfo = Contacts?.find(
    (c: any) =>
      c.status === "ACTIVE" && (c.type === "SUPPORT" || c.type === "SALES")
  );

  const starsReviewAverage = convertToDecimalIfNeeded(
    Number(plan.ratings_sum) / Number(plan.total_reviews)
  );

  const setModalData = (
    plan: Plan,
    providerInfo?: ContactInfo,
    support_url?: string,
    subscription_redirect_url?: string
  ) => {
    setModalOverviewData({
      fields: {
        Down: plan.fields?.Down as string,
        LinkSpeed: plan.fields?.LinkSpeed as number,
        Name: plan.fields?.Name as string,
        Price: plan.fields?.Price as string,
        Up: plan.fields?.Up as string,
      },
      description: plan.description as string,
      providerName: plan.providerName as string,
      name: plan.name as string,
      planName: plan.planName as string,
      service_id: plan.service_id,
      provider_id: plan.providerId as string,
      planId: plan.id as string,
      ratings_sum: plan.ratings_sum as string,
      total_reviews: plan.total_reviews as string,
      email: plan.Contacts[0]?.email,
      phone: plan.Contacts[0]?.phone,
      support_url: plan.Contacts[0]?.support_url || plan.support_url,
      subscription_redirect_url: plan.subscription_redirect_url,
      fields_icons: plan.fields_icons as Array<FieldIcon>,
    });
  };

  return (
    <Box
      padding="19.5px 26px 23.5px 25px"
      sx={{
        width: "264px",
        borderRadius: "15px",
        boxShadow: "0 4px 10px 0 rgba(201, 218, 216, 0.6)",
        maxHeight: "240px",
        "&:hover": {
          boxShadow: "0 0 17px 0 rgba(126, 130, 129, 0.39)",
          shadowOpacity: 1,
        },
        cursor: "pointer",
      }}
      onClick={() => {
        setModalData(
          plan,
          providerInfo,
          support_url,
          subscription_redirect_url
        );
        setOpenSubscribeModal();
      }}
      data-cy={dataCy}
    >
      <Typography
        color={theme.new.colors.mediumGrey}
        fontSize={14}
        fontFamily="Montserrat"
        fontWeight="bold"
        textAlign="center"
        textTransform="uppercase"
      >
        {plan.name}
      </Typography>
      <LogoContainer width="180" height="63">
        <img
          src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
          alt=""
          style={{ width: "120%" }}
        />
      </LogoContainer>

      <RowContainer sx={{ marginTop: "-6px" }}>
        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontSize={20}
          fontFamily="Montserrat"
          fontWeight={600}
          textAlign="center"
        >
          ${plan.fields?.Price}
        </Typography>
      </RowContainer>

      <RowContainer sx={{ marginTop: "0px", marginBottom: "6px" }}>
        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontSize={14}
          fontFamily="Montserrat"
          fontWeight={500}
          textAlign="center"
          height={38}
          lineHeight="normal"
        >
          {plan.planName}
        </Typography>
      </RowContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {Number(plan.total_reviews) === 0 ? (
          <Typography
            fontFamily="Montserrat"
            fontSize={getREMFromPX(12)}
            color={theme.new.colors.darkGreen}
          >
            No reviews
          </Typography>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StarIcon
              sx={{ color: "#f9cb28", fontSize: "28px", marginRight: "8px" }}
            />
            <Typography
              color={theme.new.colors.darkGreenFontColor}
              fontSize={16}
              fontFamily="Montserrat"
              fontWeight={500}
            >
              {starsReviewAverage}
            </Typography>
          </Box>
        )}
        <NewButton
          mode="default"
          text="Subscribe"
          onClick={() => {
            setModalData(
              plan,
              providerInfo,
              support_url,
              subscription_redirect_url
            );
            setOpenWorkflowSubscriptionModal();
          }}
          sx={{
            width: "120px",
            height: "40px",
            fontSize: "12px",
            borderRadius: "30px",
          }}
          data-cy={`service-plan-card-${
            plan.providerName
              ? plan.providerName.toLowerCase().replaceAll(" ", "-") + "-"
              : ""
          }${
            plan.planName
              ? plan.planName.toLowerCase().replaceAll(" ", "-")
              : ""
          }`}
          dataCy={`${dataCy}-subscribe`}
        />
      </Box>
    </Box>
  );
};

export default CardService;

import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const navStyles = {
  width: "248px",
  padding: getREMFromPX(theme.spacing * 6),
};

export const headerLogoImage = {
  width: getREMFromPX(theme.spacing * 40),
  height: 'auto',
  margin: '0px auto'
}

export const iconStyles = {
  width: getREMFromPX(theme.spacing * 4),
  height: getREMFromPX(theme.spacing * 4),
};

export const EPLogoStyles = {
  marginTop: "auto",
};

export const EPLogoStylesBottom = {
    marginTop: "auto",
    padding: "10px"
  };

export const getBoxStyles = (themePath = "default") => ({
  background: theme[themePath].colors.white,
  paddingBottom: getREMFromPX(theme.spacing * 16),
});


import React from "react";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/NewCardWrapper";
import { BillingIssuesIcon } from "../../../../assets/icons";
import { newRoutes } from "../../../../App";
import { Grid } from "@mui/material";
import { ContinueIcon, Description, ImageContainer } from "../../Styles";
import { theme } from "../../../../theme";

const BillingIssues = ({ styles }: { styles: React.CSSProperties }) => {
  const navigate = useNavigate();

  const arrowAction = (
    <ContinueIcon
      onClick={() => navigate(newRoutes.helpBilling.path)}
      data-cy="help-billing-arrow"
    />
  );

  return (
    <CardWrapper title="Billing Issues" actions={arrowAction} sx={styles}>
      <Grid
        container
        spacing={2}
        onClick={() => navigate(newRoutes.helpBilling.path)}
      >
        <Grid item sm={12} md={6}>
          <Description>
            Having questions <br /> regarding your bill?
          </Description>
        </Grid>
        <Grid item sm={12} md={6}>
          <ImageContainer>
            <BillingIssuesIcon
              style={{
                color: theme.new.colors.teal,
                width: "100%",
                height: "100%",
                objectFit: "contain",
                marginBottom: "1.84rem",
              }}
            />
          </ImageContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default BillingIssues;

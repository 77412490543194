import { Route, Routes } from "react-router-dom";
import "typeface-nunito-sans";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import GlobalStyles from "@mui/material/GlobalStyles";
import { globalStyle } from "./GlobalStyle";
import Header from "./components/Header";
import Login from "./routes/Login";
import NewLogin from "./routes/NewLogin";
import ForgotPassword from "./routes/ForgotPassword";
import AccountSettings from "./routes/AccountSettings";
import { ToastContainer } from "react-toastify";
import AuthProvider from "./components/AuthProvider";
import BillingProvider from "./components/BillingProvider";
import "react-toastify/dist/ReactToastify.min.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./routes/Dashboard";
import Billing from "./routes/NewBilling";
import Services from "./routes/Services";
import Plans from "./routes/Plans/";
import AddressAndAccountContextProvider from "./components/AddressAndAccountProvider/AddressAndAccountProvider";
import SubscribeToISP from "./routes/SubscribeToISP";
import Profile from "./routes/Profile/Profile";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {
  IconDefinition,
  IconPrefix,
  IconPack,
} from "@fortawesome/free-solid-svg-icons";
import NewCreateAccount from "./routes/NewCreateAccount/NewCreateAccount";
import NewForgotEmail from "./routes/NewForgotEmail";
import { NewDashboard } from "./routes/NewDashboard";
import NewServices from "./routes/NewServices";
import NewPlans from "./routes/NewPlans";
import UserProfile from "./routes/NewProfile/UserProfile";
import HelpCenter from "./routes/NewHelpCenter";
import FaqPage from "./routes/NewHelpCenter/components/Faq/FaqPage";
import DeviceGuidePage from "./routes/NewHelpCenter/components/DeviceGuide/DeviceGuidePage";
import DeviceTroubleshooting from "./routes/NewHelpCenter/components/DeviceGuide/DeviceTroubleshootingPage";
import ServiceProvidersPage from "./routes/NewHelpCenter/components/ServiceProviders/ServiceProvidersPage";
import BillingIssuesPage from "./routes/NewHelpCenter/components/BillingIssues/BillingIssuesPage";

type IconDefinitionOrPack = IconDefinition | IconPack;

interface ImportedIcons {
  [key: string]: IconPrefix | IconDefinitionOrPack;
}

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => (Icons as ImportedIcons)[icon]);

library.add(...(iconList as IconDefinitionOrPack[]));

const materialUITheme = createTheme({
  typography: {
    fontFamily: theme.fontFamily,
  },
});

export const routes = {
  login: {
    name: "login",
    path: "/",
    element: <Login />,
    hasHeader: true,
  },
  forgotPassword: {
    name: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    hasHeader: true,
  },
  updateAccount: {
    name: "update-account",
    path: "/update-account",
    element: (
      <ProtectedRoute>
        <AccountSettings />
      </ProtectedRoute>
    ),
    hasHeader: true,
  },
  subscribeToISP: {
    name: "subscribeToISP",
    path: "/subscribe/:serviceId/:planId",
    element: (
      <ProtectedRoute>
        <SubscribeToISP />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  createAccount: {
    name: "create-account",
    path: "/create-account",
    element: <AccountSettings />,
    hasHeader: true,
  },
  dashboard: {
    name: "dashboard",
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  services: {
    name: "services",
    path: "/services",
    element: (
      <ProtectedRoute>
        <Services />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  plans: {
    name: "plans",
    path: "/services/:id/plans",
    element: (
      <ProtectedRoute>
        <Plans />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },

  profile: {
    name: "profile",
    path: "/profile",
    element: (
      <ProtectedRoute>
        <Dashboard>
          <Profile />
        </Dashboard>
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
};

export const newRoutes = {
  login: {
    name: "login",
    path: "/",
    element: <NewLogin />,
    hasHeader: false,
  },
  createAccount: {
    name: "create-account",
    path: "/create-account",
    element: <NewCreateAccount />,
    hasHeader: false,
  },
  forgotPassword: {
    name: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    hasHeader: false,
  },
  forgotEmail: {
    name: "forgot-email",
    path: "/forgot-email",
    element: <NewForgotEmail />,
    hasHeader: false,
  },
  updateAccount: {
    name: "update-account",
    path: "/update-account",
    element: (
      <ProtectedRoute>
        <AccountSettings />
      </ProtectedRoute>
    ),
    hasHeader: true,
  },
  subscribeToISP: {
    name: "subscribeToISP",
    path: "/subscribe/:serviceId/:planId",
    element: (
      <ProtectedRoute>
        <SubscribeToISP />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  dashboard: {
    name: "dashboard",
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <NewDashboard />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  billing: {
    name: "billing",
    path: "/billing",
    element: (
      <ProtectedRoute>
        <Billing />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  services: {
    name: "services",
    path: "/services",
    element: (
      <ProtectedRoute>
        <NewServices />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  myServices: {
    name: "my-services",
    path: "/services?tab=My%20Services",
    element: (
      <ProtectedRoute>
        <NewServices />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  plans: {
    name: "plans",
    path: "/services/:id/plans",
    element: (
      <ProtectedRoute>
        <NewPlans />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },

  profile: {
    name: "profile",
    path: "/profile",
    element: (
      <ProtectedRoute>
        <UserProfile />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  help: {
    name: "help",
    path: "/help",
    element: (
      <ProtectedRoute>
        <HelpCenter />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpService: {
    name: "help-service",
    path: "/help/service",
    element: (
      <ProtectedRoute>
        <ServiceProvidersPage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpDevice: {
    name: "help-device",
    path: "/help/device",
    element: (
      <ProtectedRoute>
        <DeviceGuidePage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpTroubleshoot: {
    name: "help-troubleshoot",
    path: "/help/troubleshoot",
    element: (
      <ProtectedRoute>
        <DeviceTroubleshooting />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpBilling: {
    name: "help-billing",
    path: "/help/billing",
    element: (
      <ProtectedRoute>
        <BillingIssuesPage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpFaq: {
    name: "help-faq",
    path: "/help/faq",
    element: (
      <ProtectedRoute>
        <FaqPage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
};

const App = () => {
  const showNewUI = true;

  return (
    <ThemeProvider theme={materialUITheme}>
      <GlobalStyles styles={globalStyle} />
      <ToastContainer />
      <AuthProvider>
        <AddressAndAccountContextProvider>
          <BillingProvider>
            <Routes>
              {Object.values(showNewUI ? newRoutes : routes).map(
                ({ name, path, element, hasHeader }) => (
                  <Route
                    key={name}
                    path={path}
                    element={
                      hasHeader ? (
                        <>
                          <Header />
                          {element}
                        </>
                      ) : (
                        element
                      )
                    }
                  />
                )
              )}
            </Routes>
          </BillingProvider>
        </AddressAndAccountContextProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;

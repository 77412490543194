import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Box, Stack, Typography, Divider } from "@mui/material";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import Button from "../../../Button";
import UnsubscribeButton from "../UnsubscribeButton";
import { dividerStyle, getModalBoxStyles } from "./styles";

const UnsubscribeModal = ({
  open,
  onClose,
  onClickSubscribe,
  onClickCancel,
  disableButtons,
}: {
  open: boolean;
  onClose: () => void;
  onClickCancel: () => void;
  onClickSubscribe: () => void;
  disableButtons: boolean;
}) => {
  const themePath = useGetThemePath();
  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <Box
          width={getREMFromPX(theme.spacing * 90)}
          style={getModalBoxStyles(themePath)}
        >
          <Stack
            padding={getREMFromPX(theme.spacing * 8)}
            spacing={getREMFromPX(theme.spacing * 4)}
            alignItems="baseline"
          >
            <FontAwesomeIcon
              color={theme[themePath].colors.primary[500]}
              fontSize={getREMFromPX(theme.spacing * 8)}
              icon="circle-exclamation"
            />
            <Typography
              fontSize={getREMFromPX(theme.spacing * 5)}
              fontWeight={theme.fonts.weights.bolder}
              color={theme[themePath].colors.textPrimary.primary}
            >
              Are you sure you want to unsubscribe?
            </Typography>
            <Typography color={theme[themePath].colors.textPrimary.secondary}>
              This action cannot be undone.
            </Typography>
          </Stack>
          <Stack padding={`0 ${getREMFromPX(theme.spacing * 8)}`}>
            <Divider style={dividerStyle} />
          </Stack>
          <Stack
            direction="row"
            gap={getREMFromPX(theme.spacing * 2)}
            padding={getREMFromPX(theme.spacing * 8)}
          >
            <UnsubscribeButton
              size="medium"
              onClick={onClickSubscribe}
              text="Unsubscribe"
              disabled={disableButtons}
            />
            <Button
              disabled={disableButtons}
              size="medium"
              variant="text"
              text="Cancel"
              onClick={onClickCancel}
            />
          </Stack>
        </Box>
      </div>
    </Modal>
  );
};
export default UnsubscribeModal;

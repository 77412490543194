import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const boxStyles = {
  backgroundColor: theme.backgrounds.secondarySelection,
};

export const subHeaderContainer = {
  marginBottom: getREMFromPX(theme.spacing * 4),
};

export const iconStyles = {
  marginRight: getREMFromPX(theme.spacing * 2),
};

export const getSelectedItemStyles = (themePath = "default") => ({
  backgroundColor: theme[themePath].colors.secondary[500],
});

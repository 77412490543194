import { Box, Card, Grid, Typography, Stack } from "@mui/material";
import {
  ReactNode,
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Stepper } from "../../components/NewStepper";
import RouterIcon from "@mui/icons-material/Router";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import Header from "../../components/Header/Header";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { useCreateAccount } from "../../hooks/useCreateAccount";
import { useLinkAccountUser } from "../../hooks/useLinkAccountUser";
import { useLinkAccountDevice } from "../../hooks/useLinkAccountDevice";
import { useGetConfig } from "../../hooks/useGetConfig";
import { PaymentType } from "../../components/Payment";
import EntryPointLink from "../../components/NewEntryPointLink";
import {
  DeviceStepType,
  WarrantyStepType,
} from "../AccountSettings/AccountSettings";
import { useCreateUser } from "../../hooks/useCreateUser";
import { SingleUser } from "../../api/interfaces/User";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";
import sendErrorToast from "../../utils/sendErrorToast";

type UsernameStep = {
  stepName?: string;
  userName: string;
  password: string;
};

export interface CreateAccountSteps {
  label: string;
  icon: any;
  isActive: boolean;
  isCompleted: boolean;
}

export interface PersonalInformationStep {
  stepName?: string;
  name: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface SecurityQuestionsStep {
  stepName?: string;
  question1Option: string;
  question2Option: string;
  question1Answer: string;
  question2Answer: string;
}

type FormDataType = {
  usernameStep: UsernameStep;
  deviceStep: DeviceStepType;
  contactStep: PersonalInformationStep;
  securityQuestionsStep: SecurityQuestionsStep;
  paymentStep: PaymentType;
  warrantyStep: Partial<WarrantyStepType>;
};

const fail = (message: string) => {
  sendErrorToast(message);
};

const createAccountSteps = (showBilling: boolean) => {
  return [
    {
      label: "Device Info",
      icon: <RouterIcon />,
      isActive: true,
      isCompleted: false,
    },
    {
      label: "Personal Information",
      icon: <PermIdentityIcon />,
      isActive: false,
      isCompleted: false,
    },
    {
      label: "Account Security",
      icon: <ShieldIcon />,
      isActive: false,
      isCompleted: false,
    },
    ...(showBilling
      ? [
          {
            label: "Service Cost",
            icon: <AttachMoneyOutlinedIcon />,
            isActive: false,
            isCompleted: false,
          },
          {
            label: "Payment Info",
            icon: <CreditCardOutlinedIcon />,
            isCompleted: false,
            isActive: false,
          },
        ]
      : []),
    {
      label: "Done",
      icon: <DoneOutlinedIcon />,
      isActive: false,
      isCompleted: false,
    },
  ];
};

let currentStep = 0;

const DeviceInfo = lazy(() => import("./DeviceInfo"));
const PersonalInformation = lazy(() => import("./PersonalInformation"));
const AccountSecurity = lazy(() => import("./AccountSecurity"));
const ServiceCosts = lazy(() => import("./ServiceCosts"));
const Payments = lazy(() => import("./Payments"));
const AllDone = lazy(() => import("./AllDone"));

const NewCreateAccount = () => {
  const location = useLocation();
  const { userName, password } = location.state as UsernameStep;

  const [typePayment, setTypePayment] = useState("");
  const [retryAccount, setRetryAccount] = useState(false);
  const [accountId, setAccountId] = useState("");
  const { isAuthenticated, logOut } = useContext(
    AuthContext
  ) as AuthenticationContext;
  const { provider, userInfo, setUserInfo, setCurrentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const [steps, setSteps] = useState<Array<CreateAccountSteps>>(() =>
    createAccountSteps(showBilling)
  );
  const [deviceId, setDeviceId] = useState("");

  const { isMobile } = useViewport(1220);

  const defaultValues = useMemo(
    () => ({
      usernameStep: {
        stepName: "usernameStep",
        userName: userName ?? userInfo?.data?.user?.username ?? "",
        password: password ?? "",
      },
      deviceStep: {
        stepName: "deviceStep",
        deviceSerial: "",
      },
      contactStep: {
        stepName: "contactStep",
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      securityQuestionsStep: {
        stepName: "securityQuestionsStep",
        question1Option: "",
        question2Option: "",
        question1Answer: "",
        question2Answer: "",
      },
      paymentStep: {
        stepName: "paymentStep",
        cardHolderName: "",
        cardNumber: "",
        expirationDate: "",
        cvc: "",
        zipCode: "",
        routingNum: "",
        accountNum: "",
        accountType: "CHECKING",
        accountHolderType: "PERSONAL",
      },
      warrantyStep: {
        stepName: "warrantyStep",
        maintenanceSel: false,
        insuranceSel: false,
        paymentMethod: "",
        processingFeeSel: false,
        withoutInsurance: false,
        maintainceCharges: 0,
        insuranceCharges: 0,
        ccProcessingCharges: 0,
        subtotal: 0,
      },
    }),
    [userName, userInfo?.data?.user?.username, password]
  );

  const [formValues, setFormValues] = useState(defaultValues);

  const getAccountNumber = useCallback(() => {
    return `${formValues.contactStep.lastName}_${
      formValues.contactStep.name
    }_${formValues.contactStep.address.replaceAll(" ", "_")}`;
  }, [formValues]);

  const {
    mutateAsync: createUser,
    data: newUserData,
    isLoading: isLoadingCreateUser,
  } = useCreateUser({
    onError: () => {
      fail("There was an error creating the user, please try again");
    },
    onSuccess: (usr) => {
      const uid = usr?.data?.user?.id;
      if (uid && deviceId) {
        createAccount({
          account_number: getAccountNumber(),
          name: `${formValues.contactStep.lastName}, ${formValues.contactStep.name}`,
          status: showBilling ? "PENDING" : "ACTIVE",
          type: "RESIDENTIAL",
        }).then(async (resp) => {
          onAccountSuccess(uid, resp);
        });
      }
    },
  });

  const { mutateAsync: createAccount, isLoading: isLoadingCreateAccount } =
    useCreateAccount({
      onError: () => {
        setRetryAccount(true);
        fail("There was an error creating the account, please try again");
      },
    });

  const { mutateAsync: linkUser } = useLinkAccountUser({
    onError: () => fail("There was an error linking the user and account."),
  });

  const { mutateAsync: linkDevice } = useLinkAccountDevice({
    onError: () => fail("There was an error linking the device and account."),
  });

  useEffect(() => {
    return () => {
      setSteps(() => createAccountSteps(showBilling));
    };
  }, [showBilling]);

  const onAccountSuccess = useCallback(
    async (uid: string, resp: any) => {
      const account = resp.data?.account;
      if (!account?.id || resp.message) {
        fail(resp.message ?? "Could not create account");
        setRetryAccount(true);
        return;
      }

      // Link the user to the account
      linkUser({ accountId: account.id, userId: uid });

      // Link the device to the account
      linkDevice({ accountId: account.id, deviceId });

      // Set account ID for payment screen
      setCurrentAccount(account);
      setAccountId(account.id);
    },
    [linkUser, linkDevice, deviceId, setCurrentAccount]
  );

  const incrementStep = useCallback(() => {
    const arr = [...steps].map((step, idx) => {
      if (idx === currentStep) {
        step.isActive = false;
        step.isCompleted = true;
      }
      if (currentStep + 1 === idx) {
        step.isActive = true;
      }

      return {
        ...step,
      };
    });
    setSteps(arr);
    currentStep++;
  }, [steps]);

  const resetData = useCallback(() => {
    currentStep = 0;
    setFormValues(defaultValues);
    logOut();
  }, [defaultValues, logOut]);

  const onSubmit = useCallback(
    async (formData: FormDataType) => {
      const body = {
        force_username: false,
        username: formData.usernameStep.userName,
        force_password: false,
        password: formData.usernameStep.password,
        roles: ["SUBSCRIBER"],
        status: showBilling ? "PENDING" : "ACTIVE",
        can_review: true,
        default_url_subscriber_portal: "/dashboard#/account/status",
        ...((!userInfo?.data?.user?.answered_security_questions ||
          !isAuthenticated) && {
          security_answer1: formData.securityQuestionsStep?.question1Answer,
          security_answer2: formData.securityQuestionsStep?.question2Answer,
          security_question1: formData.securityQuestionsStep?.question1Option,
          security_question2: formData.securityQuestionsStep?.question2Option,
        }),
        default_url_management_portal: "",
        messaging: {
          ACCOUNT: ["EMAIL", "PORTAL"],
          FIRMWARE: ["EMAIL", "PORTAL"],
          OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
          SUBSCRIPTION: ["EMAIL", "PORTAL"],
          USER: ["EMAIL", "PORTAL"],
        },
        account_id: "",
        Contact: {
          id: "",
          provider_id: "",
          first_name: formData.contactStep.name,
          last_name: formData.contactStep.lastName,
          email: formData.usernameStep.userName,
          phone: formData.contactStep.phoneNumber,
          status: "ACTIVE",
          type: "BILLING",
          Location: {
            id: "",
            address1: formData.contactStep.address,
            address2: "",
            city: formData.contactStep.city,
            state: formData.contactStep.state,
            zip: formData.contactStep.zipCode,
            country: "US",
            lat: "",
            lon: "",
            elevation: "",
            uri: "",
          },
        },
        loading: "Contact.Location",
      };

      if (!newUserData) {
        await createUser(body)
          .then((res) => {
            if (res.message) {
              sendErrorToast(res.message);
            } else if (res.data) {
              setUserInfo((info: SingleUser) => {
                return {
                  ...info,
                  ...res,
                };
              });
              incrementStep();
            } else {
              sendErrorToast("Error creating user");
            }
          })
          .catch((e) => console.log("CREATE USER ERROR", e));
      } else if (retryAccount) {
        const uid = newUserData?.data?.user?.id;
        if (uid && deviceId) {
          createAccount({
            account_number: getAccountNumber(),
            name: `${formData.contactStep.lastName}, ${formData.contactStep.name}`,
            status: "PENDING",
            type: "RESIDENTIAL",
          }).then(async (resp) => {
            onAccountSuccess(uid, resp);
          });
        }
        setRetryAccount(false);
      } else {
        incrementStep();
      }
    },
    [
      showBilling,
      userInfo?.data?.user?.answered_security_questions,
      isAuthenticated,
      newUserData,
      retryAccount,
      createUser,
      setUserInfo,
      incrementStep,
      deviceId,
      createAccount,
      getAccountNumber,
      onAccountSuccess,
    ]
  );

  const handleNextStep = useCallback(
    (
      incomingValues?:
        | DeviceStepType
        | PersonalInformationStep
        | SecurityQuestionsStep
        | PaymentType
        | WarrantyStepType,
      submit: boolean = false
    ) => {
      if (incomingValues) {
        const data = {
          ...formValues,
          [`${incomingValues.stepName}`]: incomingValues,
        };
        setFormValues(data);

        if (submit && data) {
          onSubmit(data);
          return;
        }
      }

      incrementStep();
    },
    [formValues, onSubmit, incrementStep]
  );

  const handlePreviousStep = useCallback(() => {
    const arr = [...steps].map((step, idx) => {
      if (idx === currentStep) {
        step.isActive = false;
      }
      if (currentStep - 1 === idx) {
        step.isCompleted = false;
        step.isActive = true;
      }
      return {
        ...step,
      };
    });
    setSteps(arr);
    currentStep--;
  }, [steps]);

  useEffect(() => {
    if (provider && userInfo) {
      const usr = userInfo?.data?.user?.Contact;
      const name = usr?.first_name ?? "";
      const lastName = usr?.last_name ?? "";
      const phoneNumber = usr?.phone ?? "";
      const address = usr?.Location?.address1 ?? "";
      const city =
        usr?.Location?.city ??
        provider.data.provider.Contacts?.[0]?.Location?.city ??
        "";
      const state =
        usr?.Location?.state ??
        provider.data.provider.Contacts?.[0]?.Location?.state ??
        "";
      const zipCode =
        usr?.Location?.zip ??
        provider.data.provider.Contacts?.[0]?.Location?.zip ??
        "";
      setFormValues((previousValues) => ({
        ...previousValues,
        contactStep: {
          ...previousValues.contactStep,
          userName: userInfo?.data?.user?.username ?? "",
        },
        personalInformationStep: {
          ...previousValues.deviceStep,
          name,
          lastName,
          phoneNumber,
          address,
          city,
          state,
          zipCode,
        },
      }));
    }
  }, [provider, userInfo]);

  const createAccountForms: Array<{
    id: number;
    component: ReactNode;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 1,
        component: (
          <Suspense fallback={<div>Loading...</div>}>
            <DeviceInfo
              previousValues={formValues.deviceStep}
              handleNextStep={handleNextStep}
              setDeviceId={setDeviceId}
            />
          </Suspense>
        ),
        label: "deviceInfo",
      },
      {
        id: 2,
        component: (
          <Suspense fallback={<div>Loading...</div>}>
            <PersonalInformation
              previousValues={formValues.contactStep}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
            />
          </Suspense>
        ),
        label: "personalInformation",
      },
      {
        id: 3,
        component: (
          <Suspense fallback={<div>Loading...</div>}>
            <AccountSecurity
              previousValues={formValues.securityQuestionsStep}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              isLoading={isLoadingCreateUser || isLoadingCreateAccount}
            />
          </Suspense>
        ),
        label: "accountSecurity",
      },
      ...(showBilling
        ? [
            {
              id: 4,
              component: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ServiceCosts
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    setTypePayment={setTypePayment}
                  />
                </Suspense>
              ),
              label: "serviceCosts",
            },
            {
              id: 5,
              component: (
                <Suspense fallback={<div>Loading...</div>}>
                  <Payments
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    accountId={accountId}
                    warranty={{
                      maintenanceSel: formValues.warrantyStep.maintenanceSel,
                      insuranceSel: formValues.warrantyStep.insuranceSel,
                    }}
                    previousValues={formValues.paymentStep}
                    typePayment={typePayment}
                  />
                </Suspense>
              ),
              label: "payments",
            },
          ]
        : []),
      {
        id: 6,
        component: (
          <Suspense fallback={<div>Loading...</div>}>
            <AllDone handleResetSteps={resetData} />,
          </Suspense>
        ),
        label: "allDone",
      },
    ],
    [
      formValues.deviceStep,
      formValues.contactStep,
      formValues.securityQuestionsStep,
      formValues.warrantyStep.maintenanceSel,
      formValues.warrantyStep.insuranceSel,
      formValues.paymentStep,
      handleNextStep,
      handlePreviousStep,
      isLoadingCreateUser,
      isLoadingCreateAccount,
      showBilling,
      accountId,
      typePayment,
      resetData,
    ]
  );

  return (
    <Stack pb="4.5rem" bgcolor="#fff" height="100%">
      {!!isMobile ? (
        <Box>
          <Stepper steps={steps} />
          {createAccountForms[currentStep]?.component || <></>}
          <div
            style={{
              background: "#fff",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <EntryPointLink />
          </div>
        </Box>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "56px 0 2.5em 0",
              backgroundColor: "#fff",
              height: "100%",
            }}
          >
            <Grid
              container
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "100%",
                  flexDirection: "row",
                }}
              >
                <Header />
              </Grid>

              <Grid item md={9}>
                {createAccountForms[currentStep]?.component || <></>}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
              }}
              style={{ paddingLeft: "5rem" }}
            >
              <Card
                sx={{
                  borderRadius: "29px",
                  padding: "32px 0px 73px 0px",
                  background: "#fbfffe",
                  height: "640px",
                  width: "304px",
                  boxShadow: "0 3.3px 20.7px 0 rgba(201, 218, 216, 0.6)",
                  overflow: isMobile ? "" : "scroll",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#253331",
                      fontFamily: "Montserrat",
                      fontSize: getREMFromPX(20),
                      fontWeight: 600,
                      paddingLeft: "30px",
                    }}
                  >
                    Create Account
                  </Typography>
                  <Typography
                    sx={{
                      color: "#b0adad",
                      fontFamily: "Montserrat",
                      fontSize: getREMFromPX(10),
                      paddingRight: "29px",
                      paddingLeft: "30px",
                    }}
                  >
                    Follow the steps below to complete your set up!
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "2.5rem",
                      height: "440px",
                    }}
                  >
                    <Stepper steps={steps} />
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ backgroundColor: "#fff" }}>
            <Grid
              container
              xs={12}
              md={6}
              style={{
                paddingTop: "4px",
              }}
            >
              <Grid item xs={12} md={3}></Grid>
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <EntryPointLink />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

export default NewCreateAccount;

import RouterIcon from "@mui/icons-material/Router";
import {
  Typography,
  Stack,
  Divider,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import {
  dividerStyle,
  getDivStyles,
  iconStyles,
  normalisedButtonStyles,
  tableContainerStyles,
  backdropStyles,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { Port } from "../../api/interfaces/Port";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Table from "../Table";
import ProviderInfo from "../ProviderInfo";
import sendErrorToast from "../../utils/sendErrorToast";
import useArePortsLive from "../../hooks/useArePortsLive";
import { v4 as uuid } from "uuid";
import { useIsDeviceOn } from "../../hooks/useIsDeviceOn";
import { useQueryClient } from "react-query";
import { useDeleteSubscription } from "../../hooks/useDeleteSubscription";
import useGetUserId from "../../hooks/useGetUserId";
import sendSuccessToast from "../../utils/sendSuccessToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import UnsubscribeButton from "./components/UnsubscribeButton";
import UnsubscribeModal from "./components/UnsubscribeModal";
import { MappedPlan } from "../../routes/Plans/Plans";
import PlanDialog from "../PlanDialog/PlanDialog";
import { ServiceInfo } from "../../api/interfaces/ServiceInfo";
import { SortConfig } from "../../hooks/useSortableData";

const tableHeaders = [
  {
    name: "Port",
    style: { paddingLeft: getREMFromPX(theme.spacing * 4) },
  },
  {
    name: "Cable",
  },
  { name: "Provider", sortable: true, sortKey: "providerName" },
  { name: "Type" },
  { name: "Plan", sortable: true, sortKey: "planName" },
  { name: "Info" },
  { name: "", width: getREMFromPX(theme.spacing * 5) },
];

const portToMappedPlan = (port: Port): MappedPlan => {
  const validServices: ServiceInfo[] = port.Subscriptions.map(
    (subscription) => subscription?.Serviceplan.Service
  ).filter((service) => service?.Provider && service?.Plans);
  const mappedServices = validServices
    ?.map((service) =>
      service.Plans.map((plan) => ({
        plan,
        id: plan.id,
        serviceFields: service.Servicetype.fields_template_json,
        rating: plan.total_reviews ? Number(parseInt(plan.ratings_sum) / parseInt(plan.total_reviews)) : plan.total_reviews,
        providerName: service.Provider?.name as string,
        planName: plan.name,
        price: Number(plan.fields.Price),
        providerId: service.Provider?.id as string,
        providerInfo: service.Provider?.description as string,
        marketingUrl: service.marketing_url ?? ("-" as string),
        email: service.Provider?.Contacts?.[0]?.email ?? ("-" as string),
        phone: service.Provider?.Contacts?.[0]?.phone ?? ("-" as string),
      }))
    )
    .flat();

  return mappedServices[0];
};

const ServicesTable = ({
  serialNumber,
  routerName,
  ports,
  deviceId,
}: {
  serialNumber: string;
  routerName: string;
  ports: Port[];
  deviceId: string;
}) => {
  const themePath = useGetThemePath();
  const queryClient = useQueryClient();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");

  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const userId = useGetUserId();

  const { mutate: deleteSubscription, isLoading: isDeleting } =
    useDeleteSubscription({
      onSuccess: () => {
        setSelectedSubscriptionId("");
        queryClient.invalidateQueries(["ports", userId, selectedAccountId]);
        sendSuccessToast("Subscription deleted successfully!");
      },
      onError: () =>
        sendErrorToast(
          "There was a problem deleting the subscription, please try again"
        ),
    });
  const [showTable, setShowTable] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState<MappedPlan | null>(null);

  const { data: isDeviceOn, isLoading: isDeviceOnLoading } = useIsDeviceOn(
    deviceId,
    {
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast("There was an error getting the device information"),
    }
  );

  const mappedPorts = useMemo(
    () => ports.filter((port) => port.Subscriptions.length > 0),
    [ports]
  );

  const {
    data: livePorts,
    isLoading: areLivePortsLoading,
    error: haveLivePortsFailed,
  } = useArePortsLive(mappedPorts);

  useEffect(() => {
    if (haveLivePortsFailed) {
      sendErrorToast(
        "There was an error getting the ports information, please try again"
      );
    }
  }, [haveLivePortsFailed]);

  // merge the live port data that is fetched separately
  const mappedWithLive = useMemo(
    () => livePorts ? (
      mappedPorts.map((port) => {
        const live = livePorts.find((p) => p?.data?.portinterface?.id === port?.id)?.data?.portinterface
        return {
          ...port,
          live: live?.live
        }
      })
    ) : (mappedPorts),
    [mappedPorts, livePorts]);

  const toggleShowTable = () => setShowTable(!showTable);

  const isLoading = areLivePortsLoading || isDeviceOnLoading || isDeleting;

  const toggleModal = useCallback(
    (id = "") => {
      if (isDeleting) {
        return;
      }
      setSelectedSubscriptionId((oldId) => (oldId ? "" : id));
    },
    [isDeleting]
  );

  const columns = useMemo(
    () => [
      {
        getValue: (col: Port) => col.display_name,
        style: { paddingLeft: getREMFromPX(theme.spacing * 4) },
      },
      {
        getValue: (col: Port) => (
          col.live?.link_state === "up" ? "Plugged" : "Unplugged"
        )
      },
      {
        getValue: (col: Port) =>
          col.Subscriptions.map(
            (subscription) => subscription?.Serviceplan.Service.Provider?.name
          ) as string[],
      },
      {
        getValue: (col: Port) =>
          col.Subscriptions.map(
            (subscription) =>
              subscription?.Serviceplan.Service.Servicetype?.name
          ) as string[],
      },
      {
        getValue: (col: Port) =>
          col.Subscriptions.map(
            (subscription) => subscription?.Serviceplan.fields["Name"]
          ) as string[],
      },
      {
        render: (col: Port) => (
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPlan(portToMappedPlan(col));
              }}
            >
              <FontAwesomeIcon icon="info" style={iconStyles} />
            </IconButton>
          </>
        ),
      },
      {
        render: (col: Port) => (
          <UnsubscribeButton
            size="medium"
            text="Unsubscribe"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedSubscriptionId(col.Subscriptions[0].id);
            }}
          />
        ),
      },
    ],
    []
  );

  const customSortFunction = useCallback(
    (sortConfig: SortConfig) => {
      if (sortConfig?.key) {
        const mappedPortsCopy = [...mappedPorts];

        return mappedPortsCopy.sort((a, b) => {
          if (sortConfig.key === "providerName") {
            if (
              /* @ts-ignore */
              a.Subscriptions[0].Serviceplan.Service.Provider?.name <
              /* @ts-ignore */
              b.Subscriptions[0].Serviceplan.Service.Provider?.name
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }

            if (
              /* @ts-ignore */
              a.Subscriptions[0].Serviceplan.Service.Provider?.name >
              /* @ts-ignore */
              b.Subscriptions[0].Serviceplan.Service.Provider?.name
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
          if (sortConfig.key === "planName") {
            /* @ts-ignore */
            if (
              a.Subscriptions[0].Serviceplan.Service.Plans[0].name <
              b.Subscriptions[0].Serviceplan.Service.Plans[0].name
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            /* @ts-ignore */
            if (
              a.Subscriptions[0].Serviceplan.Service.Plans[0].name >
              b.Subscriptions[0].Serviceplan.Service.Plans[0].name
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
          return 0;
        });
      }
      return mappedPorts;
    },
    [mappedPorts]
  );

  return (
    <div style={tableContainerStyles}>
      <UnsubscribeModal
        open={!!selectedSubscriptionId}
        onClickSubscribe={() => deleteSubscription(selectedSubscriptionId)}
        onClickCancel={() => toggleModal()}
        onClose={() => toggleModal()}
        disableButtons={isDeleting}
      />
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        gap={getREMFromPX(theme.spacing * 4)}
        justifyContent="space-between"
        marginBottom={getREMFromPX(theme.spacing * 4)}
      >
        {isDeviceOn && (
          <>
            <Stack
              flexDirection="row"
              gap={getREMFromPX(theme.spacing * 2)}
              flexWrap="wrap"
            >
              <RouterIcon />
              <Typography
                fontSize={getREMFromPX(theme.spacing * 4.5)}
                fontWeight={theme.fonts.weights.mediumBold}
                color={theme[themePath].colors.textPrimary.primary}
              >
                {routerName}
              </Typography>
            </Stack>
            <Divider sx={dividerStyle} orientation="vertical" />
            <Typography
              color={theme[themePath].colors.textPrimary.placeholder}
              fontSize={getREMFromPX(theme.spacing * 4)}
            >
              {serialNumber}
            </Typography>
            <Stack
              flexWrap="wrap"
              justifyContent="center"
              padding="4px 8px"
              bgcolor={
                isDeviceOn.data.device === "ON"
                  ? theme[themePath].colors.okStatus
                  : theme[themePath].colors.lightWarningStatus
              }
              alignItems="center"
              borderRadius="8px"
            >
              <Typography
                color={theme[themePath].colors.white}
                fontSize={getREMFromPX(theme.spacing * 3)}
                textTransform="uppercase"
              >
                {isDeviceOn.data.device === "ON" ? "On" : "Off"}
              </Typography>
            </Stack>
            <Stack
              flexWrap="wrap"
              alignItems="center"
              flexDirection="row"
              padding={`${getREMFromPX(theme.spacing)} ${getREMFromPX(
                theme.spacing * 2
              )}`}
              borderRadius="8px"
              gap={getREMFromPX(theme.spacing * 2)}
              boxShadow={theme.boxShadow}
            >
              <div style={getDivStyles(themePath, isDeviceOn.data.device)} />
              <Typography
                fontSize={getREMFromPX(theme.spacing * 3)}
                color={theme[themePath].colors.textPrimary.primary}
              >
                WAN
              </Typography>
            </Stack>
            <Stack
              flexWrap="wrap"
              alignItems="center"
              flexDirection="row"
              gap={getREMFromPX(theme.spacing * 2)}
              padding={`${getREMFromPX(theme.spacing)} ${getREMFromPX(
                theme.spacing * 2
              )}`}
              borderRadius="8px"
              boxShadow={theme.boxShadow}
            >
              <div style={getDivStyles(themePath, isDeviceOn?.data.device)} />
              <Typography
                fontSize={getREMFromPX(theme.spacing * 3)}
                color={theme[themePath].colors.textPrimary.primary}
              >
                Status
              </Typography>
            </Stack>
          </>
        )}
        {!areLivePortsLoading &&
          !haveLivePortsFailed &&
          isDeviceOn &&
          livePorts.map((port) => (
            <Stack
              key={port?.data?.portinterface.id || uuid()}
              flexWrap="wrap"
              alignItems="center"
              flexDirection="row"
              gap={getREMFromPX(theme.spacing * 2)}
              padding={`${getREMFromPX(theme.spacing)} ${getREMFromPX(
                theme.spacing * 2
              )}`}
              borderRadius="8px"
              boxShadow={theme.boxShadow}
            >
              <div
                style={getDivStyles(
                  themePath,
                  port?.data?.portinterface?.live?.enabled &&
                    port.data?.portinterface.live.link_state === "up"
                    ? "on"
                    : "off",
                  port?.data?.portinterface?.live?.enabled &&
                    port?.data?.portinterface.live?.link_state === "down"
                )}
              />
              <Typography
                fontSize={getREMFromPX(theme.spacing * 3)}
                color={
                  port?.data?.portinterface.live?.link_state === "up"
                    ? theme[themePath].colors.textPrimary.primary
                    : theme[themePath].colors.textPrimary.placeholder
                }
              >
                {`Port ${port?.data?.portinterface.display_name}`}
              </Typography>
            </Stack>
          ))}

        <IconButton
          style={normalisedButtonStyles(themePath)}
          size="small"
          onClick={toggleShowTable}
          aria-label="toggle_router_table"
        >
          <FontAwesomeIcon icon={showTable ? "chevron-down" : "chevron-up"} />
        </IconButton>
      </Stack>
      {showTable && (
        <Table
          customSortFunction={customSortFunction}
          dataTestId="services_table"
          columns={columns}
          headers={tableHeaders}
          items={mappedWithLive}
          expandedComponent={(col: Port) => {
            const plan = col.Subscriptions[0].Serviceplan;
            return (
              <ProviderInfo
                isUnsuscribe
                planId={plan.id}
                id={
                  plan.Service.Provider?.id as string
                }
                providerName={
                  plan.Service.Provider
                    ?.name as string
                }
                fields={plan.fields}
                serviceFields={portToMappedPlan(col)?.serviceFields || ""}
                planName={plan.name}
                rating={plan.total_reviews ? Number(plan.ratings_sum) / Number(plan.total_reviews) : 0}
              />
            );
          }}
        />
      )}
      <PlanDialog
        isOpen={!!selectedPlan}
        onClose={() => setSelectedPlan(null)}
        plan={selectedPlan}
      />
      <Backdrop open={isLoading} sx={backdropStyles}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </div>
  );
};
export default ServicesTable;

import { Stack, Typography } from "@mui/material";
import Link from "../../../../components/Link";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { linkStyles, spanStyles } from "./styles";
import { routes } from "../../../../App";
import useViewport from "../../../../hooks/useViewport";

const Header = ({
  name,
  showBack,
  onClickNewService,
}: {
  name: string;
  showBack?: boolean;
  onClickNewService: () => void;
}) => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport();

  return (
    <>
      <Stack flexWrap="wrap" flexDirection="row" justifyContent="flex-end">
        {/* <Button
          onClick={onClickNewService}
          style={buttonStyles}
          startIcon={<AddOutlinedIcon />}
          text="New Service"
          size={isMobile ? "small" : "large"}
        /> */}
      </Stack>
      {!isMobile && (
        <Stack
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="flex-start"
          marginTop="0 !important"
        >
          <Typography
            component="h1"
            fontSize={getREMFromPX(theme.spacing * 10)}
            fontWeight={theme.fonts.weights.bolder}
            color={theme[themePath].colors.textPrimary.secondary}
          >
            Welcome{showBack ? " Back" : null}
            <span style={spanStyles}>
              <Link style={linkStyles} size="large" to={routes.profile.path}>
                , {name}
              </Link>
            </span>
          </Typography>
        </Stack>
      )}
    </>
  );
};
export default Header;

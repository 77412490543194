import styled from "@emotion/styled";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import Button from "../../../Button";

const UnsubscribeButton = styled(Button)(() => {
  const themePath = useGetThemePath();
  return {
    background: theme[themePath].colors.lightWarningStatus,
    "&:hover": {
      backgroundColor: theme[themePath].colors.strongWarningStatus,
    },
    "&:focus": {
      backgroundColor: theme[themePath].colors.strongWarningStatus,
    },
    "&:disabled": {
      backgroundColor: theme[themePath].colors.lightBorder,
      color: theme[themePath].colors.textSecondary.disabled,
      "& span": {
        color: `${theme[themePath].colors.textPrimary.disabled} !important`,
      },
    },
  };
});
export default UnsubscribeButton;

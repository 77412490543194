import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress, Stack, Typography, InputAdornment, IconButton } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useChangePassword } from "../../../../hooks/useChangePassword";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import useGetUserId from "../../../../hooks/useGetUserId";
import Button from "../../../../components/Button/Button";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input/Input";
import ErrorMessage from "../../../../components/ErrorMessage";
import getREMFromPX from "../../../../utils/getREMFromPX";
import sendErrorToast from "../../../../utils/sendErrorToast";
import isValidPassword from "../../../../utils/isValidPassword";
import { theme } from "../../../../theme";
import {
  getHeaderIconStyle,
  getHeaderStyle,
  iconStyle,
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad
} from "./Styles";
import { getEditStyle } from "../Contact/Styles";
import sendSuccessToast from "../../../../utils/sendSuccessToast";

export type PasswordType = {
  newPassword: string;
  confirmPassword: string;
};

export const Password = () => {
  const userId = useGetUserId();
  const themePath = useGetThemePath();

  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PasswordType>({
    mode: "onChange",
    defaultValues: { newPassword: "", confirmPassword: "" },
  });

  const newPassword = watch("newPassword").trim();
  const confirmPassword = watch("confirmPassword").trim();

  const {
    mutateAsync: changePassword,
    isLoading: passwordLoading,
  } = useChangePassword(userId, {
    onSuccess: () => {
      sendSuccessToast("Password changed");
    },
    onError: () =>
      sendErrorToast(
        "There was a problem changing the password, please try again"
      ),
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onSubmitHandler = useCallback(async (e:PasswordType) => {
    if (newPassword === confirmPassword) {
      changePassword({ newPassword, confirmPassword })
      setIsEditing(false);
    } else {
      sendErrorToast("Passwords don't match");
    }
  }, [changePassword, newPassword, confirmPassword]);

  const isLoading = passwordLoading;

  const disableButton =
    !isValid || !!Object.values(errors).length || newPassword !== confirmPassword;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordsMatch = !confirmPassword || confirmPassword === newPassword;

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={`0 0 ${getREMFromPX(theme.spacing * 3)} 0`}
      >
        <Stack direction="row" alignItems="center">
          <KeyIcon sx={getHeaderIconStyle(themePath)} />
          <Typography sx={getHeaderStyle}>
            {!isEditing ? "Password" : "Change Password"}
          </Typography>
        </Stack>
        {!isEditing && (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleEdit}
            sx={getEditStyle(themePath)}
            component="button"
          >
            <Typography component="span"> Change </Typography>
          </Stack>
        )}
      </Stack>
      {isEditing && (
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          data-testid="edit-username"
        >
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            direction="column"
            mt={getREMFromPX(theme.spacing * 8)}
          >
            <Label htmlFor="newPassword">New Password</Label>
            <Input
              id="newPassword"
              data-testid="newPassword"
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              placeholder="Enter your new password"
              error={(!!errors.newPassword && !!newPassword.length) || !passwordsMatch}
              softError={!!errors.newPassword || !newPassword.length || !passwordsMatch}
              style={inputAdornmentStyle}
              endAdornment={
                <InputAdornment position="end" style={iconStyleAdornment}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    style={iconStylePad}
                  >
                    {showPassword 
                      ? <FontAwesomeIcon icon="eye-slash" style={iconStyle} /> 
                      : <FontAwesomeIcon icon="eye" style={iconStyle} />
                    }
                  </IconButton>
                </InputAdornment>
              }
              {...register("newPassword", {
                required: true,
                validate: (value) => isValidPassword(value),
              })}
            />
          </Stack>
          <Typography
            margin={`${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
              theme.spacing * 1
            )} 0 ${getREMFromPX(theme.spacing * 1)}`}
            fontSize={getREMFromPX(theme.spacing * 2.5)}
          >
            Password must contain at least 8 characters and less than 16, at least 1
            uppercase and 1 lowercase letter, 1 number and 1 special character.
          </Typography>
          {errors.newPassword && newPassword && (
            <ErrorMessage
              fontSize={getREMFromPX(theme.spacing * 2.5)}
              margin={`0 ${getREMFromPX(theme.spacing * 1)}`}
            >
              Invalid Password. Please enter a new one.
            </ErrorMessage>
          )}
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            direction="column"
            mt={getREMFromPX(theme.spacing * 8)}
          >
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              id="confirmPassword"
              data-testid="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              placeholder="Confirm your new password"
              error={(!!errors.confirmPassword && !!confirmPassword.length) || !passwordsMatch}
              softError={!!errors.confirmPassword || !confirmPassword.length || !passwordsMatch}
              {...register("confirmPassword", {
                required: true,
                validate: (value) => value === newPassword,
              })}
            />
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            paddingTop={getREMFromPX(theme.spacing * 8)}
          >
            <Button
              data-testid="cancelButton"
              text="Cancel"
              type="button"
              size="large"
              variant="text"
              onClick={() => setIsEditing(false)}
            />
            <Button
              data-testid="saveButton"
              text="Save"
              type="submit"
              size="large"
              disabled={disableButton}
            />
          </Stack>
        </form>
      )}
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </Stack>
  );
};

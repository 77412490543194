import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, CircularProgress, Stack } from "@mui/material";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Box from "../Box";
import AppBar from "./components/AppBar";
import NavBarMenu from "./components/NavBarMenu";
import {
  iconStyles,
  EPLogoStylesBottom,
  getBoxStyles,
  headerLogoImage,
} from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
// import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { ReactComponent as EPLogo } from "../../assets/EPLogo.svg";
import useGetThemePath from "../../hooks/useGetThemePath";
import { useContext, useMemo } from "react";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import { MenuItemType } from "./components/AppBar";
import { NavBarMenuItem } from "./components/NavBarMenu";
import { routes } from "../../App";
import { useGetSystemVariables } from "../../hooks/useGetSystemVariables";
import sendErrorToast from "../../utils/sendErrorToast";
import CityIcon from "../CityIcon";

const Dashboard = ({
  boxBackground = true,
  children,
  profileLabel,
  selectedAddress,
  addresses,
  servicesNavBarMenu,
}: {
  boxBackground?: boolean;
  children?: React.ReactElement;
  profileLabel: string;
  selectedAddress: string;
  addresses: MenuItemType[];
  servicesNavBarMenu: NavBarMenuItem[];
}) => {
  const { isLoading } = useGetSystemVariables({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });
  const location = useLocation();
  const { pathname } = location;
  const themePath = useGetThemePath();
  const { logOut } = useContext(AuthContext) as AuthenticationContext;
  const navigate = useNavigate();

  // const navTroubleshootingMenuItems = useMemo(
  //   () => [
  //     /*   {
  //     label: "Connectivity",
  //     title: "Troubleshooting",
  //     divider: false,
  //     selected: false,
  //     path: "/connectivity",
  //     icon: <SignalWifiOffIcon style={iconStyles} />,
  //   }, */
  //     {
  //       label: "Support",
  //       title: "Troubleshooting",
  //       divider: false,
  //       selected: false,
  //       path: "",
  //       icon: <FontAwesomeIcon icon="headset" style={iconStyles} />,
  //     },
  //     /*   {
  //     label: "Walkthroughs",
  //     title: "",
  //     divider: false,
  //     selected: false,
  //     path: "/walkthrough",
  //     icon: <ContactSupportIcon style={iconStyles} />,
  //   }, */
  //   ],
  //   []
  // );

  const appBarProps = useMemo(
    () => ({
      selectedAddress,
      profileLabel,
      menuItems: [
        {
          label: "Account Settings",
          onClick: () => navigate(routes.profile.path),
        },
        { label: "Log Out", onClick: logOut },
      ],
      addresses,
    }),
    [logOut, profileLabel, addresses, selectedAddress, navigate]
  );

  const goToDashBoard = () => navigate(routes.dashboard.path);

  return (
    <>
      <Stack
        height="64px"
        alignItems="center"
        direction="row"
        component="header"
        padding={`${getREMFromPX(theme.spacing * 2)} 0`}
      >
        <Stack>
          <CityIcon style={headerLogoImage} />
        </Stack>
        <AppBar
          profileLabel={appBarProps.profileLabel}
          addresses={appBarProps.addresses}
          selectedAddress={appBarProps.selectedAddress}
          menuItems={appBarProps.menuItems}
        />
      </Stack>
      <Stack width="100%" flexDirection="row">
        <Backdrop open={isLoading}>
          <CircularProgress
            color="inherit"
            data-testid={isLoading ? "layout_progressSpinner" : ""}
          />
        </Backdrop>
        {boxBackground ? (
          <Box
            data-testid="boxBackground"
            component="section"
            minHeight="100vh"
            style={getBoxStyles(themePath)}
            hasBoxShadow
            width="100%"
          >
            <NavBarMenu
              onClick={goToDashBoard}
              label="Dashboard"
              divider
              selected={pathname === "/dashboard"}
              icon={
                <FontAwesomeIcon icon="table-cells-large" style={iconStyles} />
              }
            />
            {children}
          </Box>
        ) : (
          children
        )}
      </Stack>
      <Stack
        mt={`${getREMFromPX(theme.spacing * 4)} !important`}
        flexDirection="row"
        flexWrap="wrap"
        gap={getREMFromPX(theme.spacing * 6)}
        justifyContent="space-evenly"
      >
        <Stack
          direction="row"
          alignItems="center"
          paddingRight={getREMFromPX(theme.spacing * 4)}
        >
          <EPLogo style={EPLogoStylesBottom} />
        </Stack>
      </Stack>
    </>
  );
};

export default Dashboard;

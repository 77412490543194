import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography } from "@mui/material";
import SubHeader from "../../../../components/SubHeader";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import {
  boxStyles,
  getSelectedItemStyles,
  iconStyles,
  subHeaderContainer,
} from "./Styles";
import { v4 as uuid } from "uuid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ServicesInfoBox = ({
  header,
  headerIcon,
  items,
}: {
  header: string;
  headerIcon: IconProp;
  items: {
    text: string;
    value?: string | number;
    icon?: IconProp;
    selected?: boolean;
  }[];
}) => {
  const themePath = useGetThemePath();
  return (
    <Box style={boxStyles}>
      <Stack padding={getREMFromPX(theme.spacing * 6)}>
        <div style={subHeaderContainer}>
          <SubHeader
            subHeaderFontSize={getREMFromPX(theme.spacing * 4.5)}
            icon={headerIcon}
            headerText={header}
          />
        </div>
        <Stack direction="row" alignItems="top" justifyContent="center">
          {items.map((item) =>
            !item.selected ? (
              <Stack
                key={uuid()}
                paddingY={0}
                paddingX={getREMFromPX(theme.spacing * 4)}
                flex={1}
                justifyContent="center"
                alignItems="center"
                spacing={getREMFromPX(theme.spacing * 2)}
                width="min-content !important"
              >
                <Typography
                  color={theme[themePath].colors.textPrimary.secondary}
                  marginBottom={getREMFromPX(theme.spacing * 2)}
                >
                  {item.text}
                </Typography>
                <Typography
                  fontWeight={theme.fonts.weights.bolder}
                  color={theme[themePath].colors.textPrimary.primary}
                  
                >
                  {item.icon && (
                    <FontAwesomeIcon
                      color={theme[themePath].colors.secondary[500]}
                      icon={item.icon}
                      fontSize={getREMFromPX(theme.spacing * 4)}
                      style={iconStyles}
                    />
                  )}
                  {item.value}
                </Typography>
              </Stack>
            ) : (
              <Stack
                key={uuid()}
                paddingX={getREMFromPX(theme.spacing * 2)}
                borderRadius="8px"
                justifyContent="center"
                alignItems="center"
                flex={1}
                style={getSelectedItemStyles(themePath)}
                spacing={getREMFromPX(theme.spacing)}
              >
                <Typography
                  fontWeight={theme.fonts.weights.bolder}
                  color={theme[themePath].colors.white}
                >
                  {item.text}
                </Typography>
                <Typography
                  fontSize={getREMFromPX(theme.spacing * 6)}
                  //fontWeight={theme.fonts.weights.bolder}
                  color={theme[themePath].colors.white}
                >
                  {item.value}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ServicesInfoBox;

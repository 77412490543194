import { useState, useMemo, useContext } from "react";
import { Stack, Typography, TextField } from "@mui/material";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import Label from "../Label";
import { useForm } from "react-hook-form";
import sendErrorToast from "../../utils/sendErrorToast";
import { Review } from "../../api/interfaces/Review";
import { useCreateReview } from "../../hooks/useCreateReview";
import Rating from "../../components/Rating";
import Button from "../../components/Button";
import useGetUserId from "../../hooks/useGetUserId";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { Subscriptionhistory } from "../../api/interfaces/Subscription";


interface ReviewType {
  comment: string;
  rating: number;
}

interface ReviewFormProps {
  review: Review | undefined;
  history: Subscriptionhistory;
  providerName: string;
  planName: string;
  newRating?: number | null;
  onSuccess?: () => void;
}

const ReviewForm = ({ review, newRating, history, providerName, planName, onSuccess }:ReviewFormProps) => {
  const { refetchUser } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;
  const [rating, setRating] = useState<number | null>(newRating ?? review?.rating ?? null)
  const {
    watch,
    register,
    handleSubmit,
  } = useForm<ReviewType>({
    mode: "onChange",
    defaultValues: useMemo(() => ({ comment: '', ...review }), [review]),
  });

  const {
    mutateAsync: mutateReview,
  } = useCreateReview(useGetUserId(), {
    onError: () => {
      fail("There was an error submitting the review, please try again");
    },
    onSuccess: () => {
      onSuccess?.();
      refetchUser();
    }
  });

  const comment = watch("comment").trim();

  const onSubmitHandler = () => {
    if (!rating) {
      sendErrorToast('Please select a rating');
    } else {
      mutateReview({ id: review?.id, rating, comment, subscriptionhistory_id: history.id });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      data-testid="reviewForm"
    >
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        mt={getREMFromPX(theme.spacing * 4)}
        minWidth={getREMFromPX(theme.spacing * 120)}
      >
        <Typography>{providerName} - {planName}</Typography>
        <Rating individual={true} value={Number(rating)} onChange={(e, val) => setRating(val)} fontSize={getREMFromPX(theme.spacing * 6)} />
        <Label htmlFor="comment">Comment</Label>
        <TextField id="comment" multiline {...register("comment")} />
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          data-testid="continueButton"
          text="Save"
          type="submit"
          size="medium"
        />
      </Stack>
    </form>
  );
};

export default ReviewForm;

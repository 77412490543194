import { Backdrop, CircularProgress, Stack } from "@mui/material";
import ServiceBox from "../../components/ServiceBox";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import SubHeader from "../../components/SubHeader";
import {
  spinnerContainerStyles,
} from "./styles";
import { useGetServices } from "../../hooks/useGetServices";
import Dashboard from "../Dashboard";
import BreadCrumbs from "../../components/BreadCrumbs";
import { routes } from "../../App";
import { useContext } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import PrivateAccess from "../../components/PrivateAccess";
import Warning from "../../components/Warning";

const Services = () => {
  const { isLoading: isContextLoading, selectedDeviceId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { data: services, isLoading: areServicesLoading } = useGetServices({
    refetchOnWindowFocus: false,
  });

  const isLoading = areServicesLoading || isContextLoading;

  const breadCrumbs = [
    {
      name: "Add New Service",
      pathname: routes.services.path,
    },
  ];

  return (
    <Dashboard>
      <Stack
        spacing={getREMFromPX(theme.spacing * 8)}
        marginLeft={getREMFromPX(theme.spacing)}
      >
        <Stack flexDirection="row">
          <div style={{ flex: 1 }}>
            <BreadCrumbs breadCrumbs={breadCrumbs} />
          </div>
          <PrivateAccess />
        </Stack>
        <Stack
          spacing={getREMFromPX(theme.spacing * 8)}
          padding={`${getREMFromPX(theme.spacing * 6)} ${getREMFromPX(
            theme.spacing * 6
          )} 0 ${getREMFromPX(theme.spacing * 8)}`}
          mt={`0 !important`}
        >
          {!isLoading && !selectedDeviceId && (
            <Warning message="No device is associated with your account." />
          )}
          <SubHeader icon="house-signal" headerText="Available Services" />
          <Stack
            mt={`${getREMFromPX(theme.spacing * 4)} !important`}
            flexDirection="row"
            flexWrap="wrap"
            gap={getREMFromPX(theme.spacing * 6)}
          >
            {services?.data.servicetype.map((service) =>
              service.display ? (
                <ServiceBox
                  image={service.image}
                  serviceId={service.id}
                  key={service.id}
                  title={service.name}
                  tooltipDesc={service.description}
                  disabled={service.Services?.filter(s => !!s.Provider)?.length <= 0}
                  description="Look for the best provider and subscribe to the plan that fits best to you."
                />
              ) : null
            )}
          </Stack>
        </Stack>
        <div style={spinnerContainerStyles}>
          <Backdrop open={isLoading}>
            <CircularProgress data-testid="progressSpinner" color="inherit" />
          </Backdrop>
        </div>
      </Stack>
    </Dashboard>
  );
};
export default Services;

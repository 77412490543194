import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const serviceBoxStyles = {
  maxWidth: getREMFromPX(theme.spacing * 70.5),
  borderRadius: "8px",
  backgroundColor: theme.backgrounds.widget,
  boxShadow: theme.boxShadow,
};

export const dividerStyle = {
  marginTop: `${getREMFromPX(theme.spacing * 4)}!important`,
  width: "100%",
};

export const getIconStyles = (themePath = "default") => ({
  fontSize: getREMFromPX(theme.spacing * 8),
  color: theme[themePath].colors.primary[500],
});

import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const headerStyle = {
  fontSize: getREMFromPX(theme.spacing * 5),
  fontWeight: theme.fonts.weights.bolder,
};

export const dividerStyle = {
  marginTop: getREMFromPX(theme.spacing * 8),
};

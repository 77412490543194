import { Stack, Chip } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "../../../Menu";
import MenuItem from "../../../MenuItem";
import {
  getChipArrowStyles,
  getChipStyles,
} from "./Styles";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import useMenuAnchor from "../../../../hooks/useMenuAnchor";

export type MenuItemType = {
  label: string;
  onClick?: () => void;
};

const AppBar = ({
  addresses,
  profileLabel,
  menuItems,
  selectedAddress,
  gapWidth = "248px",
}: {
  addresses: MenuItemType[];
  selectedAddress: string;
  profileLabel: string;
  menuItems: MenuItemType[];
  gapWidth?: string | number;
}) => {
  const themePath = useGetThemePath();
  const {
    anchorEl: chipAnchorEl,
    open: chipOpenMenu,
    handleClick: handleChipMenuClick,
    handleClose: handleChipMenuClose,
  } = useMenuAnchor();
//   const {
//     anchorEl: addressAnchorEl,
//     open: addressOpenMenu,
//     handleClick: handleAddressMenuClick,
//     handleClose: handleAddressMenuClose,
//   } = useMenuAnchor();
  const menuListProps = {
    "aria-labelledby": "profile_options_button",
  };

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="end"
      //width={`calc(100% - ${gapWidth})`}
      width="-webkit-fill-available"
      paddingRight="1.5rem"
      flexWrap="wrap"
    >
      {/* <Stack direction="row" spacing={getREMFromPX(theme.spacing * 2)}>
        <RoomIcon fontSize="small" />
        <Stack flexDirection="row">
          <Typography
            id="address_menu"
            fontSize={getREMFromPX(theme.spacing * 4)}
            onClick={handleAddressMenuClick}
          >
            {selectedAddress}
          </Typography>
          {addresses?.length > 1 && (
            <>
              <button style={buttonStyles} onClick={handleAddressMenuClick}>
                <ArrowDropDownIcon
                  fontSize='small'
                  style={getAddressArrowStyles(themePath)}
                />
              </button>
              <Menu
                id="address-menu"
                anchorEl={addressAnchorEl}
                open={addressOpenMenu}
                onClose={handleAddressMenuClose}
                MenuListProps={menuListProps}
              >
                {addresses
                  .filter((address) => address.label !== selectedAddress)
                  .map(({ label, onClick }) => (
                    <MenuItem
                      key={label}
                      onClick={() => {
                        handleAddressMenuClose();
                        onClick?.();
                      }}
                    >
                      {label}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          )}
        </Stack>
      </Stack> */}
        <Chip
          id="profile_options_button"
          onClick={handleChipMenuClick}
          style={getChipStyles(chipOpenMenu, themePath)}
          icon={<PermIdentityIcon />}
          label={profileLabel}
          deleteIcon={
            <ArrowDropDownIcon
              fontSize="small"
              style={getChipArrowStyles(themePath)}
            />
          }
          onDelete={handleChipMenuClick}
        />

        <Menu
          id="profile-menu"
          anchorEl={chipAnchorEl}
          open={chipOpenMenu}
          onClose={handleChipMenuClose}
          MenuListProps={menuListProps}
        >
          {menuItems.map(({ label, onClick }) => (
            <MenuItem
              key={label}
              onClick={() => {
                handleChipMenuClose();
                onClick?.();
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
    </Stack>
  );
};
export default AppBar;

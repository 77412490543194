import React, { useCallback, useContext, useState } from "react";
import NewButton from "../../../components/NewButton";
import { Plan } from "../utils/intefaces/Plan";
import { Box, Grid, Typography, styled } from "@mui/material";
import { renderStars } from "../utils/getStars";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { LogoContainer } from "./CardService";
import { useDeleteSubscription } from "../../../hooks/useDeleteSubscription";
import { useDeleteReview } from "../../../hooks/useDeleteReview";
import useGetUserId from "../../../hooks/useGetUserId";
import sendErrorToast from "../../../utils/sendErrorToast";
import { useQueryClient } from "react-query";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import UnsubscribePopup from "./UnsubscribePopup";
import { Review } from "../../../api/interfaces/Review";
import { InfoButtonIcon } from "../../../assets/icons";
import ReviewModal from "../../../components/ReviewModal";
import Menu from "../../../components/Menu";
import MenuItem from "../../../components/MenuItem";
import useMenuAnchor from "../../../hooks/useMenuAnchor";

const StyledParagraph = styled("p")`
  color: ${theme.new.colors.darkGreen};
  font-family: "Montserrat";
  font-size: ${getREMFromPX(15)};
`;

export const SubscriptionHeaderTable = () => (
  <Grid
    container
    spacing={2}
    alignItems="center"
    sx={{
      backgroundColor: "rgba(217, 217, 217, 0.27)",
    }}
    borderRadius="20px"
  >
    <Grid
      item
      xs={4}
      sm={1}
      textAlign="center"
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    >
      <Typography
        color={theme.new.colors.formGrey}
        fontSize={getREMFromPX(14)}
        fontFamily="Montserrat"
        fontWeight={500}
        textAlign="left"
      >
        Port
      </Typography>
    </Grid>
    <Grid
      item
      xs={4}
      sm={1}
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    >
      <Typography
        color={theme.new.colors.formGrey}
        fontSize={getREMFromPX(14)}
        fontFamily="Montserrat"
        fontWeight={500}
        textAlign="left"
      >
        Provider
      </Typography>
    </Grid>
    <Grid
      item
      xs={4}
      sm={3}
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    >
      <Typography
        color={theme.new.colors.formGrey}
        fontSize={getREMFromPX(14)}
        fontFamily="Montserrat"
        fontWeight={500}
        textAlign="left"
      >
        Plan
      </Typography>
    </Grid>
    <Grid
      item
      xs={4}
      sm={1}
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    >
      <Typography
        color={theme.new.colors.formGrey}
        fontSize={getREMFromPX(14)}
        fontFamily="Montserrat"
        fontWeight={500}
        textAlign="left"
      >
        Type
      </Typography>
    </Grid>
    <Grid
      item
      xs={4}
      sm={1}
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    >
      <Typography
        color={theme.new.colors.formGrey}
        fontSize={getREMFromPX(14)}
        fontFamily="Montserrat"
        fontWeight={500}
        textAlign="left"
      >
        Price
      </Typography>
    </Grid>
    <Grid
      item
      xs={4}
      sm={2}
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    >
      <Typography
        color={theme.new.colors.formGrey}
        fontSize={getREMFromPX(14)}
        fontFamily="Montserrat"
        fontWeight={500}
        textAlign="left"
      >
        Reviews
      </Typography>
    </Grid>
    <Grid
      item
      xs={4}
      sm={1}
      display="flex"
      style={{ paddingTop: "3px", paddingBottom: "4px" }}
    ></Grid>
    <Grid item xs={4} sm={2}></Grid>
  </Grid>
);

const menuListProps = {
  "aria-labelledby": "profile_options_button",
};

const MySubscriptionsRow = ({
  total_reviews,
  ratings_sum,
  serviceType,
  planName,
  port,
  providerId,
  price,
  currentIndex,
  unsubscribeId,
  review,
  historyId,
  dataCy,
}: Partial<Plan> & {
  serviceType: string;
  port: string;
  price: string;
  currentIndex: number;
  unsubscribeId: string;
  review?: Review;
  historyId: string;
  dataCy?: string;
}) => {
  const { currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const queryClient = useQueryClient();
  const userId = useGetUserId();
  const { mutate: deleteSubscription, isLoading: isDeleting } =
    useDeleteSubscription({
      onSuccess: () => {
        setSelectedSubscriptionId("");
        queryClient.invalidateQueries([
          "ports",
          "subscriptions",
          userId,
          currentAccount.id,
        ]);
        window.location.reload();
      },
      onError: () =>
        sendErrorToast(
          "There was a problem deleting the subscription, please try again"
        ),
    });
  const { mutate: deleteReview } = useDeleteReview(currentAccount.id, {
    onSuccess: () => window.location.reload(),
  });

  const reviewsAverage =
    ratings_sum && Number(total_reviews) !== 0
      ? (Number(ratings_sum) / Number(total_reviews)).toString()
      : "0";

  const stars = renderStars(reviewsAverage);
  const toggleModal = useCallback(
    (id = "") => {
      if (isDeleting) {
        return;
      }
      setSelectedSubscriptionId((oldId) => (oldId ? "" : id));
    },
    [isDeleting]
  );

  const [openModal, setOpenModal] = useState(false);

  const {
    anchorEl: reviewMenuAnchorEl,
    open: reviewOpenMenu,
    handleClick: handleReviewMenuClick,
    handleClose: handleReviewMenuClose,
  } = useMenuAnchor();

  const closeModal = useCallback(() => {
    handleReviewMenuClose();
    setOpenModal(false);
  }, [handleReviewMenuClose]);

  return (
    <>
      <ReviewModal
        open={openModal}
        closeModal={closeModal}
        review={review}
        planName={planName ?? ""}
        historyId={historyId as string}
      />
      {currentIndex === 0 && <SubscriptionHeaderTable />}
      <Grid
        container
        spacing={2}
        alignItems="center"
        overflow="scroll"
        data-cy={dataCy}
      >
        <Grid
          item
          xs={12}
          sm={1}
          style={{ paddingLeft: "3px" }}
          data-cy={`my-services-row-${currentIndex}-port`}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "1px solid #efefef",
              width: "65px",
              margin: "0 auto",
              height: "28px",
            }}
          >
            <Typography
              color={theme.new.colors.darkGreenFontColor}
              fontSize={getREMFromPX(12)}
              fontFamily="Montserrat"
              fontWeight={500}
              textAlign="center"
            >
              {port}
            </Typography>
            <Box
              sx={{
                color: "#84e88e",
                background: "#84e88e",
                width: "6.6px",
                height: "6.6px",
                borderRadius: "10px",
                border: "1px solid #84e88e",
                marginLeft: "4px",
              }}
            >
              &nbsp;
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          style={{ paddingLeft: "0" }}
          data-cy={`my-services-row-${currentIndex}-provider`}
        >
          <LogoContainer width="105" height="55">
            <img
              src={`${process.env.PUBLIC_URL}/assets/provider/${providerId}.png`}
              alt=""
            />
          </LogoContainer>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          data-cy={`my-services-row-${currentIndex}-plan`}
        >
          <StyledParagraph style={{ fontWeight: 500 }}>
            {planName}
          </StyledParagraph>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          data-cy={`my-services-row-${currentIndex}-type`}
        >
          <StyledParagraph>{serviceType}</StyledParagraph>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          data-cy={`my-services-row-${currentIndex}-price`}
        >
          <Typography
            color={theme.new.colors.darkGreen}
            fontSize={getREMFromPX(15)}
            fontFamily="Montserrat"
            fontWeight={500}
          >
            ${price}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          data-cy={`my-services-row-${currentIndex}-review`}
        >
          <StyledParagraph style={{ fontWeight: 500 }}>{stars}</StyledParagraph>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          data-cy={`my-services-row-${currentIndex}-review-actions`}
        >
          <StyledParagraph
            style={{
              fontWeight: "600",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: getREMFromPX(14),
              color: theme.new.colors.darkGreen,
            }}
            onClick={(event) => {
              if (review) {
                handleReviewMenuClick(event);
              } else {
                setOpenModal(true);
              }
            }}
            data-cy={`my-services-row-${currentIndex}-add-review`}
          >
            {review ? (
              <div>
                <InfoButtonIcon />
              </div>
            ) : (
              "Add Review"
            )}
          </StyledParagraph>
          <Menu
            id="review-menu"
            anchorEl={reviewMenuAnchorEl}
            open={reviewOpenMenu}
            onClose={handleReviewMenuClose}
            MenuListProps={menuListProps}
          >
            <MenuItem
              onClick={() => {
                handleReviewMenuClose();
                setOpenModal(true);
              }}
            >
              Edit Review
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleReviewMenuClose();
                deleteReview(review?.id ?? "");
              }}
            >
              Delete Review
            </MenuItem>
          </Menu>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          display="flex"
          justifyContent="center"
          data-cy={`my-services-row-${currentIndex}-unsubscribe`}
        >
          <NewButton
            text="Unsubscribe"
            mode="default"
            sx={{
              width: "120px",
              height: "39px",
              fontSize: getREMFromPX(12),
              fontWeight: 600,
              backgroundColor: "#ff7a00",
              color: "#fff",
              borderRadius: "30px",
              "&:hover": {
                backgroundColor: "#ff7a00",
              },
              "&active": {
                backgroundColor: "#ff7a00",
              },
              "&:focus": {
                border: "1px solid #ff7a00",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedSubscriptionId(providerId ?? "");
            }}
          />
        </Grid>
      </Grid>
      <UnsubscribePopup
        open={!!selectedSubscriptionId}
        onClickSubscribe={() => deleteSubscription(unsubscribeId)}
        onClose={() => toggleModal()}
        isLoading={isDeleting}
        name={serviceType as string}
        price={price as string}
        selectedId={providerId as string}
      />
    </>
  );
};

export default MySubscriptionsRow;

import { theme } from '../../../../theme';
import getREMFromPX from '../../../../utils/getREMFromPX';

export const getHeaderIconStyle = (themePath = "default") => ({ 
  fontSize: getREMFromPX(theme.spacing * 4), 
  color: theme[themePath].colors.textPrimary.secondary
})

export const getHeaderStyle = {
  fontWeight: theme.fonts.weights.mediumBold,
  marginLeft: getREMFromPX(theme.spacing * 2),
  fontSize: getREMFromPX(theme.spacing * 4.5)
} 

export const notificationContainerStyle = (isMobile: any) => ({
  width: isMobile ? "auto" : "60%",
});
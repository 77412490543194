import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import Alert from "../../components/Alert";
import Layout from "../../components/Layout";
import ServiceBox from "../../components/ServiceBox";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Header from "./components/Header";
import NoServicesAlert from "../../components/NoServicesAlert";
import SubHeader from "../../components/SubHeader";
import {
  buttonStyles,
  findAPlanButtonStyles,
  introAlertStyles,
  spinnerContainerStyles,
  getAddressArrowStyles,
  buttonStylesN
} from "./styles";
import sendErrorToast from "../../utils/sendErrorToast";
import React, { useContext, useEffect, useMemo } from 'react';
import Button from "../../components/Button";
import { useGetServices } from "../../hooks/useGetServices";
import { useGetConfig } from "../../hooks/useGetConfig";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { useGetPorts } from "../../hooks/useGetPorts";
import useGetUserId from "../../hooks/useGetUserId";
import { Port } from "../../api/interfaces/Port";
import Warning from "../../components/Warning";
import ServicesTableContainer from "./components/ServicesTableContainer";
import ServicesInfoBox from "./components/ServicesInfoBox";
import ReviewBox from "./components/ReviewBox";
import ServicesTable from "../../components/ServicesTable";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useViewport  from "../../hooks/useViewport";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "../../components/Menu";
import MenuItem from "../../components/MenuItem";
import useMenuAnchor from "../../hooks/useMenuAnchor";

const Dashboard = ({ children }: { children?: React.ReactElement }) => {
  const {
    addresses,
    userInfo,
    provider,
    selectedAddress,
    selectedDeviceId,
    selectedAccountId,
    isLoading: isContextLoading,
  } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;

  const userId = useGetUserId();
  const { isMobile } = useViewport();

  const {
    anchorEl: addressAnchorEl,
    open: addressOpenMenu,
    handleClick: handleAddressMenuClick,
    handleClose: handleAddressMenuClose,
  } = useMenuAnchor();

  const menuListProps = {
    "aria-labelledby": "profile_options_button",
  };

  const { data: subscriptionsData, isLoading: arePortsLoading } =
    useGetPorts(userId, selectedAccountId, {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    });

  const { data: services, isLoading: areServicesLoading } = useGetServices({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "Sorry, there was a problem retrieving the available services"
      ),
  });

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;
  const showReviews = config?.data?.config?.enabled_features?.USER_REVIEWS?.enabled;

  const internetService = useMemo(
    () =>
      services?.data.servicetype.find(
        (service) => service.name === "Internet Service"
      ),
    [services]
  )?.id;

  const navigate = useNavigate();

  const themePath = useGetThemePath();

  useEffect(() => {
    if (userInfo?.data?.user?.force_password) {
      navigate(routes.updateAccount.path);
    }
  }, [userInfo, navigate])

  const userName = userInfo?.data?.user?.Contact?.first_name || "";

  const composedAddress = userInfo
    ? `${userInfo?.data?.user?.Contact?.Location?.city}, ${userInfo?.data?.user?.Contact?.Location?.state} ${userInfo?.data?.user?.Contact?.Location?.zip}`
    : "";

  const composedCity =
    (provider?.data.provider.Contacts &&
      `Welcome to the ${provider?.data?.provider?.Contacts[0]?.Location.city} City Network`) ||
    "";

  const isLoading = areServicesLoading || isContextLoading || arePortsLoading;

  const goToServices = () => navigate(routes.services.path);

  const subscriptions = useMemo(
    () =>
      subscriptionsData?.data?.user?.Accounts?.filter(
        (account) => account.id === selectedAccountId
      ).filter((account) => {
        const device = account.Devices?.filter(
          (device) => device.id === selectedDeviceId
        ).some((device) =>
          device.Portinterfaces?.some((port) => port.Subscriptions.length > 0)
        );
        return device;
      }),
    [
      subscriptionsData?.data?.user?.Accounts,
      selectedAccountId,
      selectedDeviceId,
    ]
  );

  const noHistoricalSubscriptions =
    userInfo?.data?.user?.Subscriptionhistory &&
    userInfo.data.user.Subscriptionhistory.length === 0;

  const areSubscriptions =
    subscriptions && subscriptions.length > 0;

  // const totalSubscriptionPrices = useMemo(
  //   () =>
  //     subscriptions
  //       ?.map((subscription) =>
  //         subscription.Devices?.map((device) =>
  //           device.Portinterfaces?.map((port) =>
  //             port.Subscriptions.reduce((previousValue, currentValue) => {
  //               return (
  //                 previousValue + Number(currentValue.Serviceplan.fields.Price)
  //               );
  //             }, 0)
  //           )
  //         ).flat()
  //       )
  //       .flat()
  //       ?.reduce((previousValue, currentValue) => {
  //         return (previousValue as number) + (currentValue as number);
  //       }, 0),
  //   [subscriptions]
  // );

  const totalSubscriptionByServiceType =  useMemo(() =>
      subscriptions
        ?.map((subscription) =>
          subscription.Devices?.map((device) =>
            device.Portinterfaces?.map((port) =>
              port.Subscriptions?.map((subscription) =>
                subscription.Serviceplan.Service.Servicetype
              ).flat()
            ).flat()
          ).flat()
        ).flat()
        .reduce((prev, current) => {
          if (current) {
            prev[current.name] = {
              count: (prev[current.name]?.count || 0) + 1,
              icon: current.image 
            };
          }
          return prev;
        }, {} as { [key: string]: { count: number, icon: string } }) ?? {}
    ,[subscriptions]
  )

  const totalSubscriptionBillingByServiceType =  useMemo(() =>
     subscriptions
        ?.map((subscription) =>
          subscription.Devices?.map((device) =>
            device.Portinterfaces?.map((port) =>
              port.Subscriptions?.map((subscription) => {
                return {'name': subscription.Serviceplan.Service.Servicetype.name,
                        'price': subscription.Serviceplan.fields.Price
                        }
              }
              ).flat()
            ).flat()
          ).flat()
        ).flat()
        .reduce((prev, current) => {
          if (current) {
            prev[current.name] = {
              price: Number(current.price) + ( Number(prev[current.name]?.price) || 0),
               // prev.price ? Number(prev.price): 0), (prev[current.name]?.count || 0) + 1,
              //icon: current.image 
            };
          }
          return prev;
        }, {} as { [key: string]: { price: number } }) ?? {}
    ,[subscriptions]
  )

  const subscriptionsItems = [
    ...Object.keys(totalSubscriptionByServiceType).map(name => ({
      text: name,
      value: totalSubscriptionByServiceType[name].count ?? 0,
      icon: totalSubscriptionByServiceType[name].icon as IconProp,
    })),
    {
      text: "Total",
      value: Object.values(totalSubscriptionByServiceType).reduce((curr, prev) => curr + prev.count, 0),
      selected: true,
    },
  ];

  const subscriptionsBillingItems = [
    ...Object.keys(totalSubscriptionBillingByServiceType).map(name => ({
      text: name,
      value: `$${(totalSubscriptionBillingByServiceType[name].price ?? 0).toFixed(2)}`,
    })),
    {
      text: "Total",
      value: `$${Object.values(totalSubscriptionBillingByServiceType).reduce((curr, prev) => curr + prev.price, 0)?.toFixed(2)}`,
      selected: true,
    },
  ];

  // const subscriptionsBillingItems = [
  //   {
  //     text: "Subscriptions",
  //     value: `$${totalSubscriptionPrices?.toFixed(2)}`,
  //   },
  //   {
  //     text: "Total",
  //     value: `$${totalSubscriptionPrices?.toFixed(2)}`,
  //     selected: true,
  //   },
  // ];
  const goToInternetServices = () =>
    navigate(
      `${routes.services.path}/${encodeURIComponent(
        internetService as string
      )}/plans`
    );

  const newServiceMenuItem = [
    {
      label: "",
      title: "",
      divider: true,
      selected: false,
      children: (
        <Button
          onClick={goToServices}
          text="Add New Service"
          variant="outlined"
          size="medium"
          style={buttonStyles}
        />
      ),
    },
  ];

  return (
    <Layout
      profileLabel={userName}
      addresses={addresses}
      selectedAddress={selectedAddress}
      servicesNavBarMenu={newServiceMenuItem}
    >
      {children || (
        <>
          <Stack spacing={getREMFromPX(theme.spacing * 8)}>
            {noHistoricalSubscriptions && (
              <Alert isClosable style={introAlertStyles}>
                <Typography
                  fontWeight={theme.fonts.weights.bolder}
                  color={theme[themePath].colors.textPrimary.primary}
                  fontSize={getREMFromPX(theme.spacing * 6)}
                >
                  {composedCity}
                </Typography>
              </Alert>
            )}
            <Stack
              spacing={getREMFromPX(theme.spacing * 8)}
              padding={`${getREMFromPX(theme.spacing * 6)} ${getREMFromPX(
                theme.spacing * 6
              )} 0 ${getREMFromPX(theme.spacing * 8)}`}
            >
              <Header
                name={userName}
                showBack={!noHistoricalSubscriptions}
                onClickNewService={goToServices}
              />
              <Typography
                marginTop="0 !important"
                color={theme[themePath].colors.textPrimary.primary}
                fontSize={isMobile ? getREMFromPX(theme.spacing * 3) : getREMFromPX(theme.spacing * 5)}
                fontWeight={theme.fonts.weights.bolder}
              >
                {composedAddress}
              </Typography>
              <Stack direction="row" spacing={getREMFromPX(theme.spacing * 2)} mt="0 !important">
                <RoomIcon fontSize="small" />
                <Stack flexDirection="row">
                  <Typography
                    id="address_menu"
                    fontSize={getREMFromPX(theme.spacing * 4)}
                    onClick={handleAddressMenuClick}
                  >
                    {selectedAddress}
                  </Typography>
                  {addresses?.length > 1 && (
                    <>
                      <button style={buttonStylesN} onClick={handleAddressMenuClick}>
                        <ArrowDropDownIcon
                          fontSize= 'medium'//{isMobile ? 'large' :  'medium'}
                          style={getAddressArrowStyles(themePath)}
                        />
                      </button>
                      <Menu
                        id="address-menu"
                        anchorEl={addressAnchorEl}
                        open={addressOpenMenu}
                        onClose={handleAddressMenuClose}
                        MenuListProps={menuListProps}
                      >
                        {addresses
                          .filter((address) => address.label !== selectedAddress)
                          .map(({ label, onClick }) => (
                            <MenuItem
                              key={label}
                              onClick={() => {
                                handleAddressMenuClose();
                                onClick?.();
                              }}
                            >
                              {label}
                            </MenuItem>
                          ))}
                      </Menu>
                    </>
                  )}
                </Stack>
              </Stack>
              {/* <Stack direction="row" marginTop="0 !important" spacing={getREMFromPX(theme.spacing * 2)}>
                <RoomIcon fontSize="small" />
                <Stack flexDirection="row">
                    <Typography
                        marginTop="0 !important"
                        color={theme[themePath].colors.textPrimary.primary}
                        fontSize={isMobile ? getREMFromPX(theme.spacing * 3) : getREMFromPX(theme.spacing * 4)}
                    >
                        {selectedAddress}
                    </Typography>
                </Stack>
              </Stack> */}
              {!selectedDeviceId && !isLoading && (
                <Warning message="No device is associated with your account." />
              )}
              {noHistoricalSubscriptions && (
                <NoServicesAlert
                  mainText="Select the provider that suits you best."
                  secondaryText="Search plans between the different available providers."
                >
                  <div>
                    <Button
                      onClick={goToInternetServices}
                      style={findAPlanButtonStyles}
                      text="Find a plan"
                      color="secondary"
                      size="large"
                    />
                  </div>
                </NoServicesAlert>
              )}
              <SubHeader
                icon="house-signal"
                headerText="Available Services"
                viewAll
                viewAllOnClick={goToServices}
              />
              <Stack
                mt={`${getREMFromPX(theme.spacing * 4)} !important`}
                flexDirection="row"
                flexWrap="wrap"
                gap={getREMFromPX(theme.spacing * 6)}
              >
                { services?.data.servicetype.map((service) =>
                  service.display ? (
                    <ServiceBox
                      image={service.image}
                      serviceId={service.id}
                      key={service.id}
                      title={service.name}
                      tooltipDesc={service.description}
                      disabled={service.Services?.filter(s => !!s.Provider)?.length <= 0}
                      description="Look for the best provider and subscribe to the plan that fits best to you."
                    />
                  ) : null
                )}
              </Stack>
              {areSubscriptions && (
                <>
                  <ServicesTableContainer>
                    <Stack spacing={getREMFromPX(theme.spacing * 8)}>
                      {subscriptions?.map((account) =>
                        account.Devices?.filter(
                          (device) => device.id === selectedDeviceId
                        ).map((device) => (
                          <ServicesTable
                            deviceId={device.id}
                            ports={device.Portinterfaces as Port[]}
                            key={device.id}
                            serialNumber={device.serial_number}
                            routerName={`${device.Physicallocation.street} ${device.Physicallocation.house_number}`}
                          />
                        ))
                      )}
                    </Stack>
                  </ServicesTableContainer>
                  <Stack
                    direction="row"
                    gap={getREMFromPX(theme.spacing * 8)}
                    flexWrap="wrap"
                  >
                    <ServicesInfoBox
                      headerIcon="house-signal"
                      items={subscriptionsItems}
                      header="Subscriptions"
                    />
                    {showBilling && (
                      <ServicesInfoBox
                        headerIcon="money-bill"
                        items={subscriptionsBillingItems}
                        header="Billing"
                      />
                    )}
                    {showReviews && userInfo?.data?.user?.Review?.map(r => {
                      return <ReviewBox
                        key={r.id}
                        headerIcon="star"
                        review={r}
                        header={`Review - ${r.Subscriptionhistory?.Provider?.name}`}
                      />
                    })}
                  </Stack>                  
                </>
              )}
            </Stack>
            <div style={spinnerContainerStyles}>
              <Backdrop open={isLoading}>
                <CircularProgress
                  data-testid="progressSpinner"
                  color="inherit"
                />
              </Backdrop>
            </div>
          </Stack>
        </>
      )}
    </Layout>
  );
};
export default Dashboard;

import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const getNavBarMenuStyles = (
  selected?: boolean,
  themePath = "default"
) => ({
  "&:hover": {
    color: selected
      ? theme[themePath].colors.primary[500]
      : theme[themePath].colors.textPrimary.primary,
  },
  border: "none",
  marginTop: `${getREMFromPX(theme.spacing * 2)} !important`,
  background: selected ? theme[themePath].colors.white : "none",
  borderRadius: "8px",
  padding: `${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
    theme.spacing * 4
  )} ${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(theme.spacing * 2)}`,
  gap: getREMFromPX(theme.spacing * 4),
  margin: 0,
  color: selected
    ? theme[themePath].colors.primary[500]
    : theme[themePath].colors.textPrimary.secondary,
  cursor: "pointer",
});

export const childrenContainerStyle = {
  marginTop: `${getREMFromPX(theme.spacing * 2)}`,
};

export const iconStyles = {
  width: getREMFromPX(theme.spacing * 4),
  height: getREMFromPX(theme.spacing * 4),
};
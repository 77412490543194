import { Switch, SwitchProps } from '@mui/material';
import useGetThemePath from '../../hooks/useGetThemePath';
import { getSwitchStyles } from './Styles';

const StyledSwitch = (props: SwitchProps) => {
  const themePath = useGetThemePath()
  return (
    <Switch sx={getSwitchStyles(themePath)} {...props} />
  );
  
}

export default StyledSwitch;

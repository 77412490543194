import useGetThemePath from "../../../../hooks/useGetThemePath";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { getHeaderIconStyle, getHeaderStyle } from "./Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import Switch from "../../../../components/Switch";
import sendErrorToast from "../../../../utils/sendErrorToast";
import useGetUserId from "../../../../hooks/useGetUserId";
import { useContext, useEffect, useState } from "react";
import { MessagingType } from "../../../../api/interfaces/User";
import { useUpdateUser } from "../../../../hooks/useUpdateUser";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";

const Notifications = () => {
  const themePath = useGetThemePath();
  const userId = useGetUserId();

  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser(
    userId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error saving NotificationSettings Questions, please try again"
        ),
    }
  );

  const [items, setItems] = useState<
    { category: string; email: boolean; portal: boolean; sms: boolean }[]
  >([]);

  const { userInfo, isLoading: isContextLoading } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  useEffect(() => {
    const messagingInfo = userInfo?.data?.user?.messaging ?? {};
    setItems(
      Object.keys(messagingInfo).map((key) => ({
        category: key,
        email: messagingInfo[key].includes("EMAIL"),
        portal: messagingInfo[key].includes("PORTAL"),
        sms: messagingInfo[key].includes("SMS"),
      }))
    );
  }, [userInfo]);

  const handleChange = (
    checked: boolean,
    messagingType: MessagingType,
    category: string
  ) => {
    const messaging = userInfo?.data?.user?.messaging ?? {};
    if (checked && !messaging[category].includes(messagingType)) {
      messaging[category].push(messagingType);
    } else if (!checked && messaging[category].includes(messagingType)) {
      messaging[category].splice(messaging[category].indexOf(messagingType), 1);
    } else {
      sendErrorToast("Sorry, there was some error with Notifications");
    }
    updateUser({ messaging });
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        padding={`0 0 ${getREMFromPX(theme.spacing * 3)} 0`}
      >
        <NotificationsNoneIcon sx={getHeaderIconStyle(themePath)} />
        <Typography sx={getHeaderStyle}>
          Change notifications settings
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        //width={getREMFromPX(theme.spacing * 140)}
        paddingTop={getREMFromPX(theme.spacing * 6)}
        key={`notification-header`}
      >
        {/* <div></div> */}
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          width={getREMFromPX(theme.spacing * 80)}
        >
          <Typography>E-mail</Typography>
          <Typography>SMS</Typography>
          <Typography>Portal</Typography>
        </Stack>
      </Stack>
      {items.map(({ category, email, sms, portal }, idx) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          //width={getREMFromPX(theme.spacing * 140)}
          paddingTop={getREMFromPX(theme.spacing * 6)}
          key={`notification-property-${idx}`}
        >
          <Typography>{`${
            category.charAt(0).toUpperCase() +
            category.slice(1).toLowerCase().replace(/_/g, " ")
          } notifications`}</Typography>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            width={getREMFromPX(theme.spacing * 80)}
          >
            <Switch
              key="email-switch"
              data-testid={`email-switch`}
              checked={email}
              onChange={(event) =>
                handleChange(event.target.checked, "EMAIL", category)
              }
            />
            <Switch
              key="sms-switch"
              data-testid={`sms-switch`}
              checked={sms}
              disabled={true}
            />
            <Switch
              key="portal-switch"
              data-testid={`portal-switch`}
              checked={portal}
              disabled={true}
            />
          </Stack>
        </Stack>
      ))}
      <Backdrop open={isContextLoading || isUpdatingUser}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </Stack>
  );
};

export default Notifications;

import React from "react";
import CardWrapper from "../../components/NewCardWrapper";
import { useGetOperatorFees } from "../../hooks/useGetOperatorFees";
import { useGetEstimatedFees } from "../../hooks/useGetEstimatedFees";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Box, Typography, Grid } from "@mui/material";
import formatNumber from "../../utils/formatNumber";
import PaymentMethod from "../../components/PaymentMethod";
import useViewport from "../../hooks/useViewport";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";
import { newRoutes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";

const TotalBill = ({ children }: { children?: React.ReactElement }) => {
  const colors = theme["new"].colors;

  const currentDate = new Date();
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth() + 1);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const navigate = useNavigate();

  const { currentAccount } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { paymentMethod } = React.useContext(BillingContext) as BillingType;

  const { isMobile } = useViewport();

  const { data: operatorData } = useGetOperatorFees(currentAccount?.id, {
    refetchOnWindowFocus: false,
  });

  const { data: estimateData } = useGetEstimatedFees(currentAccount?.id, {
    refetchOnWindowFocus: false,
  });

  const operatorFees = operatorData?.data?.billing?.subscription?.items?.reduce(
    (sum, item) => {
      return sum + item.price.amount;
    },
    0
  );

  const estimatedFees = estimateData?.data?.subscription?.reduce(
    (sum, item) => {
      return sum + parseFloat(item.Serviceplan.fields.Price);
    },
    0
  );

  const total = (operatorFees || 0) + (estimatedFees || 0);
  const arrowAction = (
    <ForwardArrowIcon
      onClick={() => navigate(newRoutes.billing.path)}
      style={{ cursor: "pointer" }}
      data-cy="total-bill"
    />
  );

  return (
    <Grid item xl={12}>
      <CardWrapper title="Total Bill" actions={arrowAction}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(48),
            fontWeight: "600",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: colors.teal,
          }}
        >
          {formatNumber(total, { prefix: "$", decimals: 2 })}
        </Typography>
        {!isMobile && (
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: getREMFromPX(10),
              fontWeight: "500",
              fontStyle: "italic",
              letterSpacing: 0,
              textAlign: "left",
              color: "#000000",
              marginLeft: "30px",
              marginTop: "-8px",
            }}
          >
            *This is an estimated total
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "17px",
            marginBottom: "20px",
          }}
        >
          <AutorenewIcon
            sx={{
              color: theme["new"].colors.teal,
              marginRight: isMobile ? "10px" : "11px",
            }}
          />
          <Typography
            sx={{
              color: theme["new"].colors.darkGreen,
              fontFamily: "Montserrat",
              fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
              fontWeight: 500,
            }}
          >
            Autopay on {formattedDate}
          </Typography>
        </Box>
        {!isMobile && <PaymentMethod {...paymentMethod} />}
      </CardWrapper>
    </Grid>
  );
};
export default TotalBill;

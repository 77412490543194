import _ from "lodash";
import {
  Box,
  Typography,
  Stack,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { Port } from "../../api/interfaces/Port";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import { Account } from "../../api/interfaces/Account";
import MyServicesRow from "./MyServicesRow";
import sendErrorToast from "../../utils/sendErrorToast";
import { useIsDeviceOn } from "../../hooks/useIsDeviceOn";
import RouterIcon from "@mui/icons-material/Router";
import useViewport from "../../hooks/useViewport";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext, useState } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import EmptyMyServicesMessage from "../../routes/NewServices/components/EmptyMyServicesMessage";
import { SubscriptionHeaderTable } from "../../routes/NewServices/components/MySubscriptionTableRow";

interface MyServicesProps {
  subscriptions: Array<Account>;
  switchDeviceOptions?: Array<any>;
}

const MyServices = ({ subscriptions }: MyServicesProps) => {
  const { setCurrentDevice, currentDevice, devices } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const optionsDevices = devices?.map((item: any, i: number) => ({
    label: `Device ${i + 1} - ${item?.Physicallocation.house_number} ${
      item?.Physicallocation.street
    }`,
    value: item?.id,
  })) as Array<{
    label: string;
    value: string;
  }>;

  const findDevice = (deviceId: string) =>
    devices.find((device) => device.id === deviceId);

  const listWithoutDuplicatedRecords = optionsDevices.filter(
    (item, index, arr) => {
      const firstIndex = arr.findIndex(
        (element) => element.value === item.value
      );
      return index === firstIndex;
    }
  );

  const { data: isDeviceOn } = useIsDeviceOn(currentDevice.id, {
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("There was an error getting the device information"),
  });

  const { isMobile } = useViewport();

  const [openDevicesList, setOpenDevicesList] = useState<boolean>(false);

  const currentSubscriptions = subscriptions?.filter(
    (sub: any, index: number) =>
      sub.Portinterface.device_id === currentDevice.id
  );

  return (
    <Stack
      spacing={getREMFromPX(theme.spacing * 8)}
      data-cy="myServicesContainer"
    >
      <>
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          display="flex"
          alignItems="center"
          marginBottom={!isMobile ? "34px" : ""}
        >
          <Box marginRight="7.7px">
            <RouterIcon />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => setOpenDevicesList(!openDevicesList)}
            sx={{
              cursor: "pointer",
              position: "relative",
            }}
          >
            <Typography
              fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
              fontWeight={500}
              color="#000"
              fontFamily="Montserrat"
              marginRight="5.4px"
            >
              {`${currentDevice?.Physicallocation.house_number} ${currentDevice?.Physicallocation.street}`}
            </Typography>

            {devices.length > 1 && (
              <>
                <ArrowDropDownIcon />
                {!!openDevicesList && (
                  <ClickAwayListener
                    onClickAway={() => setOpenDevicesList(false)}
                  >
                    <Paper
                      sx={{
                        position: "absolute",
                        zIndex: 10,
                        padding: "16px 8px",
                        width: isMobile ? "200px" : "237px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.25)",
                        top: "34px",
                        left: isMobile ? "20px" : "130px",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          fontSize={getREMFromPX(14)}
                          color={theme.new.colors.darkGreen}
                          fontFamily="Montserrat"
                          fontWeight={600}
                        >
                          Switch Device
                        </Typography>
                        {listWithoutDuplicatedRecords.map((val) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              marginTop: "20px",
                            }}
                            onClick={() => {
                              const newDevice = findDevice(val.value);
                              if (newDevice) {
                                setCurrentDevice(newDevice);
                              }
                            }}
                            key={val.value}
                          >
                            <RouterIcon />
                            <Typography
                              fontSize={getREMFromPX(14)}
                              color={theme.new.colors.darkGreen}
                              fontFamily="Montserrat"
                            >
                              {val.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Paper>
                  </ClickAwayListener>
                )}
              </>
            )}
          </Box>
          <Typography
            color={theme.new.colors.grey}
            fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
            marginRight="5.4px"
          >
            |
          </Typography>
          <Typography
            fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
            fontWeight={500}
            color={theme.new.colors.grey}
            fontFamily="Montserrat"
            marginRight="6px"
          >
            {`${currentDevice?.serial_number}`}
          </Typography>
          {isDeviceOn && (
            <Box
              sx={{
                width: "51px",
                height: "23px",
                borderRadius: "12.3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 16px",
                backgroundColor:
                  isDeviceOn.data.device === "ON" ? "#84e88e" : "#ff7a00",
              }}
            >
              <Typography
                fontFamily="Montserrat"
                fontSize={isMobile ? getREMFromPX(7.4) : getREMFromPX(12)}
                color={isDeviceOn.data.device === "ON" ? "#0b8718" : "#fff"}
              >
                {isDeviceOn.data.device === "ON" ? "On" : "Off"}
              </Typography>
            </Box>
          )}
        </Stack>
      </>
      {_.isEmpty(currentSubscriptions) ? (
        <>
          <SubscriptionHeaderTable />
          <EmptyMyServicesMessage />
        </>
      ) : (
        currentSubscriptions?.map((sub: any, index: number) => (
          <MyServicesRow
            deviceId={sub?.Portinterface?.device_id}
            port={{
              ...(sub.Portinterface as Port),
              providerId: sub?.Serviceplan?.Service?.provider_id,
              servicePlan: sub?.Serviceplan,
            }}
            unsubscribeId={sub.id}
            key={sub.id}
            currentIndex={index}
            dataCy={`my-services-row-${index}`}
          />
        ))
      )}
    </Stack>
  );
};

export default MyServices;

import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const stepperContainerStyle = (isMobile: any) => ({
  margin: `${getREMFromPX(theme.spacing * 12)} auto ${getREMFromPX(
    theme.spacing * 4
  )} auto`,
  padding: `0 ${getREMFromPX(theme.spacing * 2)}`,
  width: isMobile ? "auto" : getREMFromPX(theme.spacing * 140),
});


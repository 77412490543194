import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const dividerStyle = {
  height: getREMFromPX(theme.spacing),
};

export const getModalBoxStyles = (themePath = "default") =>
  ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme[themePath].colors.white,
  } as React.CSSProperties);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardService, {
  LogoContainer,
} from "../../routes/NewServices/components/CardService";
import { Box, Grid, styled } from "@mui/material";
import ListCardMobile from "../../routes/NewServices/components/ListCardMobile";
import useViewport from "../../hooks/useViewport";
import { Plan } from "../../routes/NewServices/utils/intefaces/Plan";
import { FieldIcon } from "../../routes/NewServices/utils/intefaces/FieldIcon";
import WorkflowSubscriptionModal from "../../routes/NewServices/components/SubscriptionModal/WorkflowSubscriptionModal";
import SubscribeModal from "../../routes/NewServices/components/SubscribeModal";
import TableServices from "./TableServices";
import { typesOptions } from "./constants";
import { SortFilterLabel } from "./interfaces";
import { applyExtraFilters } from "../../routes/NewServices/utils/extraFIlters";
import {
  Table,
  TableRow,
  TableHeader,
  TableCell,
  TableCellLeft,
} from "../NewPaymentHistory/styles";
import { renderStars } from "../../routes/NewServices/utils/getStars";
import NewButton from "../NewButton";
import { useLocation } from "react-router-dom";

export const onSubscribeService = (param: Partial<OverviewDataModel>) => {
  console.log(param);
};

interface AllServicesProps {
  plansList: Array<Plan>;
}

export interface OverviewDataModel {
  name: string;
  planName: string;
  providerName: string;
  service_id?: string;
  provider_id: string; //used for img
  fields: {
    Down: string;
    LinkSpeed: number;
    Name: string; // same than plan name
    Price: string;
    Up: string;
  };
  description: string;
  planId: string; // used to get reviews
  ratings_sum?: string;
  total_reviews?: string;
  email?: string;
  phone?: string;
  support_url?: string;
  subscription_redirect_url?: string;
  fields_icons?: Array<FieldIcon>;
}

const initial_state = {
  fields: {
    Down: "",
    LinkSpeed: 0,
    Name: "",
    Price: "",
    Up: "",
  },
  description: "",
  name: "",
  planName: "",
  provider_id: "",
  planId: "",
  ratings_sum: "",
  total_reviews: "",
  fields_icons: [],
  providerName: "",
};

const CardContainer = styled("div")`
  margin-top: 43.5px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
`;

const AllServices = ({ plansList }: AllServicesProps) => {
  const { isMobile } = useViewport();
  const [filteredPlansList, setFilteredPlansList] =
    useState<Array<Plan>>(plansList);

  const location = useLocation();
  let filterBy = location?.state as string;
  const [sortFilter, setSortFilter] = useState<SortFilterLabel | null>(null);
  const [options, setOptions] = useState(typesOptions);
  const [selectedTypeServiceFilter, setSelectedTypeServiceFilter] =
    useState<string>(filterBy ?? "All Services");

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [openWorkflowSubscriptionModal, setOpenWorkflowSubscriptionModal] =
    useState(false);
  const [modalOverviewData, setModalOverviewData] = useState<OverviewDataModel>(
    () => initial_state
  );

  const sortedAndFilteredPlans = useMemo(() => {
    return applyExtraFilters(
      filteredPlansList,
      selectedTypeServiceFilter,
      sortFilter
    );
  }, [sortFilter, selectedTypeServiceFilter, filteredPlansList]);

  const handleChangeTypeOfView = (idx: number) => {
    const changedStatusList = options.map((val, i) => ({
      ...val,
      active: i === idx,
    }));

    setOptions(changedStatusList);
  };

  const handleInputChange = useCallback(
    (val: string | null = null) => {
      let filteredPlans: Plan[] = plansList;
      if (val) {
        filteredPlans = plansList.filter(
          (v: Plan) =>
            v.planName.toLowerCase().includes(val.toLowerCase()) ||
            v.name.toLowerCase().includes(val.toLowerCase()) ||
            (v.providerName &&
              v.providerName.toLowerCase().includes(val.toLowerCase())) ||
            (v.fields &&
              v.fields.Price &&
              v.fields.Price.toLowerCase().includes(val.toLowerCase()))
        );
      }
      setFilteredPlansList(filteredPlans);
    },
    [plansList]
  );

  useEffect(() => {
    handleInputChange();
  }, [handleInputChange]);

  const calcStarsAverage = (ratings_sum: string, total_reviews: string) => {
    const reviewsAverage =
      ratings_sum && Number(total_reviews) !== 0
        ? (Number(ratings_sum) / Number(total_reviews)).toString()
        : "0";
    return renderStars(reviewsAverage);
  };

  return (
    <TableServices
      options={options}
      handleChangeTypeOfView={handleChangeTypeOfView}
      setSelectedTypeServiceFilter={setSelectedTypeServiceFilter}
      setSortFilter={setSortFilter}
      sortFilter={sortFilter}
      handleInputChange={handleInputChange}
      isMyTabsActive={true}
      activeServiceFilter={filterBy}
    >
      <>
        {!isMobile ? (
          <>
            {options[0]?.active ? (
              <CardContainer data-cy="allServicesContainer">
                {sortedAndFilteredPlans?.map((plan, idx) => (
                  <CardService
                    key={`${plan.id}`}
                    setOpenSubscribeModal={() => setOpenSubscribeModal(true)}
                    plan={plan}
                    setModalOverviewData={setModalOverviewData}
                    setOpenWorkflowSubscriptionModal={() =>
                      setOpenWorkflowSubscriptionModal(true)
                    }
                    dataCy={`card-service-${idx}`}
                  />
                ))}
              </CardContainer>
            ) : (
              <Box
                sx={{
                  marginTop: "38px",
                  marginLeft: "32px",
                  marginRight: "12px",
                  overflow: "scroll",
                  overflowY: "hidden",
                }}
              >
                <Table>
                  <thead>
                    <TableRow>
                      <TableHeader style={{ textAlign: "left" }}>
                        Provider
                      </TableHeader>
                      <TableHeader style={{ textAlign: "left" }}>
                        Name
                      </TableHeader>
                      <TableHeader style={{ textAlign: "left" }}>
                        Type
                      </TableHeader>
                      {selectedTypeServiceFilter === "Internet Services" &&
                        window.innerWidth >= 1140 && (
                          <>
                            <TableHeader style={{ textAlign: "left" }}>
                              Down
                            </TableHeader>
                            <TableHeader style={{ textAlign: "left" }}>
                              Up
                            </TableHeader>
                          </>
                        )}
                      <TableHeader style={{ textAlign: "left" }}>
                        Price
                      </TableHeader>
                      <TableHeader style={{ textAlign: "left" }}>
                        Average Rating
                      </TableHeader>
                      <TableHeader></TableHeader>
                    </TableRow>
                  </thead>
                  <tbody>
                    {sortedAndFilteredPlans.map((plan, index) => {
                      const stars = calcStarsAverage(
                        plan.ratings_sum,
                        plan.total_reviews
                      );
                      const cyRow = `service-table-row-${index}`;
                      return (
                        <TableRow
                          key={index}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setModalOverviewData({
                              fields: {
                                Down: plan.fields?.Down as string,
                                LinkSpeed: plan.fields?.LinkSpeed as number,
                                Name: plan.fields?.Name as string,
                                Price: plan.fields?.Price as string,
                                Up: plan.fields?.Up as string,
                              },
                              description: plan.description as string,
                              providerName: plan.providerName as string,
                              name: plan.name as string,
                              planName: plan.planName as string,
                              service_id: plan.service_id,
                              provider_id: plan.providerId as string,
                              planId: plan.id as string,
                              ratings_sum: plan.ratings_sum as string,
                              total_reviews: plan.total_reviews as string,
                              email: plan.Contacts[0]?.email,
                              phone: plan.Contacts[0]?.phone,
                              support_url: plan.support_url,
                              subscription_redirect_url:
                                plan.subscription_redirect_url,
                              fields_icons:
                                plan.fields_icons as Array<FieldIcon>,
                            });
                            setOpenSubscribeModal(true);
                          }}
                          data-cy={cyRow}
                        >
                          <TableCell
                            style={{ textAlign: "left", padding: 0 }}
                            data-cy={`${cyRow}-provider`}
                          >
                            <LogoContainer width="140" height="63">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
                                alt=""
                              />
                            </LogoContainer>
                          </TableCell>
                          <TableCellLeft data-cy={`${cyRow}-name`}>
                            {plan.planName}
                          </TableCellLeft>
                          <TableCellLeft data-cy={`${cyRow}-type`}>
                            {plan.name}
                          </TableCellLeft>
                          {selectedTypeServiceFilter === "Internet Services" &&
                            window.innerWidth >= 1140 && (
                              <>
                                <TableCellLeft data-cy={`${cyRow}-down`}>
                                  {plan.fields?.Down}
                                </TableCellLeft>
                                <TableCellLeft data-cy={`${cyRow}-up`}>
                                  {plan.fields?.Up}
                                </TableCellLeft>
                              </>
                            )}
                          <TableCellLeft data-cy={`${cyRow}-price`}>
                            ${plan.fields?.Price}
                          </TableCellLeft>
                          <TableCellLeft data-cy={`${cyRow}-rating`}>
                            {stars}
                          </TableCellLeft>
                          <TableCellLeft>
                            <NewButton
                              mode="default"
                              text="Subscribe"
                              onClick={(e) => {
                                e.stopPropagation();
                                setModalOverviewData({
                                  fields: {
                                    Down: plan.fields?.Down as string,
                                    LinkSpeed: plan.fields?.LinkSpeed as number,
                                    Name: plan.fields?.Name as string,
                                    Price: plan.fields?.Price as string,
                                    Up: plan.fields?.Up as string,
                                  },
                                  description: plan.description as string,
                                  providerName: plan.providerName as string,
                                  name: plan.name as string,
                                  planName: plan.planName as string,
                                  service_id: plan.service_id,
                                  provider_id: plan.providerId as string,
                                  planId: plan.id as string,
                                  ratings_sum: plan.ratings_sum as string,
                                  total_reviews: plan.total_reviews as string,
                                  email: plan.Contacts[0]?.email,
                                  phone: plan.Contacts[0]?.phone,
                                  support_url:
                                    plan.support_url ||
                                    plan.Contacts[0]?.support_url,
                                  subscription_redirect_url:
                                    plan.subscription_redirect_url,
                                  fields_icons:
                                    plan.fields_icons as Array<FieldIcon>,
                                });
                                setOpenWorkflowSubscriptionModal(true);
                              }}
                              sx={{
                                width: "120px",
                                height: "40px",
                                fontSize: "12px",
                                borderRadius: "30px",
                              }}
                              dataCy={`${cyRow}-subscribe`}
                            />
                          </TableCellLeft>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </Table>
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              overflow: "auto",
            }}
            data-cy="allServicesContainerBox"
          >
            {sortedAndFilteredPlans?.map((plan) => (
              <Grid item xs={12} key={plan.id}>
                <ListCardMobile
                  setOpenSubscribeModal={() => setOpenSubscribeModal(true)}
                  setOpenWorkflowSubscriptionModal={() =>
                    setOpenWorkflowSubscriptionModal(true)
                  }
                  plan={plan}
                  setModalOverviewData={setModalOverviewData}
                />
              </Grid>
            ))}
          </Box>
        )}
        <SubscribeModal
          open={openSubscribeModal}
          handleClose={() => setOpenSubscribeModal(false)}
          modalOverviewData={modalOverviewData}
          setOpenWorkflowSubscriptionModal={setOpenWorkflowSubscriptionModal}
        />
        <WorkflowSubscriptionModal
          open={openWorkflowSubscriptionModal}
          modalOverviewData={modalOverviewData}
          handleClose={() => {
            setModalOverviewData(initial_state);
            setOpenWorkflowSubscriptionModal(false);
            setOpenSubscribeModal(false);
          }}
        />
      </>
    </TableServices>
  );
};

export default AllServices;

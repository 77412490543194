import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const alertStyles = {
  padding: getREMFromPX(theme.spacing * 4),
};

export const buttonStyles = {
  marginTop: getREMFromPX(theme.spacing * 4),
};

export const getHelpIconStyles = (themePath = "default") => ({
  color: theme[themePath].colors.textPrimary.secondary,
  height: getREMFromPX(theme.spacing * 4),
  width: getREMFromPX(theme.spacing * 4),
  marginRight: getREMFromPX(theme.spacing * 2),
});

export const textContainerStyles = {
  flex: 3,
};

export const getTextHelpIconStyles = (themePath = "default") => ({
  flex: 2,
  color: theme[themePath].colors.secondary[500],
  opacity: "30%",
});

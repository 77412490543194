import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Stack, Typography, InputAdornment, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type, Step3Type } from "../../ForgotPassword";
import {
  headerStyle,
  dividerStyle,
  iconStyle,
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad,
} from "./Styles";
import { useResetPassword } from "../../../../hooks/useResetPassword";
import sendErrorToast from "../../../../utils/sendErrorToast";
import isValidPassword from "../../../../utils/isValidPassword";
import ErrorMessage from "../../../../components/ErrorMessage";

interface Step3Props {
  changeStep: () => void;
  goBackOneStep: () => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
  };
}

const Step3 = ({ changeStep, goBackOneStep, previousValues }: Step3Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<Step3Type>({
    defaultValues: {
      newPassword: previousValues.step3.newPassword,
      confirmPassword: previousValues.step3.confirmPassword,
    },
    mode: "onChange",
  });

  const { mutate: resetPassword, isLoading: isResetting } = useResetPassword({
    onSuccess: (data) => {
      if (data !== "invalid info") {
        return changeStep();
      }
      sendErrorToast("Please review the submitted info");
    },
    onError: () =>
      sendErrorToast(
        "There was an error resetting the password, please try again"
      ),
  });

  const newPassword = watch("newPassword").trim();
  const confirmPassword = watch("confirmPassword").trim();

  const disableSubmitButton = !isValid || isResetting;

  const passwordsMatch = !confirmPassword || confirmPassword === newPassword;

  const onSubmit = () => {
    const body = {
      username: previousValues.step1.userName,
      password: newPassword,
      security_question1: previousValues.step2.questions[0].id,
      security_answer1: previousValues.step2.questions[0].answer,
      security_question2: previousValues.step2.questions[1].id,
      security_answer2: previousValues.step2.questions[1].answer,
    };
    resetPassword(body);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          Reset Password
        </Typography>
      </Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        direction="column"
        mt={getREMFromPX(theme.spacing * 8)}
      >
        <Label htmlFor="newPassword">New Password</Label>
        <Input
          id="newPassword"
          data-testid="newPassword"
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Enter your new password"
          error={(!!errors.newPassword && !!newPassword.length) || !passwordsMatch}
          softError={!!errors.newPassword || !newPassword.length || !passwordsMatch}
          style={inputAdornmentStyle}
          endAdornment={
            <InputAdornment position="end" style={iconStyleAdornment}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                style={iconStylePad}
              >
                {showPassword 
                  ? <FontAwesomeIcon icon="eye-slash" style={iconStyle} /> 
                  : <FontAwesomeIcon icon="eye" style={iconStyle} />
                }
              </IconButton>
            </InputAdornment>
          }
          {...register("newPassword", {
            required: true,
            validate: (value) => isValidPassword(value),
          })}
        />
      </Stack>
      <Typography
        margin={`${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
          theme.spacing * 1
        )} 0 ${getREMFromPX(theme.spacing * 1)}`}
        fontSize={getREMFromPX(theme.spacing * 2.5)}
      >
        Password must contain at least 8 characters and less than 16, at least 1
        uppercase and 1 lowercase letter, 1 number and 1 special character.
      </Typography>
      {errors.newPassword && newPassword && (
        <ErrorMessage
          fontSize={getREMFromPX(theme.spacing * 2.5)}
          margin={`0 ${getREMFromPX(theme.spacing * 1)}`}
        >
          Invalid Password. Please enter a new one.
        </ErrorMessage>
      )}
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        direction="column"
        mt={getREMFromPX(theme.spacing * 8)}
      >
        <Label htmlFor="confirmPassword">Confirm Password</Label>
        <Input
          id="confirmPassword"
          data-testid="confirmPassword"
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder="Confirm your new password"
          error={(!!errors.confirmPassword && !!confirmPassword.length) || !passwordsMatch}
          softError={!!errors.confirmPassword || !confirmPassword.length || !passwordsMatch}
          {...register("confirmPassword", {
            required: true,
            validate: (value) => value === newPassword,
          })}
        />
      </Stack>
      <Divider sx={dividerStyle} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          startIcon={<FontAwesomeIcon icon="angle-left" style={iconStyle} />}
          onClick={goBackOneStep}
          variant="text"
          text="Back"
          size="medium"
        />
        <Button
          text="Finish"
          data-testid="submit_button_3"
          type="submit"
          size="medium"
          disabled={disableSubmitButton}
        />
      </Stack>
    </form>
  );
};
export default Step3;

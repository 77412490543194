import React from "react";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const dividerStyle = {
  margin: `0 ${getREMFromPX(theme.spacing * 4)}`,
};

export const imgStyle = {
  objectFit: "cover",
  width: "100%",
} as React.CSSProperties;

export const iconStyle = {
  paddingBottom: `${getREMFromPX(theme.spacing * 2)}`,
};

export const getButtonStyle = (isUnsubscribe?: boolean) => ({
  marginLeft: isUnsubscribe ? 0 : "auto",
});

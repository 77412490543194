import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const getChipStyles = (open: boolean, themePath = "default") => ({
  background: open
    ? theme[themePath].colors.primary[50]
    : theme[themePath].colors.white,
  fontSize: getREMFromPX(theme.spacing * 4),
});

export const getChipArrowStyles = (themePath = "default") => ({
  fill: theme[themePath].colors.textPrimary.secondary,
});

export const getAddressArrowStyles = (themePath = "default") => ({
  fill: theme[themePath].colors.textPrimary.primary,
  alignSelf: "flex-end",
});

export const buttonStyles = {
  border: 0,
  background: "none",
};

import { MouseEventHandler, ReactNode, useContext, useState } from "react";
import {
  AppBar,
  Box,
  Hidden,
  Stack,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme";
import { NavBar } from "./NavBar";
import { newRoutes as routes } from "../../App";
import { SwitchAccountsIcon, UserProfileIcon } from "../../assets/icons";
import {
  WaffleIcon,
  BillingIcon,
  ServicesIcon,
  HelpIcon,
} from "../../assets/icons";
import useMenuAnchor from "../../hooks/useMenuAnchor";
import MenuItem from "../../components/MenuItem";
import Menu from "../../components/Menu";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import { useGetConfig } from "../../hooks/useGetConfig";
import EntryPointLink from "../NewEntryPointLink/EntryPointLink";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";

export interface MenuItemsFields {
  title: string;
  icon: any;
  route: any;
}

const menuListProps = {
  "aria-labelledby": "profile_options_button",
};

const ParentContainer = styled("div")`
  background-color: #7ab8b0; /* This is your fallback color */
  position: relative;
  min-height: 100vh;
`;

const LeftMenuGradient = styled(Stack)`
  position: relative;
  height: 100%;
  background: linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%);
  display: flex;
  flex-direction: row;
`;

interface NavContainerProps<T> {
  title: string;
  headerContent?: ReactNode;
  children?: ReactNode;
  options?: Array<T>;
  eventHandler?: (val?: any) => void;
}

const NavContainer = <T,>({
  title,
  children,
  options = [],
  eventHandler = () => {},
  headerContent,
}: NavContainerProps<T>) => {
  const { userInfo, setCurrentAccount, currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { logOut } = useContext(AuthContext) as AuthenticationContext;
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const { isMobile } = useViewport();

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const menuItems: Array<MenuItemsFields> = [
    {
      title: "Dashboard",
      icon: <WaffleIcon />,
      route: routes.dashboard,
    },
    ...(showBilling
      ? [
          {
            title: "Billing",
            icon: <BillingIcon />,
            route: routes.billing,
          },
        ]
      : []),
    {
      title: "Services",
      icon: <ServicesIcon />,
      route: routes.services,
    },
    {
      title: "Help",
      icon: <HelpIcon />,
      route: routes.help,
    },
  ];

  const menuOptions = [
    {
      label: "Account Settings",
      onClick: () => navigate(routes.profile.path),
    },
    {
      label: "Log out",
      onClick: () => logOut(),
    },
  ];
  const colors = theme["new"].colors;
  const navigate = useNavigate();

  const {
    anchorEl: profileMenuAnchorEl,
    open: profileMenuOpen,
    handleClick: handleProfileMenuClick,
    handleClose: handleProfileMenuClose,
  } = useMenuAnchor();

  const handleAccountMenuClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAccountMenuOpen(true);
    handleProfileMenuClick(event);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
    handleProfileMenuClose();
  };

  const drawer = (
    <ParentContainer>
      <LeftMenuGradient>
        <NavBar title={title} menuItems={menuItems} />
        <Stack
          flexDirection="row"
          bgcolor={colors.lightestGreen}
          padding={"1.5rem"}
          flex={1}
          style={{ borderTopLeftRadius: 50 }}
        ></Stack>
      </LeftMenuGradient>
    </ParentContainer>
  );

  const accounts = userInfo?.data?.user?.Accounts;

  const accountChoices = accounts?.map((account) => {
    if (account) {
      return (
        <MenuItem
          key={account.id}
          onClick={() => {
            handleAccountMenuClose();
            setCurrentAccount(account);
          }}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {account.Devices && account.Devices.length > 0 && (
            <span style={{ textAlign: "right", width: "100%" }}>
              {account.Devices[0].Physicallocation.street}
            </span>
          )}
          {(!account.Devices || account.Devices.length === 0) && (
            <span style={{ textAlign: "right", width: "100%" }}>
              {account.name}
            </span>
          )}
        </MenuItem>
      );
    }
    return null;
  });

  return (
    <Box
      sx={{
        display: "flex",
        elevation: 0,
        width: {
          sm: "102%",
          md: `calc(100% - 9.5rem)`,
        },
        maxWidth: "100rem",
      }}
    >
      <Hidden mdDown>{drawer}</Hidden>

      <AppBar
        position="static"
        elevation={0}
        sx={{
          padding: 0,
          margin: 0,
          paddingTop: isMobile ? "1.5rem" : "3.125rem",
          bgcolor: colors.lightestGreen,
        }}
      >
        <Toolbar sx={{ alignItems: "center" }}>
          <Stack sx={{ width: "100%" }}>
            {!!headerContent ? (
              headerContent
            ) : (
              <Stack flexDirection="row">
                <Typography
                  fontFamily="Montserrat"
                  fontSize={isMobile ? "1.5rem" : "2.5rem"}
                  fontWeight="500"
                  fontStyle="normal"
                  color={colors.darkGreenFontColor}
                  sx={{
                    verticalAlign: "center",
                    padding: 0,
                  }}
                >
                  {title}
                </Typography>
                {options?.length > 0 && !isMobile && (
                  <Box
                    display="flex"
                    alignItems="center"
                    ml="56px"
                    mt="8px"
                    width="100%"
                  >
                    {options.map((opt: any, idx) => (
                      <Typography
                        key={idx}
                        fontSize={getREMFromPX(17)}
                        fontWeight={opt.isActive ? "bold" : 600}
                        fontFamily="Montserrat"
                        onClick={() => eventHandler(idx)}
                        color={
                          opt.isActive
                            ? theme.new.colors.darkGreenFontColor
                            : "#c5c5c5"
                        }
                        mr={idx === 0 ? "25px" : ""}
                        sx={{
                          "&:hover": {
                            color: theme.new.colors.formGrey,
                          },
                          cursor: "pointer",
                        }}
                      >
                        {opt?.label}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
          <Box
            sx={{
              textAlign: "right",
              marginRight: 4,
            }}
          >
            {isMobile ? (
              <MenuIcon
                style={{
                  color: "black",
                  borderRadius: "30%",
                  cursor: "pointer",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
                onClick={handleProfileMenuClick}
              />
            ) : (
              <div
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: "black",
                    margin: "auto",
                    paddingRight: "1rem",
                  }}
                >
                  <div onClick={handleProfileMenuClick}>
                    {currentAccount?.name}
                  </div>
                  {accounts && accounts.length > 0 && (
                    <div
                      style={{ color: "#aaa", fontSize: "0.875rem" }}
                      onClick={handleAccountMenuClick}
                    >
                      Switch Accounts
                    </div>
                  )}
                </span>
                <UserProfileIcon
                  onClick={handleProfileMenuClick}
                  style={{
                    borderRadius: "30%",
                    cursor: "pointer",
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                  data-cy="navcontainer-user-icon"
                />
              </div>
            )}
            <Hidden mdUp>
              <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorEl}
                open={profileMenuOpen && !accountMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={menuListProps}
              >
                {menuItems.map(({ title, route }) => (
                  <MenuItem
                    key={title}
                    onClick={() =>
                      route.path ? navigate(route.path) : route()
                    }
                  >
                    {title}
                  </MenuItem>
                ))}

                {menuOptions.map(({ label, onClick }) => (
                  <MenuItem
                    key={label}
                    onClick={() => {
                      handleProfileMenuClose();
                      onClick?.();
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
                {accounts?.length && accounts.length > 1 && (
                  <Stack
                    sx={{
                      padding: `${getREMFromPX(5)} ${getREMFromPX(15)}`,
                    }}
                  >
                    <Typography>Switch Accounts</Typography>
                    {accountChoices}
                  </Stack>
                )}
              </Menu>
            </Hidden>

            <Hidden mdDown>
              <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorEl}
                open={profileMenuOpen && !accountMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={menuListProps}
              >
                {menuOptions.map(({ label, onClick }) => (
                  <MenuItem
                    key={label}
                    onClick={() => {
                      handleProfileMenuClose();
                      onClick?.();
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
                {accounts?.length && accounts.length > 1 && (
                  <Stack
                    sx={{
                      padding: `${getREMFromPX(5)} ${getREMFromPX(15)}`,
                    }}
                  >
                    <Typography>Switch Accounts</Typography>
                    {accountChoices}
                  </Stack>
                )}
              </Menu>
            </Hidden>

            <Menu
              id="profile-menu"
              anchorEl={profileMenuAnchorEl}
              open={profileMenuOpen && accountMenuOpen}
              onClose={handleAccountMenuClose}
              MenuListProps={menuListProps}
            >
              {accounts?.length && accounts.length > 1 && (
                <Stack
                  sx={{
                    padding: `${getREMFromPX(5)} ${getREMFromPX(15)}`,
                  }}
                >
                  <Typography
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      gap: "18px",
                    }}
                  >
                    <SwitchAccountsIcon />
                    Switch Accounts
                  </Typography>
                  {accountChoices}
                </Stack>
              )}
            </Menu>
          </Box>
        </Toolbar>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            bgcolor: colors.lightestGreen,
            color: "black",
            paddingBottom: "4.5rem",
            marginRight: "1rem",
          }}
        >
          {children}
          <Box sx={{ marginTop: "2.25rem" }}>
            <EntryPointLink />
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
};

export default NavContainer;

import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Box from "../Box";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { TableFilters } from "./TableFilters";
import useViewport from "../../hooks/useViewport";
import FiltersMobile from "./FiltersMobile";
import { TypesOptionsFields } from "../../routes/NewServices/utils/intefaces/TypesOptionsFIelds";
import { SortFilterLabel } from "./interfaces";
import { useGetServices } from "../../hooks/useGetServices";
import sendErrorToast from "../../utils/sendErrorToast";

interface TableServicesProps {
  options: Array<TypesOptionsFields>;
  handleChangeTypeOfView: (v: number) => void;
  children: React.ReactElement;
  setSelectedTypeServiceFilter: (val: string) => void;
  setSortFilter: (v: SortFilterLabel | null) => void;
  sortFilter: SortFilterLabel | null;
  handleInputChange?: (val: string) => void;
  isMyTabsActive: boolean;
  activeServiceFilter?: string;
}

const TableServices = ({
  children,
  options,
  handleChangeTypeOfView,
  setSelectedTypeServiceFilter,
  setSortFilter,
  sortFilter,
  handleInputChange,
  isMyTabsActive,
  activeServiceFilter = "All Services",
}: TableServicesProps) => {
  const { data: services } = useGetServices({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "Sorry, there was a problem retrieving the available services"
      ),
  });

  const mapped =
    services?.data?.servicetype?.map((svc) => {
      return {
        label: svc.name,
        isActive: activeServiceFilter === svc.name,
        display: svc.display ?? false,
      };
    }) ?? [];

  const optionsList = [
    {
      label: "All Services",
      isActive: activeServiceFilter === "All Services" || !activeServiceFilter,
      display: true,
    },
    ...mapped,
  ];

  const filterOptions = optionsList.filter((option) => option.display === true);

  const [tabOptions, setTabOptions] = useState(() => filterOptions);
  const { isMobile } = useViewport();

  const handleChangeActiveTab = (idx: number) => {
    const changedTab = filterOptions.map((v, i) => {
      return {
        ...v,
        isActive: i === idx,
      };
    });

    setSelectedTypeServiceFilter(
      changedTab.find((b) => b?.isActive === true)?.label as string
    );
    setTabOptions(changedTab);
  };

  return (
    <Box hasBoxShadow sx={{ padding: 0 }}>
      {!isMobile && isMyTabsActive && (
        <Stack
          flexWrap="wrap"
          marginBottom={getREMFromPX(theme.spacing * 8)}
          flexDirection="row"
          alignItems="center"
          gap={getREMFromPX(39)}
        >
          {tabOptions.map(({ label, isActive }, idx) => (
            <Typography
              fontSize={getREMFromPX(14)}
              fontWeight={theme.fonts.weights.mediumBold}
              fontFamily="Montserrat"
              key={`${label}+${idx}`}
              color={
                !isActive
                  ? theme.new.colors.mediumGrey
                  : theme.new.colors.darkGreenFontColor
              }
              sx={{
                cursor: "pointer",
                textDecoration: isActive ? "underline" : "",
                "&:hover": {
                  color: theme.new.colors.formGrey,
                },
              }}
              onClick={() => handleChangeActiveTab(idx)}
            >
              {label}
            </Typography>
          ))}
        </Stack>
      )}
      {!isMobile && isMyTabsActive ? (
        <TableFilters
          options={options}
          handleChangeTypeOfView={handleChangeTypeOfView}
          setSortFilter={setSortFilter}
          sortFilter={sortFilter}
          handleInputChange={handleInputChange}
        />
      ) : (
        <FiltersMobile
          options={tabOptions}
          handleChangeActiveTab={handleChangeActiveTab}
          setSortFilter={setSortFilter}
          sortFilter={sortFilter}
          handleInputChange={handleInputChange}
          isMyTabsActive={isMyTabsActive}
        />
      )}
      {children}
    </Box>
  );
};

export default TableServices;

import { Stack, Typography } from "@mui/material";
import Alert from "../Alert";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import {
  alertStyles,
  getHelpIconStyles,
  textContainerStyles,
  getTextHelpIconStyles,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoServicesAlert = ({
  mainText,
  secondaryText,
  children,
}: {
  mainText?: string;
  secondaryText?: string;
  children?: React.ReactElement;
}) => {
  const themePath = useGetThemePath();
  return (
    <Alert style={alertStyles}>
      <>
        <Stack
          flexDirection="row"
          alignItems="center"
          marginBottom={getREMFromPX(theme.spacing * 4)}
        >
          <ContactSupportOutlinedIcon style={getHelpIconStyles(themePath)} />
          <Typography
            fontWeight={theme.fonts.weights.mediumBold}
            color={theme[themePath].colors.textPrimary.primary}
            fontSize={getREMFromPX(theme.spacing * 4.5)}
          >
            Not sure where to start?
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <div style={textContainerStyles}>
            <Typography
              color={theme[themePath].colors.secondary[500]}
              fontSize={getREMFromPX(theme.spacing * 6)}
              fontWeight={theme.fonts.weights.bolder}
            >
              {mainText || "Choose the internet plan that suits you best"}
            </Typography>
            <Typography
              color={theme[themePath].colors.textPrimary.secondary}
              fontSize={getREMFromPX(theme.spacing * 4.5)}
              fontWeight={theme.fonts.weights.mediumBold}
            >
              {secondaryText ||
                "Search and subscribe for the ISP with the best value"}
            </Typography>
            {children}
          </div>
          <FontAwesomeIcon
            style={getTextHelpIconStyles(themePath)}
            icon="wifi"
            fontSize={getREMFromPX(theme.spacing * 22)}
          />
        </Stack>
      </>
    </Alert>
  );
};

export default NoServicesAlert;

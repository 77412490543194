import { theme } from '../../../../../theme';
import getREMFromPX from '../../../../../utils/getREMFromPX';

export const getHeaderIconStyle = (themePath = "default") => ({ 
  fontSize: getREMFromPX(theme.spacing * 4), 
  color: theme[themePath].colors.textPrimary.secondary
})

export const getHeaderStyle = {
  fontWeight: theme.fonts.weights.mediumBold,
  marginLeft: getREMFromPX(theme.spacing * 2),
  fontSize: getREMFromPX(theme.spacing * 4.5)
} 

export const getTotalStyle = {
  fontWeight: theme.fonts.weights.bolder,
  marginLeft: getREMFromPX(theme.spacing * 2),
  fontSize: getREMFromPX(theme.spacing * 8)
} 

export const notificationContainerStyle = (isMobile: any) => ({
  width: isMobile ? "auto" : "60%",
});

export const iconStyle = (themePath = "default") => ({ 
  padding: getREMFromPX(theme.spacing * 2),
  color: theme[themePath].colors.secondary[500]
});

export const getSubHeaderStyle = {
  fontWeight: theme.fonts.weights.bolder,
  fontSize: getREMFromPX(theme.spacing * 4)
} 

export const getSubHeaderPayStyle = {
  fontWeight: theme.fonts.weights.bolder,
  marginLeft: getREMFromPX(theme.spacing * 16),
  fontSize: getREMFromPX(theme.spacing * 4)
}
export const dividerStyle = {
  width: "100%",
};
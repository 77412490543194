import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const boxStyles = {
  backgroundColor: theme.backgrounds.secondarySelection,
};

export const subHeaderContainer = {
  marginBottom: getREMFromPX(theme.spacing * 4),
};

export const iconStyles = {
  marginRight: getREMFromPX(theme.spacing * 2),
};

export const getSelectedItemStyles = (themePath = "default") => ({
  backgroundColor: theme[themePath].colors.secondary[500],
});

export const getEditStyle = (themePath = "default") => ({
  "&:hover": {
    color: theme[themePath].colors.primary[700],
  },
  color: theme[themePath].colors.primary[500],
  fontSize: getREMFromPX(theme.spacing * 3.5),
  border: "none",
  background: "none",
  gap: getREMFromPX(theme.spacing * 2),
  cursor: "pointer",
})
import { Stack, Typography } from "@mui/material";
import React from "react";
import Box from "../../../../components/Box";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { boxStyles } from "./styles";

const ServicesTableContainer = ({
  children,
}: {
  children?: React.ReactElement;
}) => (
  <Box hasBoxShadow style={boxStyles}>
    <Stack
      flexWrap="wrap"
      marginBottom={getREMFromPX(theme.spacing * 8)}
      flexDirection="row"
      alignItems="center"
      gap={getREMFromPX(theme.spacing * 2)}
    >
      <Typography
        fontSize={getREMFromPX(theme.spacing * 4.5)}
        fontWeight={theme.fonts.weights.mediumBold}
      >
        Subscribed Services
      </Typography>
    </Stack>
    {children}
  </Box>
);
export default ServicesTableContainer;

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { endpoints } from "../api/constants/endpoints";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../components/AddressAndAccountProvider";
import { MappedProviderWithPlan } from "../routes/SubscribeToISP/SubscribeToISP";
import createFormData from "../utils/createFormData";
import fetcher from "../utils/fetchWrapper";
import sendErrorToast from "../utils/sendErrorToast";
import useGetUserId from "./useGetUserId";

export type SubscriptionBody = {
  port_interface_id: string;
  serviceplan_id: string;
};

const subscriptionId = uuid();

const useCreateSubscription = (
  isDoneFunction: () => void,
  mappedProvider: MappedProviderWithPlan
) => {
  const userId = useGetUserId();
  const { selectedAccountId, userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [loading, setLoading] = useState(false);
  const [isFetchingWebsite, setIsFetchingWebsite] = useState(false);
  const isFetchingWebsiteRef = useRef(isFetchingWebsite);

  const subscriptionBody = useMemo(
    () =>
      createFormData({
        service_id: mappedProvider?.plan.service_id,
        subscription_id: subscriptionId,
        service: {
          ...mappedProvider?.plan?.fields,
        },
        contacts: [
          {
            location: {
              address1: userInfo?.data?.user?.Contact?.Location?.address1,
              address2: userInfo?.data?.user?.Contact?.Location?.address2,
              city: userInfo?.data?.user?.Contact?.Location?.city,
              state: userInfo?.data?.user?.Contact?.Location?.state,
              zip: userInfo?.data?.user?.Contact?.Location?.zip,
              country: "US",
            },
            email: userInfo?.data?.user?.Contact?.email,
            first_name: userInfo?.data?.user?.Contact?.first_name,
            last_name: userInfo?.data?.user?.Contact?.last_name,
            phone: userInfo?.data?.user?.Contact?.phone,
            type: userInfo?.data?.user?.Contact?.type,
          },
        ],
      }),
    [
      mappedProvider?.plan.fields,
      mappedProvider?.plan.service_id,
      userInfo?.data?.user?.Contact?.Location?.address1,
      userInfo?.data?.user?.Contact?.Location?.address2,
      userInfo?.data?.user?.Contact?.Location?.city,
      userInfo?.data?.user?.Contact?.Location?.state,
      userInfo?.data?.user?.Contact?.Location?.zip,
      userInfo?.data?.user?.Contact?.email,
      userInfo?.data?.user?.Contact?.first_name,
      userInfo?.data?.user?.Contact?.last_name,
      userInfo?.data?.user?.Contact?.phone,
      userInfo?.data?.user?.Contact?.type,
    ]
  );

  useEffect(() => {
    isFetchingWebsiteRef.current = isFetchingWebsite;
  }, [isFetchingWebsite]);

  const handleConnectionCheck = useCallback(async () => {
    // This function checks if the new IP provided by the BE is up and the router is working. Otherwise it times out.
    const abortController = new AbortController();
    const id = setTimeout(() => abortController.abort(), 10000);
    setIsFetchingWebsite(true);

    try {
      const website = await fetch(window.location.origin, {
        signal: abortController.signal,
      });
      if (!website.ok) {
        throw Error();
      }
      clearTimeout(id);
      // If the new IP is in place, we call the function to finish the process
      isDoneFunction();
    } catch (error: any) {
      setLoading(false);
      setIsFetchingWebsite(false);
      sendErrorToast(
        "There was an error handling the subscription. Please try again or contact support."
      );
    }
  }, [isDoneFunction]);

  const handleSubscription = useCallback(
    async (body: SubscriptionBody) => {
      setLoading(true);

      const bodyWithId = {
        ...body,
        subscription_id: subscriptionId,
        user_id: userId,
      };

      const checkIfConnectionIsBack = setTimeout(
        async () => handleConnectionCheck(),
        10000
      );

      try {
        // First we call the endpoint to register the subscription in the BE
        const response = await fetcher(
          `${endpoints.createSubscription}/${selectedAccountId}/subscription`,
          {
            body: JSON.stringify(bodyWithId),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
        if (!response.ok) {
          throw Error("Something went wrong");
        }
      } catch (error: any) {
        if (
          error.message === "Something went wrong" &&
          !isFetchingWebsiteRef.current
        ) {
          setLoading(false);
          clearTimeout(checkIfConnectionIsBack);
          sendErrorToast(
            "There was an error handling the subscription. Please try again or contact support."
          );
        }
      }
    },
    [selectedAccountId, userId, handleConnectionCheck]
  );

  return { handleSubscription, loading, subscriptionBody };
};
export default useCreateSubscription;

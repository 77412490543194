import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import ExternalLink from "../../../../components/ExternalLink";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { useGetSystemVariables } from "../../../../hooks/useGetSystemVariables";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { Step1Type } from "../../ForgotPassword";
import {
  headerStyle,
  userNameInputStyle,
  dividerStyle,
  getSubHeaderStyle,
  iconStyle,
} from "./Styles";

interface Step1Props {
  changeStep: (args: Step1Type) => void;
  previousValues: {
    step1: Step1Type;
  };
  goBackOneStep: () => void;
}

const Step1 = ({ changeStep, previousValues, goBackOneStep }: Step1Props) => {
  const { data, isLoading } = useGetSystemVariables({
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });
  const themePath = useGetThemePath();
  const { watch, register, handleSubmit } =
    useForm<Step1Type>({
      defaultValues: {
        userName: previousValues.step1.userName,
      },
    });

  const userName = watch("userName").trim();

  const disableSubmitButton = !userName;

  const onSubmit = () => {
    const selectedOption = {
      userName,
    };
    changeStep(selectedOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          Forgot Password
        </Typography>
        <Typography sx={getSubHeaderStyle(themePath)}>
          Enter Username
        </Typography>
      </Stack>
      <Stack mt={getREMFromPX(theme.spacing * 5)}>
        <Label htmlFor="userNameRadio">Username</Label>
        <Input
          style={userNameInputStyle}
          data-testid="usernameInput"
          {...register("userName")}
        />
      </Stack>
      <Stack
        margin={`${getREMFromPX(theme.spacing * 6)} 0 ${getREMFromPX(
          theme.spacing * 8
        )} 0`}
      >
        <ExternalLink
          href={data?.data.system.support_ticket_url}
          size="medium"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Don´t Remember Username?
        </ExternalLink>
      </Stack>
      <Divider sx={dividerStyle} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <Button
          startIcon={<FontAwesomeIcon icon="angle-left" style={iconStyle} />}
          onClick={goBackOneStep}
          variant="text"
          text="Back"
          size="medium"
        />
        <Button
          data-testid="submit_button_1"
          text="Continue"
          type="submit"
          size="medium"
          disabled={disableSubmitButton}
        />
      </Stack>
      <Backdrop open={isLoading}>
        <CircularProgress
          color="inherit"
          data-testid={isLoading ? "progressSpinner" : ""}
        />
      </Backdrop>
    </form>
  );
};
export default Step1;

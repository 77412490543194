import { Stack, Select, MenuItem, FormControl, InputLabel, Typography, IconButton, Divider} from "@mui/material";
import { useState } from "react";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import { dividerStyle, iconStyle} from "./Styles";
import Box from "../../../../../components/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetThemePath from "../../../../../hooks/useGetThemePath";

const StatementHistory = () => {
  const themePath = useGetThemePath();

  const [selYear, setSelYear] = useState(new Date().getFullYear());
  const years = Array.from(new Array(3),( val, index) => new Date().getFullYear() - index);


  function handleYearChange(event: any) {
    setSelYear(event.target.value);
  }

  const items = [{  year: 2023, 
                    periods: [  
                                {months: "Dec 9 - Jan 8" , cost: "180.36"}, 
                                {months: "Jan 9 - Feb 8" , cost: "200.45"}, 
                                {months: "Feb 9 - Mar 8" , cost: "220.88"}]
                  },
                  {  year: 2022, 
                    periods: [  
                                {months: "Nov 9 - Dec 8" , cost: "190.98"}, 
                                {months: "Oct 9 - Nov 8" , cost: "200.11"},
                                {months: "Sep 9 - Oct 8" , cost: "210.34"},
                                {months: "Aug 9 - Sep 8" , cost: "200.45"},
                                {months: "Jul 9 - Aug 8" , cost: "210.77"},
                                {months: "Jun 9 - Jul 8" , cost: "220.09"}]
                  }];

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        marginTop="20px"
      >
        <FormControl style={{width: "100px"}}>
          <InputLabel htmlFor="year-simple" style={{marginTop: "-10px"}}>Select Year</InputLabel>
          <Select
            value={selYear}
            onChange={handleYearChange}
            inputProps={{
              name: "year",
              id: "age-simple"
            }}
          >
            {years.map((value, index) => {
              return <MenuItem value={value}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Stack>   

      {items.map((item, index) =>
        <Stack
            direction="column"
            justifyContent="center"
            marginTop="30px"
          >
            <Typography>
              {item.year}
            </Typography>
            <Box
                padding={`${getREMFromPX(theme.spacing * 2)}`}
                minHeight={getREMFromPX(theme.spacing * 16)}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                width="-webkit-fill-available"
                hasBoxShadow
            >
              {item.periods.map((period, idx) => 
                <>
                  <Stack 
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography paddingLeft={getREMFromPX(theme.spacing * 6)}>
                      {period.months}
                    </Typography>
                    
                    <Stack 
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography paddingLeft={getREMFromPX(theme.spacing * 6)}>
                        {`$${period.cost}`}
                      </Typography>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        
                        onClick={(e) => {
                          e.stopPropagation();
                          //setShowDialog(true);
                        }}
                      >
                        <FontAwesomeIcon icon="angle-right" style={iconStyle(themePath)} title="View Statement" />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {item.periods.length -1 !== idx && <Divider sx={dividerStyle} />}
                </>
              )}
              
            </Box>

        </Stack>
      )} 
    </Stack>
  );
};

export default StatementHistory;

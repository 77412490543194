import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const selectStyles = {
  flex: 1,
};

export const planSelectStyles = {
  flex: 1.2,
};

export const searchButtonStyles = {
  marginLeft: getREMFromPX(theme.spacing * 2),
};

export const searchIconStyles = {
  marginRight: getREMFromPX(theme.spacing * 2),
};

import { Stack } from '@mui/material';
import getREMFromPX from '../../utils/getREMFromPX';
import { theme } from '../../theme';
import Label from '../Label';
import { Controller, useForm } from 'react-hook-form';
import Select from '../Select/Select';
import SelectOption from '../SelectOption';
import Input from '../Input/Input';
import { useGetSecurityQuestions } from '../../hooks/useGetSecurityQuestions';
import sendErrorToast from '../../utils/sendErrorToast';
import { Step3Type } from '../../routes/AccountSettings/AccountSettings';

export interface SecurityQuestionsSelection {
  question1Option: string;
  question2Option: string;
  question1Answer: string;
  question2Answer: string;
}

interface SecurityQuestionSelectionProps {
  children?: React.ReactElement;
  onSubmit: (options: SecurityQuestionsSelection) => void; 
  setIsValid: (isValid: boolean) => void;
  setIsLoadingQuestions: (isLoading: boolean) => void; 
  previousValues: SecurityQuestionsSelection
}

const SecurityQuestionSelection = ({previousValues, children, onSubmit, setIsValid, setIsLoadingQuestions}: SecurityQuestionSelectionProps) => {
  const { data: securityQuestions, isLoading: isLoadingQuestions } =
    useGetSecurityQuestions({
      onError: () =>
        sendErrorToast(
          "There was an error fetching the security questions, please try again"
        ),
      refetchOnWindowFocus: false,
    });

  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<Step3Type>({
    mode: "onChange",
    defaultValues: {
      question1Option: previousValues.question1Option,
      question2Option: previousValues.question2Option,
      question1Answer: previousValues.question1Answer,
      question2Answer: previousValues.question2Answer,
    },
  });
  const question1Option = watch("question1Option");
  const question2Option = watch("question2Option");
  const question1Answer = watch("question1Answer");
  const question2Answer = watch("question2Answer");
  
  const onSubmitHandler = () => {
    onSubmit({
      question1Option,
      question2Option,
      question1Answer,
      question2Answer
    })
  };

  // This will notify parent component if this form is valid or not.
  setIsValid(isValid)
  setIsLoadingQuestions(isLoadingQuestions)
  
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack
        spacing={getREMFromPX(theme.spacing * 3)}
        mt={getREMFromPX(theme.spacing * 8)}
      >
        <Label htmlFor="question1Option">Question 1</Label>
        <Controller
          name="question1Option"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Select
              placeholder="Select Question"
              {...field}
              id="question1Option"
            >
              {securityQuestions?.data.securityquestion
                .filter(({ id }) => id !== question2Option)
                .map(({ question, id }) => (
                  <SelectOption data-testid={question} key={id} value={id}>
                    {question}
                  </SelectOption>
                ))}
            </Select>
          )}
        />
        <Input
          data-testid="answer1"
          placeholder="Answer"
          {...register("question1Answer", { required: true })}
        />
      </Stack>
      <Stack
        spacing={getREMFromPX(theme.spacing * 3)}
        mt={getREMFromPX(theme.spacing * 5)}
      >
        <Label htmlFor="question2Option">Question 2</Label>
        <Controller
          name="question2Option"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Select
              placeholder="Select Question"
              {...field}
              id="question2Option"
            >
              {securityQuestions?.data.securityquestion
                .filter(({ id }) => id !== question1Option)
                .map(({ question, id }) => (
                  <SelectOption data-testid={question} key={id} value={id}>
                    {question}
                  </SelectOption>
                ))}
            </Select>
          )}
        />
        <Input
          data-testid="answer2"
          placeholder="Answer"
          {...register("question2Answer", { required: true })}
        />
      </Stack>
      { children }
    </form>
  );
}

export default SecurityQuestionSelection;

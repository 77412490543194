import { theme } from '../../theme';
import getREMFromPX from '../../utils/getREMFromPX';

export const getTabsStyle = (themePath = "default") => ({
  '.MuiTabs-indicator': {
    backgroundColor:theme[themePath].colors.primary[500]
  },
  '.MuiTabs-flexContainer': {
    //flexWrap : "wrap"
  }
  
})

export const getTabStyle = (themePath = "default") => ({
  textTransform: 'capitalize',
  fontSize: getREMFromPX(theme.spacing * 4),
  fontWeight: theme.fonts.weights.bolder,
  color: theme[themePath].colors.textPrimary.secondary,
  
  '&.Mui-selected ': {
    color: theme[themePath].colors.textPrimary.primary
  }
})

import { Stack, Step, StepLabel } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import Box from "../../components/Box";
import EntryPointLink from "../../components/EntryPointLink";
import FinalStepScreen from "../../components/FinalStepScreen";
import Stepper from "../../components/Stepper";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import { stepperContainerStyle } from "./Styles";
import useViewport  from "../../hooks/useViewport";

const steps = [...Array(3).keys()];

interface IObjectKeys {
  [key: number]: JSX.Element;
}

export type Step1Type = {
  userName: string;
};

export type Step2Type = {
  questions: {
    question: string;
    id: string;
    answer: string;
  }[];
};

export type Step3Type = {
  newPassword: string;
  confirmPassword: string;
};

const ForgotPassword = () => {
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    step1: {
      userName: "",
    },
    step2: {
      questions: [
        {
          question: "",
          id: "",
          answer: "",
        },
        {
          question: "",
          id: "",
          answer: "",
        },
      ],
    },
    step3: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const changeStep = (incomingValues?: Step1Type | Step2Type) => {
    const newStep = activeStep + 1;
    if (incomingValues) {
      setFormValues((formValues) => ({
        ...formValues,
        [`step${newStep}`]: incomingValues,
      }));
    }
    setActiveStep(newStep);
  };

  const goBackOneStep = () => {
    if (activeStep === 0) {
      return navigate(-1);
    }

    setActiveStep(activeStep - 1);
  };

  const stepsComponents = {
    0: (
      <Step1
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
        previousValues={formValues}
      />
    ),
    1: (
      <Step2
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
        previousValues={formValues}
      />
    ),
    2: (
      <Step3
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
        previousValues={formValues}
      />
    ),
  } as IObjectKeys;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.dashboard.path);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (activeStep === steps.length) {
      setTimeout(() => navigate(routes.login.path), 5000);
    }
  }, [activeStep, navigate]);

  return isAuthenticated === null ? null : (
    <>
      <div style={stepperContainerStyle(isMobile)}>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </div>
      <Stack
        justifyContent="center"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        {activeStep !== steps.length ? (
          <Box
            padding={getREMFromPX(theme.spacing * 6)}
            width= {isMobile ? "-webkit-fill-available" : getREMFromPX(theme.spacing * 150)}
          >
            {stepsComponents[activeStep]}
          </Box>
        ) : (
          <FinalStepScreen />
        )}
        <EntryPointLink mt={getREMFromPX(theme.spacing * 12)} />
      </Stack>
    </>
  );
};

export default ForgotPassword;

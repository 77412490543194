import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Backdrop,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type } from "../../ForgotPassword";
import {
  headerStyle,
  dividerStyle,
  iconStyle,
  getSubHeaderStyle,
} from "./Styles";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import sendErrorToast from "../../../../utils/sendErrorToast";
import { useGetUsersSecurityQuestions } from "../../../../hooks/useGetSecurityQuestions";
import { useEffect } from "react";

interface Step2Props {
  changeStep: (args: Step2Type) => void;
  goBackOneStep: () => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
  };
}

const Step2 = ({ changeStep, goBackOneStep, previousValues }: Step2Props) => {
  const {
    data: userSecurityQuestions,
    isLoading: isLoadingUserSecurityQuestions,
  } = useGetUsersSecurityQuestions(
    previousValues.step1.userName,
    undefined,
    {
      onError: (e) => {
        sendErrorToast(
          e.message ||
          "There was an error fetching the security questions, please try again"
        );
        goBackOneStep();
      },
      refetchOnWindowFocus: false,
    }
  );

  const themePath = useGetThemePath();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<Step2Type>({
    defaultValues: {
      questions: previousValues.step2.questions,
    },
    mode: "onChange",
  });

  const questions = watch("questions");
  const disableSubmitButton = !isValid;

  useEffect(() => {
    if (userSecurityQuestions) {
      userSecurityQuestions.forEach((question, idx) => {
        setValue(`questions.${idx}.question`, question.question);
        setValue(`questions.${idx}.id`, question.id);
      });
    }
  }, [userSecurityQuestions, setValue]);

  const onSubmit = () => {
    const selectedOptions = { questions };
    changeStep(selectedOptions);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={getREMFromPX(theme.spacing * 2)}>
          <Typography sx={headerStyle} component="h1">
            Security Questions
          </Typography>
          <Typography sx={getSubHeaderStyle(themePath)}>
            Verify your identity by answering security questions.
          </Typography>
        </Stack>
        {!userSecurityQuestions
          ? null
          : userSecurityQuestions.map((questionData, idx) => (
              <Stack
                spacing={getREMFromPX(theme.spacing * 3)}
                mt={getREMFromPX(theme.spacing * 8)}
                key={`question-${idx}`}
              >
                <Label htmlFor="question1Option">{questionData.question}</Label>
                <Input
                  data-testid={"answer" + idx}
                  placeholder="Answer"
                  {...register(`questions.${idx}.answer`, { required: true })}
                />
              </Stack>
            ))}
        <Divider sx={dividerStyle} />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          mt={getREMFromPX(theme.spacing * 6)}
        >
          <Button
            startIcon={<FontAwesomeIcon icon="angle-left" style={iconStyle} />}
            onClick={goBackOneStep}
            variant="text"
            text="Back"
            size="medium"
          />
          <Button
            data-testid="submit_button_2"
            text="Continue"
            type="submit"
            size="medium"
            disabled={disableSubmitButton}
          />
        </Stack>
      </form>
      <Backdrop open={isLoadingUserSecurityQuestions}>
        <CircularProgress color="inherit" data-testid="progressSpinner" />
      </Backdrop>
    </>
  );
};
export default Step2;

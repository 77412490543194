import { MenuItem, MenuItemProps } from "@mui/material/";
import useGetThemePath from "../../hooks/useGetThemePath";
import { getOptionStyles } from "./Styles";

const SelectOption = ({ children, ...props }: MenuItemProps) => {
  const themePath = useGetThemePath();
  return (
    <MenuItem sx={getOptionStyles(themePath)} {...props}>
      {children}
    </MenuItem>
  );
};
export default SelectOption;

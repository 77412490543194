import {
  Box,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  inputAdornmentStyle,
  iconStyleAdornment,
  iconStylePad,
} from "./Styles";
import { ReactComponent as LoginImage } from "../../assets/login-image.svg";
import { ReactComponent as LoginImageSmall } from "../../assets/login-image-small.svg";
import Input from "../../components/NewInput";
import Label from "../../components/NewLabel";
import CityIcon from "../../components/CityIcon";
import NewButton from "../../components/NewButton";
import { useState, useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/NewErrorMessage";
import getREMFromPX from "../../utils/getREMFromPX";
import Link from "../../components/Link";
import { useValidateUser } from "../../hooks/useValidateUser";
import ExternalLink from "../../components/ExternalLink";
import { routes } from "../../App";
import { theme } from "../../theme";
import EntryPointLink from "../../components/NewEntryPointLink";
import { EyeShowIcon, EyeHideIcon, MailIcon } from "../../assets/icons";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import { useLoginUser } from "../../hooks/useLoginUser";
import sendErrorToast from "../../utils/sendErrorToast";
import useViewport from "../../hooks/useViewport";
import { useGetConfig } from "../../hooks/useGetConfig";
import { useGetSystemVariables } from "../../hooks/useGetSystemVariables";
import isValidPassword from "../../utils/isValidPassword";
import isValidEmail from "../../utils/isValidEmail";

interface LoginFormValues {
  userName: string;
  password: string;
  confirmPassword: string;
}

const NewLogin = () => {
  const {
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isValid, errors },
  } = useForm<LoginFormValues>({
    mode: "onChange",
    defaultValues: {
      userName: "",
      password: "",
      confirmPassword: "",
    },
  });
  const [openCreateAccountView, setOpenCreateAccountView] =
    useState<boolean>(false);
  const location = useLocation();
  const state = location.state as { formerLocation?: string };
  const navigate = useNavigate();
  const redirectRoute = useMemo(
    () => state?.formerLocation || routes.dashboard.name,
    [state?.formerLocation]
  );

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { data: config } = useGetConfig();
  const showCreateAccount =
    config?.data?.config?.enabled_features?.CREATE_ACCOUNT?.enabled;

  const { data: systemVars } = useGetSystemVariables({
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });

  const {
    data,
    mutate: loginUser,
    isLoading,
  } = useLoginUser({
    onError: () =>
      setError("password", {
        message: "There was an error during the login, please try again",
      }),
  });

  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const userName = watch("userName").trim();
  const password = watch("password").trim();
  const confirmPassword = watch("confirmPassword").trim();
  const { width, isMobile } = useViewport();

  const {
    refetch: validateUserName,
    data: userIsValid,
    isLoading: userNameLoading,
  } = useValidateUser(userName, {
    cacheTime: 0,
    onError: () =>
      sendErrorToast(
        "There was a problem validating the Email or Username, please try again"
      ),
    enabled: false,
  });

  const disableFields = userNameLoading;
  const disableButton = !isValid || isLoading || userNameLoading;

  const onSubmit = () => loginUser({ userName, password });

  useEffect(() => {
    if (userIsValid?.data.user.available === false) {
      setError("userName", {
        message:
          "That Email or Username is already taken. Please choose a new one",
      });
    } else if (userIsValid?.data.user.available === true) {
      navigate(routes.createAccount.name, {
        state: { userName, password },
      });
    }
  }, [navigate, password, setError, userIsValid, userName, isValid]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectRoute);
    }
  }, [isAuthenticated, navigate, redirectRoute]);

  useEffect(() => {
    if (data === "Invalid Email or Username / Password combination.") {
      setError("password", {
        message:
          "This Email or Password is incorrect. Please verify and try again.",
      });
    }
    if (
      data !== "Invalid Email or Username / Password combination." &&
      data?.data?.user?.jwt
    ) {
      localStorage.setItem("token", data.data?.user?.jwt);
      navigate(redirectRoute);
    }
  }, [data, navigate, redirectRoute, setError]);

  const colors = theme["new"].colors;

  const passwordsMatch =
    openCreateAccountView && (!confirmPassword || confirmPassword === password);

  const handleRedirectToEmail = () => {
    window.open("mailto:help@entpnt.com", "_blank", "noopener,noreferrer");
  };

  return isAuthenticated === null ? null : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {width >= 745 && (
        <div
          style={{
            position: "absolute",
            left: width < 1220 ? "47px" : "72px",
            top: width < 1220 ? "38px" : "56px",
          }}
        >
          <CityIcon
            style={{
              width: getREMFromPX(theme.spacing * 20),
              height: "auto",
            }}
          />
        </div>
      )}
      <Stack
        flexDirection="row"
        flex={1}
        justifyContent="center"
        alignItems="flex-start"
        pt={width < 1220 ? "48px" : "110px"}
        pb="4.5rem"
        bgcolor={colors.white}
      >
        <Stack
          flex={1}
          maxWidth={isMobile ? `${width}px` : "640px"}
          alignItems="center"
          mt={isMobile ? "0px" : "65px"}
        >
          {width < 1220 && (
            <LoginImageSmall
              style={{ marginBottom: "16px" }}
              width={isMobile ? "95px" : "189px"}
              height={isMobile ? "88.4" : "175.33"}
            />
          )}
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: 460,
              padding: "0 30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: isMobile ? getREMFromPX(30) : getREMFromPX(36),
                fontWeight: 600,
                lineHeight: "44px",
                letterSpacing: "0em",
                marginBottom: isMobile ? "8px" : "56px",
                textAlign: "center",
              }}
            >
              {openCreateAccountView && !isMobile
                ? "Create an account"
                : "Welcome!"}
            </Typography>
            <Stack
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Stack style={{ width: "100%" }}>
                  <Label htmlFor="email">Email or Username</Label>
                  <Input
                    autoComplete="username"
                    {...register("userName", {
                      required: true,
                      validate: (value) => {
                        return openCreateAccountView
                          ? isValidEmail(value)
                          : true;
                      },
                    })}
                    error={!!errors.userName}
                    disabled={isLoading}
                    id="email"
                    placeholder="Email or Username"
                  />
                  {errors.userName && userName && (
                    <ErrorMessage>
                      {errors.userName?.message ||
                        "Invalid Email or Username. Please enter a new one."}
                    </ErrorMessage>
                  )}
                </Stack>
                <Stack style={{ width: "100%" }}>
                  <Label htmlFor="password">Password</Label>
                  <Input
                    autoComplete="current-password"
                    {...register("password", {
                      required: true,
                      validate: (value) => {
                        return (
                          isValidPassword(value) ||
                          !openCreateAccountView ||
                          "Password must contain at least 8 characters and less than 16, at least 1\nuppercase and 1 lowercase letter, 1 number and 1 special character."
                        );
                      },
                    })}
                    error={
                      (!!errors.password && !!password.length) ||
                      (!passwordsMatch && !!openCreateAccountView)
                    }
                    softError={
                      !!errors.password || !password.length || !passwordsMatch
                    }
                    disabled={disableFields}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    style={inputAdornmentStyle}
                    endAdornment={
                      <InputAdornment position="end" style={iconStyleAdornment}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          style={iconStylePad}
                        >
                          {showPassword ? <EyeHideIcon /> : <EyeShowIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!errors.password && (
                    <ErrorMessage>{errors.password.message}</ErrorMessage>
                  )}
                </Stack>
                {!openCreateAccountView ? null : (
                  <Stack style={{ width: "100%" }}>
                    <Label htmlFor="confirmPassword">Confirm Password</Label>
                    <Input
                      data-testid="confirmPassword"
                      id="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      disabled={disableFields}
                      error={
                        (!!errors.confirmPassword &&
                          !!confirmPassword.length) ||
                        !passwordsMatch
                      }
                      softError={
                        !!errors.confirmPassword ||
                        !confirmPassword.length ||
                        !passwordsMatch
                      }
                      {...register("confirmPassword", {
                        required: openCreateAccountView,
                        validate: (value) => value === getValues().password,
                      })}
                      style={inputAdornmentStyle}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={iconStyleAdornment}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            style={iconStylePad}
                          >
                            {showPassword ? <EyeHideIcon /> : <EyeShowIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {(!passwordsMatch ||
                      (!!errors.confirmPassword &&
                        !!confirmPassword.length)) && (
                      <ErrorMessage>
                        {!passwordsMatch
                          ? "This password does not match"
                          : errors.confirmPassword?.message}
                      </ErrorMessage>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                justifyContent: "center", // center until Rememer Me is implemented
                width: "100%",
                marginTop: isMobile ? "8px" : "36px",
              }}
            >
              {/* hide "Remember Me" for now. Come back to this later */}
              <div />
              {/* <FormControlLabel
                control={
                  <Checkbox style={{ marginRight: isMobile ? "3px" : "8px" }} />
                }
                label={
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: isMobile ? 12 : 14,
                    }}
                  >
                    Remember me
                  </Typography>
                }
                sx={{
                  textAlign: "left",
                  marginLeft: "0px",
                  color: colors.formGrey,
                }}
              /> */}
              {openCreateAccountView ? null : (
                <Link
                  to="/forgot-password"
                  style={{
                    fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(14),
                    fontWeight: 600,
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    color: "#497C76",
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontFamily: "Montserrat",
                  }}
                >
                  Forgot password?
                </Link>
              )}
            </div>
            <Box sx={{ width: "100%", marginTop: isMobile ? "16px" : "78px" }}>
              {openCreateAccountView ? (
                <>
                  <NewButton
                    text="Create Account"
                    mode="secondary"
                    onClick={() => validateUserName()}
                    disabled={disableButton}
                    sx={{ width: "100%", marginBottom: "24px" }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(14),
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#497c76",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      setOpenCreateAccountView(false);
                      clearErrors();
                    }}
                  >
                    Login
                  </Typography>
                </>
              ) : (
                <>
                  <NewButton
                    text="Login"
                    mode="default"
                    sx={{ width: "100%", marginBottom: "24px" }}
                    data-testid="submit_button"
                    type="submit"
                    disabled={disableButton}
                  />
                  {showCreateAccount && (
                    <NewButton
                      text="Create Account"
                      mode="secondary"
                      sx={{ width: "100%", marginBottom: "24px" }}
                      onClick={() => {
                        clearErrors();
                        setOpenCreateAccountView(true);
                      }}
                    />
                  )}
                </>
              )}
            </Box>
            {!openCreateAccountView ? (
              <div>
                <ExternalLink
                  href={systemVars?.data.system.support_ticket_url}
                  size="medium"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(14),
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#497c76",
                    textDecoration: "underline",
                  }}
                >
                  Forgot Email or Username?
                </ExternalLink>
              </div>
            ) : null}
            {!isMobile && (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "35px",
                  cursor: "pointer",
                }}
                onClick={handleRedirectToEmail}
              >
                <MailIcon />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    lineHeight: "15px",
                    color: "#253331",
                    letterSpacing: "0.015rem",
                    textDecoration: "underline",
                  }}
                >
                  &nbsp;help@entpnt.com
                </Typography>
              </Stack>
            )}
            <div style={{ marginTop: isMobile ? "16px" : "86px" }}>
              <EntryPointLink />
            </div>
          </Box>
        </Stack>
        {width >= 1220 && <LoginImage />}
      </Stack>
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </form>
  );
};

export default NewLogin;

import React from 'react';
import useGetThemePath from '../../../../hooks/useGetThemePath';
import getREMFromPX from '../../../../utils/getREMFromPX';
import { theme } from '../../../../theme';
import { Stack, Tab, Tabs } from '@mui/material';
import Box from '../../../../components/Box';
import { getTabsStyle, getTabStyle } from './Styles';
import CurrentStatement from './CurrentStatement';
import StatementHistory from './StatementHistory';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  name: string
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${name}`}
      aria-labelledby={`tab-panel-${name}`}
      {...other}
    >
      {value === index && (
        <Box
          data-testid="boxBackground2"
          component="section"
          height="100%"
          hasBoxShadow
          padding={getREMFromPX(theme.spacing * 6)}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const Billing = () => {
  
  const themePath = useGetThemePath();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  return (
    <Stack
      spacing={getREMFromPX(theme.spacing * 4)}
      padding={`${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(theme.spacing * 4)}`}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange} aria-label="scrollable auto tabs example" sx={getTabsStyle(themePath)} 
          variant="scrollable" scrollButtons allowScrollButtonsMobile>
          <Tab label="Current Statement" id="tab-currentStmt" aria-controls="tab-panel-currentStmt" sx={getTabStyle(themePath)} />
          <Tab label="Statement History" id="tab-statementHist" aria-controls="tab-panel-statementHist" sx={getTabStyle(themePath)}   />
          <Tab label="Transaction History" id="tab-transactionHist" aria-controls="tab-panel-transactionHist" sx={getTabStyle(themePath)} />
          <Tab label="Payment Methods" id="tab-paymentMethods" aria-controls="tab-panel-paymentMethods" sx={getTabStyle(themePath)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} name="currentStmt">
        <CurrentStatement />
      </TabPanel>
      <TabPanel value={value} index={1} name="statementHist">
        <StatementHistory />
      </TabPanel>
      <TabPanel value={value} index={2} name="transactionHist">
        Coming Soon !!!
      </TabPanel>
      <TabPanel value={value} index={3} name="paymentMethods">
        Coming Soon !!!
      </TabPanel>
    </Stack>
  )
}

export default Billing;

import React from 'react';
import useGetThemePath from '../../hooks/useGetThemePath';
import getREMFromPX from '../../utils/getREMFromPX';
import { theme } from '../../theme';
import { Stack, Tab, Tabs } from '@mui/material';
import Box from '../../components/Box';
import { getTabsStyle, getTabStyle } from './Styles';
import Security, { Username, Password } from './components/Security';
import Contact from './components/Contact';
import Notifications from './components/Notifications';
import Billing from './components/Billing';
import useViewport  from "../../hooks/useViewport";
import { useGetConfig } from "../../hooks/useGetConfig";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  name: string
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, name, ...other } = props;
  const { isMobile, width } = useViewport();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${name}`}
      aria-labelledby={`tab-panel-${name}`}
      {...other}
    >
      {value === index && (
        <Box
          data-testid="boxBackground"
          component="section"
          height="100%"
          hasBoxShadow
          width={isMobile ? "auto" : width < 1000 ? "-webkit-fill-available" : "60% !important"}
          padding={index !== 3 ? getREMFromPX(theme.spacing * 6) : "0px"}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const Profile = () => {
  
  const themePath = useGetThemePath();

  const [value, setValue] = React.useState(0);

  const { data: config } = useGetConfig();

  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  return (
    <Stack
      spacing={getREMFromPX(theme.spacing * 8)}
      padding={`${getREMFromPX(theme.spacing * 4)} ${getREMFromPX(theme.spacing * 8)}`}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange} aria-label="scrollable auto tabs example" sx={getTabsStyle(themePath)} 
          variant="scrollable" scrollButtons allowScrollButtonsMobile>
          <Tab label="Contact" id="tab-contact" aria-controls="tab-panel-contact" sx={getTabStyle(themePath)} />
          <Tab label="Security" id="tab-security" aria-controls="tab-panel-security" sx={getTabStyle(themePath)}   />
          <Tab label="Notification" id="tab-notification" aria-controls="tab-panel-notification" sx={getTabStyle(themePath)} />
          {showBilling && <Tab label="Billing" id="tab-billing" aria-controls="tab-panel-billing" sx={getTabStyle(themePath)} />}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} name="contact">
        <Contact />
      </TabPanel>
      <TabPanel value={value} index={1} name="security">
        <Username />
      </TabPanel>
      <TabPanel value={value} index={1} name="security">
        <Password />
      </TabPanel>
      <TabPanel value={value} index={1} name="security">
        <Security />
      </TabPanel>
      <TabPanel value={value} index={2} name="notification">
        <Notifications />
      </TabPanel>
      <TabPanel value={value} index={3} name="billing">
        <Billing />
      </TabPanel>
    </Stack>
  )
}

export default Profile;

import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";

export const linkStyles = {
  fontSize: getREMFromPX(theme.spacing * 10),
};

export const spanStyles = {
  paddingLeft: getREMFromPX(theme.spacing * 1),
};

export const buttonStyles = {
  height: getREMFromPX(theme.spacing * 12),
};

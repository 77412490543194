import useGetThemePath from "../../../../../hooks/useGetThemePath";
import { Stack, Typography, IconButton } from "@mui/material";
import { getHeaderStyle, getTotalStyle, iconStyle, getSubHeaderStyle, getSubHeaderPayStyle } from "./Styles";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { theme } from "../../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useViewport  from "../../../../../hooks/useViewport";


const CurrentStatement = () => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport();

  return (
    <Stack>
      <Stack>
        <Stack>
          <Typography sx={getHeaderStyle}>
            Total Balance
          </Typography>
        </Stack>
        <Stack mt={getREMFromPX(theme.spacing * 4)}>
          <Typography sx={getTotalStyle}>
            $ 0.00
          </Typography>
        </Stack>
        <Stack 
          direction="row"
          alignItems="center"
          mt={getREMFromPX(theme.spacing * 4)}
          //spacing={getREMFromPX(theme.spacing * 4)}
        >
          <FontAwesomeIcon icon="arrows-rotate"
                fontSize={getREMFromPX(theme.spacing * 6)}
                style={iconStyle(themePath)}
              />
          <Typography paddingLeft={getREMFromPX(theme.spacing * 6)}>
            Autopay processed on Feb 28
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={getSubHeaderPayStyle}>
          Visa *****1234
        </Typography>
      </Stack>
      <Stack mt={getREMFromPX(theme.spacing * 4)}>
        <Typography sx={getSubHeaderStyle}>
          Charges
        </Typography>
      </Stack>
      <Stack 
        direction="row"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Typography>
          Regular monthly charges
        </Typography>
        <Typography paddingLeft={getREMFromPX(theme.spacing * 24)}>
          $100
        </Typography>
      </Stack>
      <Stack 
        direction="row"
        alignItems="center"        
        mt={getREMFromPX(theme.spacing * 4)}
      >
        {/* <Typography> */}
          Taxes, fees and other charges
        {/* </Typography> */}
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          style={{ marginLeft: "5px" }}
          onClick={(e) => {
            e.stopPropagation();
            //setShowDialog(true);
          }}
        >
          <FontAwesomeIcon icon="info" style={iconStyle(themePath)} />
        </IconButton>
        <Typography paddingLeft={getREMFromPX(theme.spacing * 8)}>
          $8
        </Typography>
      </Stack>
      <Stack 
        direction="row"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 4)}
      >
        <Typography sx={getSubHeaderStyle}>
          Total
        </Typography>
        <Typography paddingLeft={isMobile ? getREMFromPX(theme.spacing * 40) : getREMFromPX(theme.spacing * 60)}>
          $110
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CurrentStatement;

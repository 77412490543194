import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getSwitchStyles = (themePath = "default") => ({
  width: getREMFromPX(theme.spacing * 7),
  height: getREMFromPX(theme.spacing * 4.5),
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    width: getREMFromPX(theme.spacing * 7),
    height: getREMFromPX(theme.spacing * 4.5),
    transform: `translateX(-${getREMFromPX(theme.spacing * 1)})`,
    "&.Mui-checked": {
      transform: `translateX(${getREMFromPX(theme.spacing * 1.5)})`,
      color: theme[themePath].colors.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: `${getREMFromPX(theme.spacing * 0.75)} solid ${
          theme[themePath].colors.primary[500]
        }`,
        backgroundColor: theme[themePath].colors.primary[500],
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme[themePath].colors.white,
      },
      "&.Mui-disabled": {
        "& + .MuiSwitch-track": {
          backgroundColor: theme[themePath].colors.primary[300],
          borderColor: theme[themePath].colors.primary[300],
        },
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: getREMFromPX(theme.spacing * 2),
    height: getREMFromPX(theme.spacing * 2),
    backgroundColor: theme[themePath].colors.textPrimary.disabled,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: getREMFromPX(theme.spacing * 3),
    opacity: 1,
    backgroundColor: theme[themePath].colors.white,
    border: `${getREMFromPX(theme.spacing * 0.75)} solid ${
      theme[themePath].colors.textPrimary.disabled
    }`,
  },
});

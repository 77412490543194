import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Stack, Typography, Dialog } from "@mui/material";
import Box from "../Box";
import Button from "../Button";
import Rating from "../Rating";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { dividerStyle, getButtonStyle, iconStyle, imgStyle } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo, useState, useContext, useEffect } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FilterValueProperty } from "../../routes/Plans/Plans";
import getProperLabelValue from "../../utils/getProperLabelValue";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import ReviewForm from "../../components/Review";
import { useGetConfig } from "../../hooks/useGetConfig";

const ProviderInfo = ({
  fields,
  serviceFields,
  rating,
  planId,
  id,
  providerName,
  planName,
  isUnsuscribe,
}: {
  planId: string;
  id: string;
  providerName: string;
  planName: string;
  fields: {
    [key: string]: string | number;
  };
  rating: number;
  isUnsuscribe?: boolean;
  serviceFields: string;
}) => {
  const { id: serviceId } = useParams();
  const themePath = useGetThemePath();
  const mappedServiceFields = useMemo(
    () => JSON.parse(serviceFields) as FilterValueProperty[],
    [serviceFields]
  );

  const { userInfo } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;
  const subHistories = useMemo(() => userInfo?.data?.user?.Subscriptionhistory?.filter(e => e.serviceplan_id === planId), [planId, userInfo]);
  const pastReview = useMemo(() => userInfo?.data?.user?.Review?.find(v => subHistories?.some(val => v.subscriptionhistory_id === val.id)), [subHistories, userInfo]);

  const currentSub = subHistories?.find(e => !e.canceled_at);

  const { data: config } = useGetConfig();
  const showReviews = config?.data?.config?.enabled_features?.USER_REVIEWS?.enabled;

  const [userRating, setUserRating] = useState<number | null>();
  const [showForm, setShowForm] = useState(false);

  const icons = useMemo(
    () => [
      ...Object.values(mappedServiceFields)
        .filter((field) => field.name && field.filter === "1")
        .map(({ name, icon }) => ({
          name,
          value: getProperLabelValue(name, fields[name] as string),
          icon,
          label: name,
        })),
    ],
    [fields, mappedServiceFields]
  );

  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    () => navigate(`/subscribe/${serviceId}/${planId}`),
    [navigate, planId, serviceId]
  );

  useEffect(() => {
    if (userRating) {
      setShowForm(true)
    }
  }, [userRating, setShowForm])

  return (
    <Box hasBoxShadow padding={`${getREMFromPX(theme.spacing * 4)}`}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize={getREMFromPX(theme.spacing * 4.5)}
            fontWeight={theme.fonts.weights.mediumBold}
            color={theme[themePath].colors.textPrimary.primary}
          >
            {providerName}
          </Typography>
          <Divider style={dividerStyle} orientation="vertical" flexItem />
          <Typography
            fontSize={getREMFromPX(theme.spacing * 4)}
            color={theme[themePath].colors.textPrimary.placeholder}
          >
            {planName}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        marginTop={getREMFromPX(theme.spacing * 8)}
        direction="row"
        gap={getREMFromPX(theme.spacing * 2)}
        flexWrap="wrap"
      >
        <Box
          padding={getREMFromPX(theme.spacing * 4)}
          hasBoxShadow
          width={getREMFromPX(theme.spacing * 45)}
        >
          <img
            style={imgStyle}
            alt={providerName}
            src={`${process.env.PUBLIC_URL}/assets/provider/${id}.png`}
          />
        </Box>
        {icons.map(({ icon, label, value }) => (
          <Box
            style={{
              background:
                (label === "Price" &&
                  `${theme.backgrounds.primarySelection}`) ||
                theme.backgrounds.widget,
            }}
            key={label}
            flex="1"
            hasBoxShadow
            maxWidth={getREMFromPX(theme.spacing * 26)}
            maxHeight={getREMFromPX(theme.spacing * 24)}
          >
            <Stack
              padding={getREMFromPX(theme.spacing * 4)}
              alignItems="center"
              justifyContent="center"
            >
              {icon && icon !== "undefined" && (
                <FontAwesomeIcon
                  icon={icon as IconProp}
                  fontSize={getREMFromPX(theme.spacing * 6)}
                  style={iconStyle}
                />
              )}
              <Typography
                fontSize={getREMFromPX(theme.spacing * 3)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                {label}
              </Typography>
              <Typography
                fontSize={getREMFromPX(theme.spacing * 4)}
                fontWeight={theme.fonts.weights.bolder}
                color={theme[themePath].colors.textPrimary.secondary}
                overflow="hidden"
                maxWidth="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {value}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Stack>
      <Stack marginTop={getREMFromPX(theme.spacing * 6)}>
        <Stack>
          {showReviews && (
            <Stack direction="row" gap={getREMFromPX(theme.spacing * 4)}>
              <Typography
                fontSize={getREMFromPX(theme.spacing * 4)}
                color={theme[themePath].colors.textPrimary.secondary}
              >
                Rating
              </Typography>
              <Rating
                value={Number(pastReview?.rating) ?? Number(rating)} 
                data-testid={`provider_info_test_id_4`}
                fontSize={getREMFromPX(theme.spacing * 6)}
                color={theme[themePath].colors.primary[500]}
                planId={planId}
                planName={planName}
                onChange={(event, newValue) => {
                  setUserRating(Number(newValue ?? pastReview?.rating ?? rating));
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        justifyContent={isUnsuscribe ? "end" : "start"}
        marginTop={isUnsuscribe ? getREMFromPX(theme.spacing * 4) : 0}
        flexDirection="row"
        padding={`0 ${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
          theme.spacing * 2
        )} 0 `}
      >
        {!isUnsuscribe && (
          <Button
            style={getButtonStyle(isUnsuscribe)}
            size="medium"
            text="Subscribe"
            onClick={handleButtonClick}
          />
        )}
      </Stack>
      {currentSub && (
        <Dialog open={showForm} onClose={() => setShowForm(false)}>
          <Box
            padding={getREMFromPX(theme.spacing * 8)}
            maxWidth={getREMFromPX(theme.spacing * 150)}
            data-testid="dialogWrapper"
          >
            <ReviewForm
              review={pastReview}
              history={currentSub}
              newRating={userRating}
              providerName={providerName}
              planName={planName}
              onSuccess={() => setShowForm(false)}
            />
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default ProviderInfo;

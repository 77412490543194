import { Stack, Typography, Divider } from "@mui/material";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { childrenContainerStyle, getNavBarMenuStyles, iconStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetSystemVariables } from "../../../../hooks/useGetSystemVariables";
import sendErrorToast from "../../../../utils/sendErrorToast";

export type NavBarMenuItem = {
  title?: string;
  icon?: React.ReactElement;
  label: string;
  onClick?: () => void;
  divider?: boolean;
  selected?: boolean;
  children?: React.ReactElement;
  padding?: string;
};

const NavBarMenu = ({
  title,
  icon,
  label,
  children,
  onClick,
  divider,
  selected,
  padding,
}: NavBarMenuItem) => {
  const themePath = useGetThemePath();

  const { data } = useGetSystemVariables({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast(
        "There was an error getting the recover username link, please try again"
      ),
  });

  return (
    <Stack spacing={getREMFromPX(theme.spacing * 4)} component="section">
      {title && (
        <Typography
          color={theme[themePath].colors.textPrimary.secondary}
          component="h3"
          fontSize={getREMFromPX(theme.spacing * 3)}
          padding={padding}
        >
          {title}
        </Typography>
      )}
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack
          display={label ? "flex" : "none"}
          component="button"
          onClick={onClick}
          flexDirection="row"
          alignItems="center"
          sx={getNavBarMenuStyles(selected, themePath)}
        >
          {icon}
          <Typography
            role="link"
            component="span"
            fontWeight={
              !selected ? theme.fonts.weights.normal : theme.fonts.weights.bolder
            }
          >
            {label}
          </Typography>
        </Stack>
        <Stack
          display={label ? "flex" : "none"}
          component="button"
          onClick={() => {
            data &&
              window.open(
                `${data.data.system.support_ticket_url}`,
                "_blank",
                "noopener,noreferrer"
              );
          }}
          flexDirection="row"
          alignItems="center"
          sx={getNavBarMenuStyles(false, themePath)}
        >
          <FontAwesomeIcon icon="headset" style={iconStyles} />
          <Typography
            role="link"
            component="span"
            fontWeight={
              !selected ? theme.fonts.weights.normal : theme.fonts.weights.bolder
            }
          >
            Troubleshooting
          </Typography>
        </Stack>
      </Stack>
      {children && <div style={childrenContainerStyle}>{children}</div>}
      {divider && <Divider />}
    </Stack>
  );
};
export default NavBarMenu;

import React from "react";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const dividerStyle = {
  height: getREMFromPX(theme.spacing * 8),
};

export const getDivStyles = (
  themePath = "default",
  status?: string,
  isDisabled?: boolean
) => {
  const primaryStyles = {
    borderRadius: "50%",
    background:
      status?.toLowerCase() === "on" || status === undefined
        ? theme[themePath].colors.okStatus
        : theme[themePath].colors.lightWarningStatus,
    width: getREMFromPX(theme.spacing * 2.5),
    height: getREMFromPX(theme.spacing * 2.5),
  };
  if (isDisabled) {
    return {
      ...primaryStyles,
      background: theme[themePath].colors.textPrimary.disabled,
    };
  }
  return primaryStyles;
};

export const getModalBoxStyles = (themePath = "default") =>
  ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme[themePath].colors.white,
  } as React.CSSProperties);

export const iconStyles = {
  paddingLeft: getREMFromPX(theme.spacing * 1.5), 
  paddingRight: getREMFromPX(theme.spacing * 1.5), 
};

export const normalisedButtonStyles = (themePath = "default") =>
  ({
    marginLeft: "auto",
    border: 0,
    appearance: "none",
    background: "none",
    fontFamily: "inherit",
    padding: 0,
    cursor: "pointer",
    color: theme[themePath].colors.textPrimary.secondary,
    fontSize: getREMFromPX(theme.spacing * 5),
    fontWeight: theme.fonts.weights.mediumBold,
  } as React.CSSProperties);

export const tableContainerStyles = {
  overflowX: "auto",
} as React.CSSProperties;

export const backdropStyles = {
  zIndex: 2000,
};

import { theme } from '../../../../theme';
import getREMFromPX from '../../../../utils/getREMFromPX';

export const getHeaderIconStyle = (themePath = "default") => ({ 
  fontSize: getREMFromPX(theme.spacing * 4), 
  color: theme[themePath].colors.textPrimary.secondary
})

export const getListValueStyle = (themePath = "default") => ({
  marginLeft: getREMFromPX(theme.spacing * 4),  
  color: theme[themePath].colors.textPrimary.placeholder
})

export const getHeaderStyle = {
  fontWeight: theme.fonts.weights.mediumBold,
  marginLeft: getREMFromPX(theme.spacing * 2),
  fontSize: getREMFromPX(theme.spacing * 4.5)
} 

export const getEditStyle = (themePath = "default") => ({
  "&:hover": {
    color: theme[themePath].colors.primary[700],
  },
  color: theme[themePath].colors.primary[500],
  fontSize: getREMFromPX(theme.spacing * 3.5),
  border: "none",
  background: "none",
  gap: getREMFromPX(theme.spacing * 2),
  cursor: "pointer",
})

export const contactContainerStyle = (isMobile: any, breakpoint: any) => ({
  //width: isMobile ? "auto" : breakpoint > 1000 ? "-webkit-fill-available" : "60% !important",
})
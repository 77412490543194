import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getOptionStyles = (themePath = "default") => ({
  "&.MuiMenuItem-root": {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fonts.weights.normal,
    fontSize: getREMFromPX(theme.spacing * 4),
    color: theme[themePath].colors.textPrimary.primary,
    whiteSpace: "pre-wrap",
  },
});

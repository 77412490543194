import { Collapse, Alert, IconButton, AlertProps } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { alertStyles, getIconStyles } from "./Styles";
import useGetThemePath from "../../hooks/useGetThemePath";

const StyledAlert = ({
  children,
  isClosable,
  ...props
}: AlertProps & {
  isClosable?: boolean;
}) => {
  const themePath = useGetThemePath();
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <Alert
        sx={alertStyles}
        severity="info"
        icon={false}
        action={
          isClosable && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon style={getIconStyles(themePath)} />
            </IconButton>
          )
        }
        {...props}
      >
        {children}
      </Alert>
    </Collapse>
  );
};
export default StyledAlert;

import { Divider, Stack, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Button from "../Button";
import { dividerStyle, getIconStyles, serviceBoxStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ServiceBox = ({
  title,
  description,
  tooltipDesc,
  serviceId,
  image, 
  disabled,
  ...props
}: BoxProps & {
  title: string;
  image: string;
  description: string;
  tooltipDesc: string;
  serviceId: string;
  disabled: boolean;
}) => {
  const navigate = useNavigate();
  const themePath = useGetThemePath();

  const onClickProviders = () =>
    navigate(
      `${routes.services.path}/${encodeURIComponent(serviceId)}/plans`
    );

  const serviceIcon = image.split("-")[1] as IconProp;

  return (
    <Box sx={serviceBoxStyles} {...props}>
      <Stack
        spacing={getREMFromPX(theme.spacing * 2)}
        padding={getREMFromPX(theme.spacing * 6)}
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={serviceIcon} style={getIconStyles(themePath)} />
        <Typography
          color={theme[themePath].colors.textPrimary.primary}
          fontSize={getREMFromPX(theme.spacing * 4.5)}
          fontWeight={theme.fonts.weights.mediumBold}
        >
          {title}
        </Typography>
        <Typography
          textAlign="center"
          color={theme[themePath].colors.textPrimary.secondary}
          fontSize={getREMFromPX(theme.spacing * 3)}
        >
          {description}
        </Typography>
        <Divider sx={dividerStyle} />
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="center"
          mt={`${getREMFromPX(theme.spacing * 4)} !important`}
          justifyContent="space-between"
        >
          <Tooltip title={tooltipDesc} enterTouchDelay={0} arrow>
            <Stack gap={getREMFromPX(theme.spacing * 2)} flexDirection="row">
              <FontAwesomeIcon icon="circle-info" />
              <Typography
                color={theme[themePath].colors.textPrimary.secondary}
                fontSize={getREMFromPX(theme.spacing * 3)}
              >
                Info
              </Typography>
            </Stack>
          </Tooltip>
          <Tooltip title={disabled ? 'There are not plans created for this provider' : ''} arrow disableFocusListener disableTouchListener >
            <Stack>
              <Button
                variant="outlined"
                text="View Providers"
                disabled={disabled}
                onClick={onClickProviders}
              />
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  );
};
export default ServiceBox;

import { theme } from '../../../../theme';
import getREMFromPX from '../../../../utils/getREMFromPX';

export const getHeaderIconStyle = (themePath = "default") => ({ 
  fontSize: getREMFromPX(theme.spacing * 4), 
  color: theme[themePath].colors.textPrimary.secondary
})

export const getHeaderStyle = {
  fontWeight: theme.fonts.weights.mediumBold,
  marginLeft: getREMFromPX(theme.spacing * 2),
  fontSize: getREMFromPX(theme.spacing * 4.5)
} 

export const getTabsStyle = (themePath = "default") => ({
  '.MuiTabs-indicator': {
    backgroundColor:theme[themePath].colors.primary[500]
  },
  '.MuiTabs-flexContainer': {
    //flexWrap : "wrap"
  }
  
})

export const getTabStyle = (themePath = "default") => ({
  textTransform: 'capitalize',
  fontSize: getREMFromPX(theme.spacing * 4),
  fontWeight: theme.fonts.weights.bolder,
  color: theme[themePath].colors.textPrimary.secondary,
  
  '&.Mui-selected ': {
    color: theme[themePath].colors.textPrimary.primary
  }
})

import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import RouterIcon from "@mui/icons-material/Router";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ErrorIcon from "@mui/icons-material/Error";
import { Button, Box, Modal, Typography, IconButton } from "@mui/material";
import getREMFromPX from "../../../../utils/getREMFromPX";
import NewButton from "../../../../components/NewButton";
import { OverviewDataModel } from "../../../../components/AllServices/AllServices";
import { CreateAccountSteps } from "../../../NewCreateAccount/NewCreateAccount";
import ModalStepper from "./ModalStepper";
import LoaderContent from "./steps/LoaderContent";
import TermsContent from "./steps/TermsContent";
import CardContent from "./steps/CardContent";
import TableContent from "./steps/TableContent";

import { MappedProviderWithPlan } from "../../../SubscribeToISP/SubscribeToISP";
import useCreateSubscription from "../../../../hooks/useCreateSubscription";
import { ServiceInfo } from "../../../../api/interfaces/ServiceInfo";
import fetcher from "../../../../utils/fetchWrapper";
import { theme } from "../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { Port } from "../../../../api/interfaces/Port";
import { useIsPortLive } from "../../../../hooks/useIsPortLive";
import useViewport from "../../../../hooks/useViewport";

const WorkflowSubscriptionModal = ({
  modalOverviewData,
  open,
  handleClose,
}: {
  modalOverviewData: OverviewDataModel;
  open: boolean;
  handleClose: Function;
}) => {
  const [services, setServices] = useState<ServiceInfo[]>();
  const serviceId = null;
  const planId = modalOverviewData.planId;
  const [selectedPlan, setSelectedPlan] = useState<MappedProviderWithPlan>();
  const [receivedDisclosure, setReceivedDisclosure] = useState<boolean>(false);
  const { isMobile, width } = useViewport();

  useEffect(() => {
    const fetchService = async () => {
      const loading =
        "Servicetype," +
        "Provider.Contacts," +
        "Plans" +
        "&filter=" +
        "status eq ACTIVE::" +
        "Provider.status eq ACTIVE::" +
        "Plans.status eq ACTIVE";

      const response = await fetcher(
        `${process.env.REACT_APP_BE_URL}/servicetype/${serviceId}/service?loading=${loading}`
      );

      const r = await response.json();
      setServices(r?.data?.service);
      return r;
    };
    if (serviceId) {
      fetchService();
    }
  }, [serviceId]);

  useEffect(() => {
    if (services) {
      let planMatch: any;
      const currentPlan = services
        .filter((service) =>
          service.Plans.find((plan) => {
            if (plan.id === planId) {
              planMatch = plan;
            }
            return plan.id === planId;
          })
        )
        .map((plan) => ({ provider: plan, plan: planMatch }))[0];
      setSelectedPlan(currentPlan);
    }
  }, [services, planId, serviceId]);

  const { handleSubscription } = useCreateSubscription(() => {},
  selectedPlan as MappedProviderWithPlan);

  const createAccountSteps = useMemo(
    () => [
      {
        label: "Device Info",
        icon: <RouterIcon sx={{ fontSize: "20px" }} />,
        isActive: true,
        isCompleted: false,
      },
      {
        label: "Personal Information",
        icon: <AttachMoneyIcon sx={{ fontSize: "20px" }} />,
        isActive: false,
        isCompleted: false,
      },
      {
        label: "Account Security",
        icon: (
          <ErrorIcon sx={{ fontSize: "20px", transform: "rotate(-180deg)" }} />
        ),
        isActive: false,
        isCompleted: false,
      },
    ],
    []
  );

  const [selectedPort, setSelectedPort] = useState<string>("");
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<Array<CreateAccountSteps>>(
    () => createAccountSteps
  );

  const { currentDevice, refetchUser } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const wanPort = (currentDevice.Portinterfaces as Port[]).filter((port) => {
    return port.name === "WAN";
  });
  const { data: isLiveData, refetch } = useIsPortLive(
    currentDevice.id,
    wanPort[0].id
  );
  const isLive =
    isLiveData?.data?.portinterface?.live?.link_state === "up" || false;

  const incrementStep = useCallback(() => {
    const arr = [...steps].map((step, idx) => {
      if (idx === currentStep) {
        step.isActive = false;
        step.isCompleted = true;
      }
      if (currentStep + 1 === idx) {
        step.isActive = true;
      }

      return {
        ...step,
      };
    });
    setSteps(arr);
    setCurrentStep(currentStep + 1);
  }, [steps, currentStep]);

  const handlePreviousStep = useCallback(() => {
    const arr = [...steps].map((step, idx) => {
      if (idx === currentStep) {
        step.isActive = false;
      }
      if (currentStep - 1 === idx) {
        step.isCompleted = false;
        step.isActive = true;
      }
      return {
        ...step,
      };
    });
    setSteps(arr);
    setCurrentStep(currentStep - 1);
  }, [steps, currentStep]);

  const handleNextStep = useCallback(() => {
    incrementStep();
  }, [incrementStep]);

  const handleRestart = useCallback(() => {
    setCurrentStep(0);
    setSteps(createAccountSteps);
    handleClose();
  }, [createAccountSteps, handleClose]);

  const subscribeServiceWorkflow: Array<{
    id: number;
    component: ReactNode;
    label: string;
  }> = useMemo(
    () => [
      {
        id: 1,
        component: (
          <TableContent
            selectedPort={selectedPort}
            setSelectedPort={setSelectedPort}
            deviceIsConnected={isLive}
          />
        ),
        label: "deviceInfo",
      },
      {
        id: 2,
        component: (
          <CardContent
            modalOverviewData={modalOverviewData}
            handleRestart={handleRestart}
          />
        ),
        label: "personalInformation",
      },
      {
        id: 3,
        // TODO: props don't seem to update when the component is used like this.
        component: (
          <TermsContent
            companyName={modalOverviewData.providerName || "the provider"}
            onReceivedDisclosure={() =>
              setReceivedDisclosure(!receivedDisclosure)
            }
            isReceived={receivedDisclosure}
          />
        ),
        label: "accountSecurity",
      },
      {
        id: 4,
        component: <LoaderContent modalOverviewData={modalOverviewData} />,
        label: "allDone",
      },
    ],
    [selectedPort, isLive, modalOverviewData, handleRestart, receivedDisclosure]
  );

  return (
    <Modal open={open} onClose={handleRestart}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? `calc(${width}px - 3%)` : getREMFromPX(909),
          bgcolor: "background.paper",
          boxShadow: 24,
          py: isMobile ? getREMFromPX(16) : getREMFromPX(66),
          px: isMobile ? getREMFromPX(32) : getREMFromPX(132),
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          overflowY: "auto",
          height: isMobile ? "90%" : getREMFromPX(800),
        }}
      >
        <Box
          sx={{
            maxWidth: isMobile ? `calc(${width}px - 5%)` : getREMFromPX(909),
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: isMobile ? getREMFromPX(32) : 0,
          }}
        >
          {currentStep !== 3 && <ModalStepper steps={steps} />}
          <Box
            sx={{
              my: getREMFromPX(8),
            }}
          >
            {subscribeServiceWorkflow[currentStep]?.component}
          </Box>
          {currentStep !== 3 && (
            <Box
              display="flex"
              justifyContent="space-between"
              margin={"0px 10px"}
            >
              <Button
                onClick={() =>
                  currentStep === 0 ? handleRestart() : handlePreviousStep()
                }
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon="chevron-left"
                    fontSize={getREMFromPX(16)}
                    color={theme.new.colors.teal}
                  />
                }
              >
                <Typography
                  textAlign="center"
                  fontSize={getREMFromPX(16)}
                  fontFamily="Montserrat"
                  fontWeight="700"
                  sx={{
                    color: theme.new.colors.teal,
                    textTransform: "capitalize",
                  }}
                >
                  Back
                </Typography>
              </Button>
              {currentStep === 0 && !isLive && (
                <NewButton
                  mode="default"
                  text="Re-check Device"
                  sx={{
                    width: "220px",
                    height: "56px",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    refetch();
                  }}
                />
              )}
              {(currentStep !== 0 || isLive) && (
                <NewButton
                  mode="default"
                  text={currentStep !== 2 ? "Continue" : "Subscribe"}
                  disabled={currentStep === 2 && !receivedDisclosure}
                  sx={{
                    width: "220px",
                    height: "56px",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    currentStep < 3 ? handleNextStep() : handleRestart();
                    if (currentStep === 2) {
                      handleSubscription({
                        port_interface_id: selectedPort as string,
                        serviceplan_id: planId,
                      }).then(() => {
                        refetchUser();
                        handleRestart();
                      });
                    }
                  }}
                />
              )}
            </Box>
          )}
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: theme.new.colors.teal,
              p: 2,
            }}
            onClick={() => handleRestart()}
          >
            <CloseIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default WorkflowSubscriptionModal;

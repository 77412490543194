import React from "react";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/NewCardWrapper";
import { FaqIcon } from "../../../../assets/icons";
import { newRoutes } from "../../../../App";
import { Grid } from "@mui/material";
import { ContinueIcon, Description, ImageContainer } from "../../Styles";

const Faq = ({ styles }: { styles: React.CSSProperties }) => {
  const navigate = useNavigate();

  const arrowAction = (
    <ContinueIcon
      onClick={() => navigate(newRoutes.helpFaq.path)}
      data-cy="help-faq-arrow"
    />
  );

  return (
    <CardWrapper
      title="Frequently Asked Questions"
      actions={arrowAction}
      sx={styles}
    >
      <Grid
        container
        spacing={2}
        onClick={() => navigate(newRoutes.helpFaq.path)}
      >
        <Grid item sm={12} md={6}>
          <Description>
            Have some <br /> questions?
          </Description>
        </Grid>
        <Grid item sm={12} md={6}>
          <ImageContainer>
            <FaqIcon
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                marginBottom: "1.84rem",
              }}
            />
          </ImageContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default Faq;
